<template>
  <div>
    <BButton @click="onFileSelect" size="sm" variant="primary">Завантажити замовлення з Excel</BButton>
    <input type="file" ref="fileInput" @change="uploadFile" style="display: none" />
  </div>
</template>

<script>
import { ref } from 'vue';
import { BButton } from 'bootstrap-vue-next';
import { exportExcelOrders } from "@/axios/settignsRequests";

export default {
  setup() {
    const fileInput = ref(null);

    const onFileSelect = () => {
      fileInput.value.click();
    };

    const uploadFile = async (event) => {
      const file = event.target.files[0];
      console.log('Selected file:', file);

      if (!file) return; // Якщо файл не вибрано, нічого не робимо.

      const formData = new FormData();
      formData.append('file', file);

      console.log('FormData content:', formData.get('file'));

      try {
        const response = await exportExcelOrders(formData);
        console.log('File uploaded successfully', response.data);
      } catch (error) {
        console.error('Error uploading file', error);
      } finally {
        // Скидаємо значення input, щоб подія change могла спрацювати з тим самим файлом.
        fileInput.value.value = null;
      }
    };

    return {
      fileInput,
      onFileSelect,
      uploadFile,
    };
  },

  components: {
    BButton,
  },
};
</script>

<style scoped>
</style>
