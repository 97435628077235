<template>
  <!--  <b-modal
        z-index="9999"
        v-model="isVisible"
        no-footer
        :title="popUpTitle"
        @hide="closePopup"
    >-->
  <b-modal
      z-index="9999"
      id="pushModal"
      v-model="isVisible"
      no-footer

      hide-footer
      :title="popUpTitle"
      @hide="closePopup"
  >


    <BRow>
      <template>
        <BBadge
            class="mr-2xl"
            :variant="isSubscribed === 'true' ? 'success' : 'danger'"
        >
          {{ isSubscribed === 'true' ? 'Сповіщення Увімкнені' : 'Сповіщення Вимкнені' }}
        </BBadge>

        <BBadge variant="secondary">Кількість тем для сповіщень {{ subscriptionsCount }}</BBadge>

        <div v-if="!showImageInstructions">
          <BFormCheckbox
              v-for="(value, key) in pushThemes"
              :key="key"
              v-model="pushThemes[key]"
              :name="key"
              :id="key"
          >
            {{ getLabel(key) }}
          </BFormCheckbox>
          <div class="d-flex align-items-center justify-content-center">
            <BButton
                class="mr-2xl"
                @click="subscribeToPushNotifications"
                variant="success"
            >
              <BSpinner v-if="popUpSpinner" small/>
              {{ getSubscribeButtonText }}
            </BButton>
            <!--      <BButton @click="closePopup" variant="outline-danger">{{
                      isSubscribed === true ? 'Вимкнути сповіщення' : 'Ні'
                    }}
                  </BButton>-->

          </div>
        </div>
        <div v-else>
          <div>Сповіщення заблоковані. Дозвольте їх у налаштуваннях браузера. <br>
            Потім перезавантажте сторінку і ще раз натисніть зайдіть у це меню і натисніть "Увімкнути сповіщення"
          </div>
          <img src="/img/content-img/accept_allerts-min.jpg" alt="">
        </div>
      </template>
    </BRow>


  </b-modal>
</template>

<script>
import {ref, watch, computed} from "vue";
import {requestNotificationPermission} from "../../public/firebase";
import {savePushKey} from "@/axios/settignsRequests";
import {getThemeSettings} from "@/axios/settignsRequests";


export default {
  name: "Popup",
  /*  props: {
      pushPopUpState: {
        type: Boolean,

      },
    },*/

  props: {
    modelValue: {
      type: Boolean,

    },
  },

  setup(props, {emit}) {

    const popUpTitle = ref("Оберіть події про які вам потрібні негайні сповіщення");

    const popUpSpinner = ref(false);

    /*    computed(() => {
          isVisible.value = props.pushPopUpState;
        });*/

    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const isSubscribed = ref(localStorage.getItem("pushStatus") || "not_set");


    console.log('isSubscribed', isSubscribed.value)

    const pushToken = localStorage.getItem('pushToken');

    const closePopup = () => {
      isVisible.value = false;
    };

    const getLabel = (key) => {
      switch (key) {
        case 'new_orders':
          return 'Нові замовлення';
        case 'important_alerts':
          return 'Важливі повідомлення';
        case 'order_cancelled':
          return 'Замовлення скасоване';
        case 'payment_cancelled':
          return 'Оплата скасована';
        default:
          return 'Невідомий тип сповіщення';
      }
    }

    const showImageInstructions = ref(false);

    const pushThemes = ref({
      'new_orders': true,
      'important_alerts': true,
      'order_cancelled': true,
      'payment_cancelled': true,
    })

    if (pushToken !== null) {
      pushThemes.value.data = getThemeSettings({client_key: pushToken}).then((res) => {
        pushThemes.value = res.data;

        console.log('pushThemes2', pushThemes.value);
      }, (err) => {
        console.log('err pushThemes', err)
      });
    }

    const hasActiveSubscriptions = computed(() => {
      return pushThemes.value && typeof pushThemes.value === 'object'
          ? Object.values(pushThemes.value).some(theme => theme === true)
          : false; // Якщо pushThemes.value не визначений, повертаємо false
    });


    // Computed property to get the subscription button text
    const getSubscribeButtonText = computed(() => {
      if (isSubscribed.value === "true" && hasActiveSubscriptions.value) {
        return "Змінити налаштування";
      } else if (isSubscribed.value !== "true" && hasActiveSubscriptions.value) {
        return "Увімкнути сповіщення";
      } /*else if (!hasActiveSubscriptions.value) {
        return "Вимкнути усі сповіщення";
      }*/
      else {
        return "Зберегти налаштування";
      }
    });

    if (isSubscribed.value === "true") {
      popUpTitle.value = "Редагування налаштувань сповіщень";
    } else if (isSubscribed.value === 'false') {
      // set pushThemes each to false
      for (let key in pushThemes.value) {
        pushThemes.value[key] = false;
      }
    }


    const subscribeToPushNotifications = async () => {

      if (!hasActiveSubscriptions.value && pushToken === null) {

        localStorage.setItem("pushStatus", "false");

      } else {
        try {
          popUpSpinner.value = true;


          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            console.log("Notification permission granted.");

            const token = await requestNotificationPermission();
            if (token) {
              console.log("Token received:", token);
              localStorage.setItem("pushToken", token);
              localStorage.setItem("pushStatus", "true");

              const subscriptionData = {
                client_key: token,
                push_status: "true",
                push_themes: pushThemes.value,
                user_agent: navigator.userAgent,
                platform: navigator.platform
              };

              await sendSubscriptionToServer(subscriptionData).then((res) => {
                console.log("sendSubscriptionToServer1", res);
                if (res.status === 'success') {
                  pushThemes.value = res.data;
                }
              })
              isSubscribed.value = 'true'
            } else {
              localStorage.setItem("pushStatus", "false");
              console.log("Notification permission denied.");
            }
          } else if (permission === "denied") {
            showImageInstructions.value = true;
          }
          popUpSpinner.value = false;
        } catch (error) {
          console.error("Error during push notification subscription:", error);
        }

      }
    };

    const subscriptionsCount = computed(() => {
      let count = 0;
      for (let key in pushThemes.value) {
        if (pushThemes.value[key] === true) {
          count += 1;
        }
      }
      return count;
    });


    const sendSubscriptionToServer = async (subscriptionData) => {
      return await savePushKey(subscriptionData);
    };

    return {
      isVisible,
      subscribeToPushNotifications,
      closePopup,
      isSubscribed,
      popUpTitle,
      pushThemes,
      getLabel,
      getSubscribeButtonText,
      hasActiveSubscriptions,
      popUpSpinner,
      showImageInstructions,
      subscriptionsCount
    };
  },
};
</script>

<style scoped>
.mr-2xl {
  margin-right: 20px;
}
</style>
