<template>

  <Suspense>
    <custom-modal
        v-if="modaslIsVisible"
        :showOrderModal="showModal"
        :orderForUpdate="selectedOrder"
        :modalTitle="modalTitle"
        :activeTab="activeTab"
        @update:showOrderModal="closeModal"
    />
  </Suspense>
  <confirmModal
      :modalStatus="modalVisible"
      :modalTitle="confirmModalTitle"
      :modalBody="confirmModalBody"
      :confirmButtonValue="confirmButtonValue"
      :cancelButtonValue="cancelButtonValue"
      @update:userConfirmation="handleUserConfirmation"
      @update:closeConfirmModal="modalVisible = false"
  />

  <PackingModal
      v-if="showPackingModal"
      :localModalStatus="showPackingModal"
      :order="selectedOrder"
      @update:localModalStatus="showPackingModal = false"
  />

  <PackingListModal
      v-if="showPackingListModal"
      :localModalStatus="showPackingListModal"
      :ordersForPacking="productsToShip"
      @update:localModalStatus="showPackingListModal = false"/>

  <BOffcanvas
      v-model="showCallInfo.status"
      :title="showCallInfo.title"
      :type="showCallInfo.type"
      @hide="closeCallInfo"
      placement="end"
      backdrop="false"
      lazy
  >

    <!--    <div v-for="(calls, phoneNumber) in callHistory"

        >


          <div
              class="padding-side-3 div-table-cell"
              :class="calls[0].phoneIcons.icon"
              v-b-tooltip.hover.left="calls[0].phoneIcons.alt"
          />
          <div class="padding-side-3 div-table-cell"
               v-b-tooltip.hover.left="'К-сть викликів за останні 3 дні'"
          >
            {{ '(' + calls.length + ')' }}
          </div>
          <div class="div-table-cell">{{ phoneNumber }}</div>
          <div class="div-table-cell">{{ calls[0].billsec }}</div>
          <div class="div-table-cell">
            {{ calls[0].calldate }}
          </div>
          <div>
            <BButton
                size="sm"
                variant="outline-success"
                @click="showCallInfo"
                class="padding-side-3"
                v-b-tooltip.hover.left="'Показати усі дзвінки по номеру'"
            >
              more
            </BButton>
          </div>



        </div>-->

    <div>
      <BRow>
        <div>
          <BButton size="sm" variant="outline-primary" @click="fetchCallHistory">Оновити</BButton>
          <BButton size="sm" variant="outline-secondary" @click="changeDate = !changeDate" role="button">Обрати інший
            період
          </BButton>
        </div>
        <div v-if="changeDate === true" class="d-flex justify-content-around">
          <BInputGroup prepend="Від">
            <BFormInput type="date" v-model="dateFrom" placeholder="дата від">Від</BFormInput>
          </BInputGroup>
          <BInputGroup prepend="До">
            <BFormInput type="date" placeholder="дата до" v-model="dateTo"
                        @change="fetchCallHistory"
            >До
            </BFormInput>
          </BInputGroup>
        </div>
      </BRow>
      <!-- Table Header -->
      <div class="d-flex align-items-center bg-light font-weight-bold">
        <div style="width: 40px;"></div>
        <div class="div-table-cell" style="width: 50px;" title="Кількість викликів за останні 3 дні">К-ть</div>
        <div class="div-table-cell" style="width: 150px;">Телефон</div>
        <div class="div-table-cell" style="width: 100px;" title="Тривалість виклику у секундах">Трив</div>
        <div class="div-table-cell" style="width: 200px;">Час</div>
      </div>
      <!-- Table Rows -->
      <div v-if="Object.keys(callHistory).length > 0">
        <div v-for="(calls, phoneNumber) in callHistory" :key="calls[0].uniqueid" class="mt-2 border-bottom">
          <div v-b-toggle="'collapse-' + calls[0].uniqueid"
               role="button"
          >
            <div>
              <div class="d-flex align-items-center ">
                <div
                    :class="calls[0].phoneIcons.icon ?? ''"
                    v-b-tooltip.hover.left="calls[0].phoneIcons.alt ?? ''"
                    style="width: 40px;"
                >
                </div>
                <div class=" div-table-cell" v-b-tooltip.hover.left="'К-сть викликів за останні 3 дні'"
                     style="width: 50px;">
                  {{ '(' + calls.length + ')' }}
                </div>

                <div class="div-table-cell" style="width: 150px;">
                  <BLink v-if="userData.ip_telephony !== true" :href="`tel:38${phoneNumber}`">
                    {{ phoneNumber }}
                  </BLink>
                  <BLink v-else @click.stop="callToClient(phoneNumber)">{{
                      phoneNumber
                    }}
                  </BLink>
                </div>
                <div class="div-table-cell" style="width: 100px;">{{ calls[0].billsec + 'c.' }}</div>
                <div class="div-table-cell" style="width: 200px;">{{ calls[0].calldate }}</div>
              </div>
              <div v-if="relatedPhoneNumbersToOrders[phoneNumber] && relatedPhoneNumbersToOrders[phoneNumber].length">
                <div class="buyer-info">
                  Покупець: {{
                    relatedPhoneNumbersToOrders[phoneNumber][relatedPhoneNumbersToOrders[phoneNumber].length - 1].buyerName
                  }}
                </div>

                <div class="order-info d-flex justify-content-around">
                  <div class="order-source" style="width: 30%;">
                    {{
                      relatedPhoneNumbersToOrders[phoneNumber][relatedPhoneNumbersToOrders[phoneNumber].length - 1].source
                    }}
                  </div>
                  <div class="order-status" style="width: 50%;">
                    {{
                      relatedPhoneNumbersToOrders[phoneNumber][relatedPhoneNumbersToOrders[phoneNumber].length - 1].CrmOrderStatus.name
                    }}
                  </div>
                  <div class="order-price" style="width: 20%;">
                    {{
                      relatedPhoneNumbersToOrders[phoneNumber][relatedPhoneNumbersToOrders[phoneNumber].length - 1].totalPrice
                    }} грн
                  </div>
                </div>
                <div class="order-comment">
                  {{
                    relatedPhoneNumbersToOrders[phoneNumber][relatedPhoneNumbersToOrders[phoneNumber].length - 1].orderComment
                  }}
                </div>
              </div>
            </div>
          </div>
          <BCollapse :id="'collapse-' + calls[0].uniqueid" class="mt-2">
            <BCard>
              <!--            <BCard class="card-body">-->
              <!-- Collapsible content here -->


              <!--            relatedPhoneNumbersToOrders-->
              <b-tabs>
                <!-- Calls Tab -->
                <b-tab title="Дзвінки" active>
                  <template v-for="call in calls" :key="call.uniqueid">
                    <div class="d-flex justify-content-around mb-5px">
                      <div :class="call.phoneIcons?.icon" style="width: 10%;"></div>
                      <div style="width: 40%;">{{ call.calldate }}</div>
                      <div style="width: 24%;">{{ call.billsec }} сек</div>
                      <BButton size="sm" v-if="call.recordingfile !== ''" @click="playAudio(call)"
                               style="width: 16%;">
                        Play
                      </BButton>
                    </div>
                  </template>
                </b-tab>

                <!-- Orders Tab -->
                <b-tab title="Замовлення">
                  <template v-for="order in relatedPhoneNumbersToOrders[phoneNumber]" :key="order.crm_order_id">
                    <div class="mb-5px border-bottom">
                      <a href="#" @click="openOrder(order)">Заказ {{ order.order_id }} від {{ order.orderDate }}</a>
                      <div>{{ order.deliveryType }} {{ order.TtnNumber }}</div>
                      <div> Статус замовлення: {{ order.CrmOrderStatus.name }}</div>
                      <div> Статус доставки: {{ order.deliveryStatus }}</div>
                      <div v-if="order.paymentStatus">Сплачено</div>
                      <div>{{ order.totalPrice }} грн</div>
                      <div>Джерело: {{ order.source }}</div>
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
              <BButton size="sm" :href="'viber://chat?number=38' + phoneNumber">Написати у Viber</BButton>
            </BCard>
          </BCollapse>
        </div>
      </div>
    </div>
  </BOffcanvas>

  <!--  <PrintPackingListModal
        v-if="showPrintPackingListModal"
        :ordersForPrint="ordersForPrint"
        />-->

  <div class="container-fluid">
    <div class="tabs-container"
         @mouseover="showArrows = true"
         @mouseleave="showArrows = false">

      <BButton v-if="showArrows && scrolledRight" size="sm" variant="outline-success"
               class="scroll-arrow left-arrow" @click="scrollLeft">&lt;
      </BButton>
      <div ref="containerRef" class="overflow-scroll w-100">
        <b-tabs class="mt-3 " v-model="activeTab" small>
          <!--          <b-tab v-for="(tab, tabId) in tabs" :key="tab.name" lazyOnce :title="tab.title"-->
          <b-tab v-for="(tab, tabId) in tabsInUse" :key="tabs[tab].name" lazyOnce :title="tabs[tab].title"
                 class="scrollable-tabs nav-item" role="presentation">
            <template #title>
              {{ tabs[tab].title }}
              <BBadge variant="danger" class="badge-position" v-if="tabsCount[tabId + 1] > 0">{{
                  tabsCount[tabId + 1]
                }}
              </BBadge>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <BButton v-if="showArrows" variant="outline-success" size="sm" class="scroll-arrow right-arrow"
               @click="scrollRight">&gt;
      </BButton>
    </div>

    <div class="div-table ">
      <template>
        <div class="outer-wrapper">
          <div class="sticky-header">
            <BButtonToolbar class="button-toolbar mt-3 mb-3 " size="sm">



              <BFormCheckbox class="ml-3" switch size="sm" v-if="waitForShipButton" v-model="packingMode">
                Режим упаковки
              </BFormCheckbox>


              <BButtonGroup v-if="waitForShipButton && packingMode">
                <BButton
                    v-b-tooltip.hover.top="!isAnyRowSelected ? '' : ''"
                    :disabled="!isAnyRowSelected"
                    variant="outline-primary" size="sm"
                    @click="showSummaryProductsToShip"
                >
                  Звіт по товарам на відправку для комірника
                </BButton>



                <BButton
                    :disabled="!isAnyRowSelected"
                    variant="outline-secondary" size="sm"
                    @click="getOrdersForPrint"
                >
                  Друк списку
                </BButton>
              </BButtonGroup>
              <BButtonGroup v-show="!packingMode" class="">
                <BButton variant="primary" size="sm"
                         @click="openModal(null, {'title': 'Нове замовлення', 'modalType': 'insert'})"
                >Створити замовлення
                </BButton>
                <!--              <BButton variant="primary" size="sm"
                                       @click="sendMessage"
                              >Створити замовлення
                              </BButton>-->

                <BButtonGroup v-show="waitForShipButton">
                  <template :title="isAnyRowSelected ? '' : 'Массове створення ТТН. ' +
                               'Для початку оберіть потрібні замовлення'">
                    <BButton size="sm" variant="outline-primary" :disabled="!isAnyRowSelected"
                             @click="createTtns">
                      {{ createTtnButtonLabel }}
                      <BSpinner v-if="spinerActive.createTtn" small/>
                    </BButton>
                  </template>

                  <template :title="isAnyRowSelected ? '' :
                               'Для початку оберіть потрібні замовлення'">
                    <!--                    <BButton size="sm" variant="outline-primary" :disabled="!isAnyRowSelected"
                                                 @click="createCheks">
                                          Створити чеки
                                          <BSpinner v-if="spinerActive.createChecks" small/>
                                        </BButton>-->


                    <BDropdown
                        variant="outline-primary"
                        size="sm"
                        :disabled="!isAnyRowSelected"
                        class="mx-1"
                        right
                    >
                      <template #button-content>
                                <span>
                                    Чеки
                                    <BSpinner v-if="spinerActive.createChecks" small/>
                                  </span>
                      </template>
                      <BDropdownItem @click="createCheks(false)">Створити чек</BDropdownItem>
                      <BDropdownItem @click="printCheks">Роздрукувати чек</BDropdownItem>
                      <BDropdownItem @click="createCheks(true)">Скасувати чек</BDropdownItem>
                    </BDropdown>


                  </template>


                  <template :title="isAnyRowSelected ? '' : 'Спочатку оберіть потрібні замовлення'">
                    <!--                  <BButton size="sm" variant="outline-primary" :disabled="!isAnyRowSelected"
                                               @click="printmarkings">
                                        Друк ТТН
                                        <BSpinner v-if="spinerActive.printTtn" small/>
                                      </BButton>-->

                    <BDropdown
                        variant="outline-primary"
                        size="sm"
                        :disabled="!isAnyRowSelected"
                        class="mx-1"
                        right
                    >

                      <template #button-content>
                                <span>
                                    Друк ТТН
                                    <BSpinner v-if="spinerActive.printTtn" small/>
                                  </span>
                      </template>


                      <BDropdownItem @click="printmarkings('zebra')">Друк маркувань зебра</BDropdownItem>
                      <BDropdownItem @click="printmarkings('6xA4')">Маркування на А4 (6 наклейок)</BDropdownItem>


                    </BDropdown>

                  </template>
                  <!--                    <BDropdown :title="isAnyRowSelected ? '' : 'Спочатку оберіть потрібні замовлення'"
                                                 variant="outline-primary" size="sm" :disabled="!isAnyRowSelected" class="mx-1" right
                                                 :text="reestrButton">-->
                  <BDropdown
                      variant="outline-primary"
                      size="sm"
                      :disabled="!isAnyRowSelected"
                      class="mx-1"
                      right
                  >
                    <template #button-content>
                                <span>
                                    {{ reestrButton }}
                                    <BSpinner v-if="spinerActive.createReestr" small/>
                                  </span>
                    </template>
                    <BDropdownItem @click="addToReestr">Додати у реєстр</BDropdownItem>
                    <BDropdownItem @click="printReestr">Роздрукувати реєстр</BDropdownItem>
                    <BDropdownItem @click="removeFromReestr">Видалити з реєстру</BDropdownItem>
                  </BDropdown>
                  <template :title="isAnyRowSelected ? 'Розіслати ТТН в СМС, вивантажити ТТН та змінити статуси замовлень на маркетплейсах'
                               : 'Розіслати ТТН в СМС, вивантажити ТТН та змінити статуси замовлень на маркетплейсах. Спочатку оберіть потрібні замовлення'">
                    <BButton size="sm" @click="sendBulkSmsWithTtns" variant="outline-primary"
                             :disabled="!isAnyRowSelected">
                      Розіслати ТТН у СМС
                      <BSpinner v-if="spinerActive.sendBulkSms" small/>
                    </BButton>
                  </template>


                </BButtonGroup>


                <BButtonGroup v-show="parselInRoadOrCanceledOrderStatusButtons">
                  <BButton variant="outline-primary" size="sm" :disabled="!isAnyRowSelected"
                           @click="createReturn">
                    Створити повернення
                  </BButton>


                  <BDropdown
                      variant="outline-primary"
                      size="sm"
                      :disabled="!isAnyRowSelected"
                      class="mx-1"
                      right
                  >
                    <template #button-content>
                                <span>
                                    Автообзвін
                                    <BSpinner v-if="spinerActive.roboCall" small/>
                                  </span>
                    </template>
                    <BDropdownItem @click="initRoboCall('remindGetParsel1')">Сповістити про прибуття посилки
                    </BDropdownItem>
                    <BDropdownItem @click="initRoboCall('remindGetParsel2')">Повідомити що через 3 дні посилка
                      повернеться
                    </BDropdownItem>
                  </BDropdown>
                  <BButton size="sm" variant="outline-primary" @click="openChangeOrderStatusesByNumberModal"
                           v-b-tooltip.hover.top="'Массова зміна статусів замовлень за номерами ТТН'">
                    Змінити статус замовлення по номерам
                  </BButton>
                </BButtonGroup>
                <BButtonGroup>
                  <template :title="isAnyRowSelected ? 'Оберіть потрібний статус'
                              : 'Массова зміна статусів замовлень. Спочатку оберіть потрібні замовлення'">
                    <BDropdown
                        size="sm"
                        :class="{ 'disabled': !isAnyRowSelected }"
                        :disabled="!isAnyRowSelected"
                        @show="fillOrderStatuses"
                        text="Змінити статус"
                        variant="btn outline-primary btn-sm me-2">
                      <BDropdownItem v-for="status in orderStatuses"
                                     :key="status.id"
                                     @click="changeOrderStatus(status.id)"
                      >{{ status.name }}
                      </BDropdownItem>
                    </BDropdown>
                  </template>


                </BButtonGroup>
                <BButton v-if="activeTab === 0 || activeTab === 1 || activeTab === 10 || activeTab === 8"
                         v-b-tooltip.hover.top="!isAnyRowSelected ? '' : ''"
                         :disabled="!isAnyRowSelected"
                         variant="outline-primary" size="sm"
                         @click="showSummaryProductsToShip"
                         class="ml-2"
                >
                  Звіт по товарам на відправку для комірника
                </BButton>
                <template>
                  <BButton
                      v-b-tooltip.hover.top="isAnyRowSelected ? '' : 'Оберіть потрібні замовлення'"
                      variant="outline-danger" size="sm" @click="changeOrderStatus(14)"
                      :class="{ 'disabled': !isAnyRowSelected }"
                      :disabled="!isAnyRowSelected">
                    Видалити
                  </BButton>
                </template>

                <importOrdersFromExcel v-if="activeTab === 1" />

              </BButtonGroup>

            </BButtonToolbar>
            <div class="header-row">
              <draggable class="div-table-row border-top" v-model="commonHeaders"
                         :item-key="header => header.columnId">
                <template #item="{ element: header }">
                  <div :class="['div-table-cell dragging', header.columnWidth]"
                       v-if="header.columnId === 'checkbox'">
                  </div>
                  <div :class="['div-table-cell dragging', header.columnWidth]" :title="header.text" v-else>
                    {{ header.text }}
                  </div>
                </template>
              </draggable>
            </div>
            <div class="filter-row">
              <div class="div-table-row ">
                <div v-for="header in commonHeaders" :key="header.columnId"
                     :class="[ header.columnWidth, 'div-table-cell-multisearch'] ">
                  <VueMultiselect
                      v-if="header.options"
                      v-model="header.filter"
                      :options="header.options"
                      :taggable="false"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :selectLabel="''"
                      placeholder="Шукати"
                      label="value"
                      track-by="value"
                      @open="() => handleOpen(header)"
                  >
                    <template #option="{ option }">
                      {{ option.value }} ({{ option.quantity }})
                    </template>
                  </VueMultiselect>

                  <div v-else-if="header.columnId === 'rowId'"></div>
                  <input v-else-if="header.columnId === 'checkbox'"
                         :class="['div-table-cell',  header.columnWidth]"
                         :type="header.type"
                         v-model="header.filter"
                         @click="header.ev($event.target.checked)"
                         placeholder="Шукати"
                         class="input-multiselect-style"
                  />
                  <input v-else
                         :class="['div-table-cell',  header.columnWidth]"
                         :type="header.type"
                         v-model="header.filter"
                         placeholder="Шукати"
                         class="input-multiselect-style"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="inner-wrapper" :style="{ width: totalInnerWrapperWidth + 'px' }">
            <RecycleScroller :items="filteredOrders"
                             :item-size="42"
                             key-field="crm_order_id"
                             class="div-table-body scroller"
                             page-mode
            >

              <!--              :class="{ 'row-hover': hoveredRow === item.crm_order_id, 'selected-row': isRowSelected(item.crm_order_id) }"-->
              <template v-slot="{ item, index }">
                <div class="div-table-row" :key="item.crm_order_id"
                     @mousedown="handleRowClick($event, item.crm_order_id)"
                     :class="{ 'selected-row': isRowSelected(item.crm_order_id) }"
                     @mouseover="setHoveredRow(item.crm_order_id)"
                     @mouseleave="clearHoveredRow"
                >

                  <div v-for="header in commonHeaders" :key="header.columnId"
                       :id="header.columnId"
                       :class="['div-table-cell', header.columnWidth, { 'selected-row': isRowSelected(item.id) }]">

                    <template v-if="header.columnId === 'checkbox'">
                      <BFormCheckbox v-model="selectedOrders" :key="item.crm_order_id"
                                     :value="item.crm_order_id">
                      </BFormCheckbox>
                    </template>
                    <template v-else-if="packingMode && header.columnId === 'openOrder'">
                      <BButton size="sm"
                               variant="outline-secondary"
                               @click="openPackingModal(item)">
                        Open
                      </BButton>
                    </template>
                    <template v-else-if="header.columnId === 'OrderNumber'">
                      {{ item.sourceData['OrderNumber'] }}
                      <BButton size="sm"
                               variant="outline-secondary"
                               @click="openModal(item, {'title': 'Редагувати замовлення', 'modalType': 'update'})">
                        ред.
                      </BButton>
                    </template>
                    <template v-else-if="header.columnId === 'date'">
                      {{ formatDate(item[header.columnId]) }}
                    </template>
<!--                    <template v-else-if="header.columnId === 'smsWithTtnSent'">{{
                        getSmsSentStatus(item.smsHistory)
                      }}
                    </template>-->

                    <template v-else-if="header.columnId === 'last_sms_status'">{{
                        item.last_sms_status
                      }}
                    </template>

                    <template
                        v-else-if="header.columnId === 'BuyerPhone' || header.columnId === 'DeliveryPhoneSearch'"
                        :title="item.dontCall === 'Так' ? '' : 'Не здвонити' "
                        :style="item.dontCall === 'Так' ? '' : 'color: red' "
                    >
                      <BLink v-if="userData.ip_telephony !== true" :href="`tel:380${item[header.columnId]}`">
                        0{{ item[header.columnId] }}
                      </BLink>
                      <BLink v-else @click="callToClient(item[header.columnId])">0{{
                          item[header.columnId]
                        }}
                      </BLink>
                    </template>
                    <template v-else-if="header.columnId === 'callsHistory'">
                      <div class="d-flex justify-content-between"
                           v-if="callHistory['0' + item.BuyerPhone]?.[0].phoneIcons.icon !== ''">
                        <div class="d-flex some">
                          <div v-show="callHistory['0' + item.BuyerPhone]?.length !== undefined"
                               class="padding-side-3"
                               v-b-tooltip.hover.left="'К-сть викликів за останні 3 дні'">
                            {{ '(' + callHistory['0' + item.BuyerPhone]?.length + ')' }}
                          </div>
                          <div
                              class="padding-side-3"
                              :class="callHistory['0' + item.BuyerPhone]?.[0].phoneIcons.icon"
                              v-b-tooltip.hover.left="callHistory['0' + item.BuyerPhone]?.[0].phoneIcons.alt"
                          />
                        </div>
                        <div>
                          {{ callHistory['0' + item.BuyerPhone]?.[0].calldate }}
                        </div>
                      </div>
                    </template>

                    <template v-else-if="header.columnId === 'roboCallStatus'">
                      {{ roboCallStats[item.crm_order_id]?.roboCallStatus }}

                    </template>


                    <template v-else-if="header.columnId === 'orderComment'">
                      {{ item.orderComment }}
                      <!--                    <span v-b-tooltip.hover.left="item.orderComment">{{ item.orderComment }} </span>-->
                    </template>
                    <template v-else-if="header.columnId === 'MarketplacePaymentType'">
                      {{ getPaymentType(item) }}
                    </template>
                    <template v-else-if="header.columnId === 'products'">
                      <span v-b-tooltip.hover.left="item.ordered_products_summary">{{
                          item.ordered_products_summary
                        }}</span>
                    </template>
                    <template v-else-if="header.columnId === 'seatsAmounts'">
                      {{ item.seatsAmounts.length }}
                    </template>

                    <template v-else>
                      {{
                        item[header.columnId]
                        || item.sourceData[header.columnId]
                        || item.deliveryData[header.columnId]
                        || item.check_data[header.columnId]
                      }}
                    </template>
                  </div>
                </div>
              </template>
            </RecycleScroller>
          </div>
        </div>
      </template>
    </div>


    <!--
        <Popup v-if="showPopup" :pushPopUpState="showPopup" @update:showPopup="showPopup = $event" />
    -->
    <popup v-if="showPopup" v-model="showPopup"/>

  </div>

  <changeOrderStatusysByNumberModal/>
</template>

<script>
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {RecycleScroller} from 'vue-virtual-scroller'
import draggable from "vuedraggable";
import confirmModal from "@/components/ConfirmModal";
import VueMultiselect from 'vue-multiselect'

import changeOrderStatusysByNumberModal from "@/components/ChangeOrderStatusysByNumberModal";
import PackingModal from "@/components/PackingModal";
import PackingListModal from "@/components/PackingListModal";
import PrintPackingListModal from "@/components/PrintPackingListModal";
import Popup from "./Popup.vue";

//import { requestNotificationPermission, onMessageListener } from '';


import {
  BBadge,
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BLink,
  BModal,
  BOffcanvas,
  BSpinner,
  BTab,
  BTabs
} from 'bootstrap-vue-next';
import customModal from './Modal.vue';
import {formatDate} from '../../utils/orderFunctions';
import {useStore} from "vuex";
import axios from "axios";
import {
  createCheksQuery,
  getCallsHistory, getRobocallState,
  getTabsCount,
  makeCall,
  printCheksQuery, roboCallInit, savePushKey,
  sendRequestRemoveFromReestr
} from "@/axios/settignsRequests";
import {useCallToClient} from "@/asterisk/useCallToClient";
import apiCall from "@/axios/apiRequests";
import {onMessageListener, requestNotificationPermission} from "../../public/firebase";
import importOrdersFromExcel from "@/components/importOrdersFromExcel"
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  setup() {

    const store = useStore();
    const createTtnButtonLabel = ref('Створити ТТН')
    const spinerActive = ref({})
    //console.log('orders opened')

    spinerActive.value = {
      createTtn: false,
      printTtn: false,
      printReestr: false,
      createReestr: false,
      sendBulkSms: false,
      createChecks: false,
      removeFromReestr: false,
      roboCall: false,
    }

    // const changeOrderStatusesByNumberModal = ref(false)

    const openChangeOrderStatusesByNumberModal = () => {
      store.commit('changeOrderStatusByTtn/changeModalStatus', {
        'title': 'Змінити статуси замовлень по номерам',
        'type': 'changeStatus',
      })
      //  changeOrderStatusesByNumberModal.value = !changeOrderStatusesByNumberModal.value
    }


    /*    const paymentReportState = computed(() => {
          return store.getters['changeOrderStatusByTtn/getModalStatus'];
        });*/


    const packingMode = ref(false)

    const paymentTypeNames = {
      2: {id: 2, name: 'Післяплата'},
      3: {id: 3, name: 'Післяплата'},
      4: {id: 4, name: 'Предоплата'},
      5: {id: 5, name: 'Готівка(самовивіз)'},
    };

    const router = useRouter();
    const route = useRoute();

    const hoveredRow = ref(null);

    const setHoveredRow = (rowId) => {
      hoveredRow.value = rowId;
    };

    const clearHoveredRow = () => {
      hoveredRow.value = null;
    };

    const userData = ref(JSON.parse(localStorage.getItem('userData')) || {});

    const svgContent = reactive(
        {
          'missed': '',
          'outgoing': '',
          'incoming': '',
          'busy': '',
        }
    )

    const fetchOrders = async (tabIndex) => {
      try {

        const fetchedOrdersLenght = await store.dispatch('order/getOrders', tabIndex);

        console.log('fetchedOrdersLenght', fetchedOrdersLenght);

        const fetchedOrderStatusesCounts = await getOrderStatusesCount();

        if (fetchedOrderStatusesCounts !== fetchedOrdersLenght) {
          // console.log('fetchedOrderStatusesCounts ', fetchedOrderStatusesCounts)
          //  console.log('fetchedOrdersLenght ', fetchedOrdersLenght)
          await getTabsCount();
        }

        if ([3, 4, 5].includes(tabIndex) && userData.value.ip_telephony === true) {

          getRobocallStatus();
        }

      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };

    function getRobocallStatus() {
      let ordersIds = [];

      orders.value.map((order) => {
        // console.log('order', order)
        ordersIds.push(order.crm_order_id);
      });

      const query = {
        ordersIds: ordersIds,
        type: 'remindGetParsel1'
      };

      // console.log('join in 5', query);

      if (ordersIds.length > 0) {
        getRobocallState(query).then((response) => {
          //  console.log('response.data', response.data)
          if (response.status === 'success') {
            roboCallStats.value = response.data;
          }
        });
      }
    }


    /*
      icon-incoming-call-red
      icon-incoming-call-green
      icon-missed-call-red
      icon-busy-call-red
      icon-out-call-red
      icon-out-call-green
      icon-active-call-green
    */

    const getIcon = (callValue) => {

      // console.log('callValue1', callValue);
      //  callValue = callHistory.value[callValue] ? callHistory.value[callValue][0] : null;

      //  console.log('callValue2', callValue);
      if (callValue === null) {
        return {
          'icon': '',
          'color': '',
          'alt': ''
        }
      }

      //  console.log('callValue.disposition', callValue?.disposition)

      if (callValue.lastapp === 'Dial' || callValue.lastapp === 'Congestion') { // вихідний дзвінок
        // BUSY NO ANSWER ANSWERED

        if (callValue.disposition === 'ANSWERED') {
          return {
            'icon': 'icon-out-call-green',
            'color': 'green',
            'alt': 'Успішний вихідний'
          };
        } else if (callValue.disposition === 'NO ANSWER') {
          return {
            'icon': 'icon-out-call-red',
            'color': 'red',
            'alt': 'Не відповів'
          };

        } else if (callValue.disposition === 'BUSY' || callValue.disposition === 'CONGESTION') {
          return {
            'icon': 'icon-busy-call-red',
            'color': 'red',
            'alt': 'Зайнято'
          };
        } else if (callValue.disposition === 'FAILED') {
          return {
            'icon': 'icon-busy-call-red',
            'color': 'red',
            'alt': 'Помилка'
          };
        }
        /* else if(callValue.disposition === 'ABANDON'){
          return 'mdi-phone-missed';
        } else if(callValue.disposition === 'CANCEL'){
          return 'mdi-phone-missed';
        } else if(callValue.disposition === 'CONGESTION'){
          return 'mdi-phone-missed';
        } else if(callValue.disposition === 'CHANUNAVAIL'){
          return 'mdi-phone-missed';
        } else if(callValue.disposition === 'FAILED'){
          return 'mdi-phone-missed';
        } else if(callValue.disposition === 'UNKNOWN'){
          return 'mdi-phone-missed';
        } else {
          return 'mdi-phone-missed';
        }*/

      } else { // вхідний
        //  ABANDON COMPLETEAGENT  COMPLETECALLER

        if (callValue.disposition === 'ANSWERED') {
          return {
            'icon': 'icon-incoming-call-green',
            'color': 'green',
            'alt': 'Успішний вхідний'
          };
        } else if (callValue.disposition === 'ABANDON') {
          return {
            'icon': 'icon-missed-call-1red',
            'color': 'red',
            'alt': 'Пропущений'
          };

        } else if (callValue.disposition === 'COMPLETEAGENT') {
          return {
            'icon': 'icon-incoming-call-green',
            'color': 'green',
            'alt': 'Успішний вхідний'
          };

        } else if (callValue.disposition === 'COMPLETECALLER') {
          return {
            'icon': 'icon-incoming-call-green',
            'color': 'green',
            'alt': 'Успішний вхідний'
          };

        } else {
          return {
            'icon': '',
            'color': '',
            'alt': ''
          };
        }

      }

      /*      if(callValue.lastapp === 'dial'){ // вихідний дзвінок
              // BUSY NO ANSWER ANSWERED

              if(callValue.disposition === 'ANSWERED'){
                return {
                  'icon': svgContent.outgoing,
                  'color': 'green',
                  'alt': 'Успішних вихідний'
                };
              } else if(callValue.disposition === 'NO ANSWER'){
                return {
                  'icon': svgContent.outgoing,
                  'color': 'red',
                  'alt': 'Не відповів'
                };

              } else if(callValue.disposition === 'BUSY'){
                return {
                  'icon': svgContent.busy,
                  'color': 'red',
                  'alt': 'Зайнято'
                };
              }

              /!* else if(callValue.disposition === 'ABANDON'){
                return 'mdi-phone-missed';
              } else if(callValue.disposition === 'CANCEL'){
                return 'mdi-phone-missed';
              } else if(callValue.disposition === 'CONGESTION'){
                return 'mdi-phone-missed';
              } else if(callValue.disposition === 'CHANUNAVAIL'){
                return 'mdi-phone-missed';
              } else if(callValue.disposition === 'FAILED'){
                return 'mdi-phone-missed';
              } else if(callValue.disposition === 'UNKNOWN'){
                return 'mdi-phone-missed';
              } else {
                return 'mdi-phone-missed';
              }*!/

            } else { // вхідний
            //  ABANDON COMPLETEAGENT  COMPLETECALLER

              if(callValue.disposition === 'ANSWERED'){
                return {
                  'icon': svgContent.incoming,
                  'color': 'green',
                  'alt': 'Успішний вхідний'
                };
              } else if(callValue.disposition === 'ABANDON'){
                return {
                  'icon': svgContent.missed,
                  'color': 'red',
                  'alt': 'Пропущений'
                };

              } else if(callValue.disposition === 'COMPLETEAGENT'){
                return {
                  'icon': svgContent.incoming,
                  'color': 'green',
                  'alt': 'Успішний вхідний'
                };

              } else if(callValue.disposition === 'COMPLETECALLER'){
                return {
                  'icon': svgContent.incoming,
                  'color': 'green',
                  'alt': 'Успішний вхідний'
                };

              } else {
                return {
                  'icon': svgContent.missed,
                  'color': 'red',
                  'alt': 'Пропущений'
                };
              }

            }*/
    }


    //const callHistory = ref([]);

    const callHistory = ref({});


    /*const callHistory = {
      "3": [
        {
          "disposition": "ABANDON",
          "uniqueid": "1715653270.2500",
          "lastapp": "Hangup",
          "outbound_cnum": "",
          "calldate": "2024-05-14 05:21:10",
          "src": "380960414359",
          "dcontext": "play-system-recording",
          "billsec": "7",
          "recordingfile": "",
          "linkedid": "1715653270.2500",
          "dst": "3"
        }
      ],
      "5000": [
        {
          "disposition": "ABANDON",
          "uniqueid": "1715581333.2454",
          "lastapp": "Playback",
          "outbound_cnum": "",
          "calldate": "2024-05-13 09:22:13",
          "src": "380961719540",
          "dcontext": "ext-queues",
          "billsec": "5",
          "recordingfile": "q-5000-380961719540-20240513-092213-1715581333.2454.WAV",
          "linkedid": "1715581333.2454",
          "dst": "5000"
        }
      ],
    }*/


    function threeDaysAgo() {
      // Get the current date
      const currentDate = new Date();

      // Set the time to the beginning of the day
      currentDate.setHours(0, 0, 0, 0);

      // Subtract 3 days
      currentDate.setDate(currentDate.getDate() - 7);

      return currentDate;
    };

    const dateFrom = ref(threeDaysAgo());
    //const dateTo = ref(new Date());

    const dateTo = ref(new Date());
    dateTo.value.setHours(0, 0, 0, 0); // встановлюємо час на початок поточного дня
    dateTo.value.setDate(dateTo.value.getDate() + 1); // додаємо один день, щоб отримати початок завтрашнього дня

    //console.log(dateTo.value.toISOString()); // виводимо дату у форматі ISO 8601

    const missedCalls = ref([]);

    const phoneNumbersOfCallsForLastTwoDays = [];

    async function fetchCallHistory() {

      /*
        {
          "disposition":"ANSWERED",
          "uniqueid":"1715580974.2444",
          "lastapp":"Dial",
          "outbound_cnum":"380735145420",
          "calldate":"2024-05-13 09:16:14",
          "src":"380735145420",
          "dcontext":"from-internal",
          "billsec":"36",
          "recordingfile":"out-0984691263-5001-20240513-091614-1715580974.2444.WAV",
          "linkedid":"1715580974.2444",
          "dst":"0984691263"
        }
      */

      const missed = await import('@/assets/img/phone-missed.svg?raw');
      const outgoing = await import('@/assets/img/phone-outgoing.svg?raw');
      const incoming = await import('@/assets/img/phone-incoming.svg?raw');
      const busy = await import('@/assets/img/phone-off.svg?raw');

      svgContent.missed = missed.default;
      svgContent.outgoing = outgoing.default;
      svgContent.incoming = incoming.default;
      svgContent.busy = busy.default;

      /* let res = await getCallsHistory({
         date_from: dateFrom.value,
         date_to: dateTo.value,
       });*/

      //`date_from=${res.date_from}&date_to=${res.date_to}`

      let res = await getCallsHistory(`date_from=${dateFrom.value.toISOString()}&date_to=${dateTo.value.toISOString()}`);


      // console.log('res:', res)

      if (!res['callsHistory'] || res['callsHistory'].length === 0) {
        missedCalls.value = [];
        return;
      }

      const callsHistory = res['callsHistory'];
      // convert missed calls to res format
      let groupedAndSorted = callsHistory.reduce((acc, item) => {
        // Determine the key based on the length of `dst`
        // console.log('item:', item);
        // console.log('acc', acc);
        item.src = item.src.startsWith('38') ? item.src.slice(2) : item.src;
        item.src = item.src.startsWith('+38') ? item.src.slice(3) : item.src;
        const key = item.dst.length < 7 ? item.src : item.dst;

        /* if(dst === 'hangup'){

         }*/

        // Initialize the array if this key hasn't been used before
        if (!acc[key]) {
          acc[key] = [];
        }

        // Push the current item into the array for the chosen key
        acc[key].push(item);
        return acc;
      }, {});


      // console.log('groupedAndSorted', groupedAndSorted);

      // Sort the arrays of call records by date
      for (const key in groupedAndSorted) {
        groupedAndSorted[key].sort((a, b) => new Date(b.calldate) - new Date(a.calldate));
      }

      // Get the missed calls

      missedCalls.value = res['missedCalls'].map(item => item.src);

      store.commit('callInfoModule/setMissedCallsCount', missedCalls.value.length);

      /* missedCalls = [  "0960414359",
                          "0961719540",
                          "0634335246",
                          "0665906365",
                          "0979049005",
                          "0978493439",
                          "0982814933",
                          "0679305156"
      ]*/

      // Convert the object to an array of [key, value] pairs
      let groupedAndSortedArray = Object.entries(groupedAndSorted);

      groupedAndSortedArray.sort((a, b) => {
        const keyA = a[0];
        const keyB = b[0];
        const lastDateA = new Date(a[1][0].calldate);
        const lastDateB = new Date(b[1][0].calldate);

        // Check if keys are in missedCalls and sort accordingly
        const isMissedA = missedCalls.value.includes(keyA);
        const isMissedB = missedCalls.value.includes(keyB);

        if (isMissedA && !isMissedB) return -1;
        if (!isMissedA && isMissedB) return 1;
        return lastDateB - lastDateA;
      });

      // Add a class attribute with value 'danger' to missed calls


      for (const [key, calls] of groupedAndSortedArray) {
        if (missedCalls.value.includes(key)) {
          groupedAndSorted[key] = calls.map(call => ({
            ...call,
            class: 'danger'
          }));
        } else {
          groupedAndSorted[key] = calls;
        }
      }
      // Convert back to an object
      groupedAndSorted = Object.fromEntries(groupedAndSortedArray);

      //console.log('groupedAndSorted', groupedAndSorted);

      // Add phone icons and format call dates

      /*      function padZero(value) {
              return value < 10 ? '0' + value : value;
            }

            function formatDate(dateStr) {
              const date = new Date(dateStr);
              const now = new Date();
              const diff = now - date;
              const oneDay = 24 * 60 * 60 * 1000;

              const hours = padZero(date.getHours());
              const minutes = padZero(date.getMinutes());

              if (diff < oneDay && date.getDate() === now.getDate()) {
                return `${hours}:${minutes}`;
              } else if (diff < 2 * oneDay && date.getDate() === now.getDate() - 1) {
                return `вч. ${hours}:${minutes}`;
              } else {
                const monthNames = ["Січ", "Лют", "Бер", "Кві", "Трав", "Черв", "Лип", "Сер", "Вер", "Жов", "Лист", "Гру"];
                return `${date.getDate()} ${monthNames[date.getMonth()]} ${hours}:${minutes}`;
              }
            }

            for (const key in groupedAndSorted) {
              groupedAndSorted[key] = groupedAndSorted[key].map(item => ({
                ...item,
                phoneIcons: getIcon(item),
                calldate: formatDate(item.calldate)
              }));
            }*/

      function padZero(value) {
        return value < 10 ? '0' + value : value;
      }

      function isTodayOrYesterday(dateStr) {
        const date = new Date(dateStr);
        const now = new Date();
        const diff = now - date;
        const oneDay = 24 * 60 * 60 * 1000;
        if (diff < oneDay && date.getDate() === now.getDate()) {
          return true;
        } else if (diff < 2 * oneDay && date.getDate() === now.getDate() - 1) {
          return true;
        }
        return false;
      }

      function formatDate(dateStr) {
        const date = new Date(dateStr);
        const now = new Date();
        const diff = now - date;
        const oneDay = 24 * 60 * 60 * 1000;

        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());

        if (diff < oneDay && date.getDate() === now.getDate()) {
          return `${hours}:${minutes}`;
        } else if (diff < 2 * oneDay && date.getDate() === now.getDate() - 1) {
          return `вч. ${hours}:${minutes}`;
        } else {
          const monthNames = ["Січ", "Лют", "Бер", "Кві", "Трав", "Черв", "Лип", "Сер", "Вер", "Жов", "Лист", "Гру"];
          return `${date.getDate()} ${monthNames[date.getMonth()]} ${hours}:${minutes}`;
        }
      }


      /*     for (const key in groupedAndSorted) {
             groupedAndSorted[key] = groupedAndSorted[key].map(item => {
               if (isTodayOrYesterday(item.calldate)) {
                 if (!phoneNumbersOfCallsForLastTwoDays.includes(key)) {
                   phoneNumbersOfCallsForLastTwoDays.push(key);
                 }
               }

               return {
                 ...item,
                 phoneIcons: getIcon(item),
                 calldate: formatDate(item.calldate)
               };
             });
           }*/


      for (const key in groupedAndSorted) {
        groupedAndSorted[key] = groupedAndSorted[key].map(item => {
          /*   if (isTodayOrYesterday(item.calldate)) {
               // Remove the first symbol and get the last 9 symbols of the key
               const modifiedKey = parseInt(key.slice(-9), 10);

               if (!phoneNumbersOfCallsForLastTwoDays.includes(modifiedKey)) {
                 phoneNumbersOfCallsForLastTwoDays.push(modifiedKey);
               }
             }*/

          return {
            ...item,
            phoneIcons: getIcon(item),
            originalDate: item.calldate,
            calldate: formatDate(item.calldate)
          };
        });
      }


      callHistory.value = groupedAndSorted;


      //console.log('callHistory.value', callHistory.value);
    }


    const commonHeaders = ref();
    const activeTab = ref(0);
    const orders = computed(() => store.getters['order/getOrders'](activeTab.value) || '');
    const tabsCount = computed(() => store.getters['order/getTabCounts'] || '');

    // console.log('orders', orders.value)
    const modaslIsVisible = ref(false);
    const isAllRowsSelected = ref(false);
    const sources = ref([]);
    const marketPlaceStatuses = ref([]);
    const smsStatuses = ref([]);
    const orderNumberSearch = ref('');
    const buyerNameSearch = ref('');
    const commentSearch = ref('');
    const deliveryFioSearch = ref('');
    const phoneSearch = ref('');
    const DeliveryPhoneSearch = ref('');
    const marketplacePaymentTypes = ref([]);
    const senders = ref([]);
    const cities = ref([]);

    const ttnSearch = ref('');
    const deliveryStatuses = ref([]);
    const backTtnStatuses = ref([]);
    const selectedSources = ref([]);
    const selectedOrders = ref([]);
    const selectedMarketplacesStatuses = ref([]);
    const selectedSmsStatus = ref([]);
    const selectedCrmStatuses = ref([]);
    const selectedMarketplacePaymentTypes = ref([]);
    const selectedPackingStatus = ref([]);
    const selectedMarketPlacesPaymentStatuses = ref([]);
    const selectedPosts = ref([]);
    const selectAllOrders = ref(false);
    const selectedSenders = ref([]);
    const selectedCities = ref([]);
    const ttnPrintedStatus = ref([]);
    const roboCallStatuses = ref([]);
    const selectedPrintTtnStatus = ref([]);
    const selectedRobocallState = ref([]);

    const ReestrPrintOptions = ref([]);

    const ReestrsOptions = ref([]);

    const checkStatuses = ref([]);
    const checkActions = ref([]);
    const selectedReestrPrintStatus = ref([]);

    const selectedReestr = ref([]);

    const productSearch = ref('');
    const selectedDeliveryType = ref([]);
    const selectedDeliveryStatuses = ref([]);
    const selectedBackTtnStatuses = ref([]);
    const posts = ref([]);
    const marketPlacesPaymentStatuses = ref([]);

    /* const selectedPaymentTypes = ref([]);
   const paymentTypeNames = {
     2: 'Грошовий переказ',
     3: 'Контроль оплати',
     4: 'Без наложки',
     5: 'Готівка(самовивіз)',
   };*/

    /*    const checkOnInclude = (columnId, tabId) => {
          console.log('columnid/name', columnId);
          console.log('tabId', tabId);
          // return tabs[tabId].filteredHeaders.includes(columnId);
          tab.filteredHeaders.includes(header.columnId)
        };*/

    /*    const crmStatuses = [
      "Новий",
      "Очікує відправку",
      "Очікує предоплату",
      "У дорозі з наложкою",
      "У дорозі без наложки",
      "Зберігання/відмови",
      "Виконані",
      "Не успішні",
      "Очікує наявності",
      "Одержали повернення",
      "На комплектації"
    ];*/

    const crmStatuses = ref([]);
    const packingStatuses = ref([]);
    const deliveryTypes = ref([]);
    /*const deliveryTypes = [
      "До склада",
      "До дверей"
    ];*/
    const selectedSourceTypes = ref([]);
    const selectedCheckCreatedStatus = ref([]);

    const selectedCheckActions = ref([]);

    const sourceTypes = ref([]);
    const lastClickedRowIndex = ref(null);
    const isShiftKeyPressed = ref(false);
    const isCtrlKeyPressed = ref(false);

    const orderStatuses = ref([]);
    const showModal = ref(true);
    const modalTitle = ref({title: '', modalType: ''});
    const selectedOrder = ref(null);


    const openModal = (order, Title) => {
      // console.log('order', order);
      selectedOrder.value = order;
      showModal.value = true
      modalTitle.value = Title;
      modaslIsVisible.value = true;
    };


    const closeModal = (newValue) => {
      showModal.value = newValue;
      selectedOrder.value = null;
      modalTitle.value = {title: '', modalType: ''};
      //  console.log('showModal.value', showModal.value);
    };


    const showPackingModal = ref(false);

    const openPackingModal = (order) => {
      selectedOrder.value = order;
      showPackingModal.value = true;
    }

    const closePackingModal = (newValue) => {
      showPackingModal.value = newValue;
      selectedOrder.value = null;
    }

    const showPackingListModal = ref(false);
    const productsToShip = ref([]);

    /*    function showSummaryProductsToShip()  {

          let productsToShip = [];

          selectedOrders.value.forEach(orderId => {
            let order = filteredOrders.value.find(order => order.crm_order_id === orderId);
            let products = order.products;

            // calculate summary product quantity to shop
            products.forEach(product => {
              let productToShip = productsToShip.find(item => item.product_id === product.product_id);
              if (productToShip) {
                productToShip.quantity += product.quantity;
              } else {
                productsToShip.push({
                  product_id: product.product_id,
                  article: product.product.article,
                  product_name: product.product.name,
                  quantity: product.quantity,
                  image_url: product.product.thumbnail_url

                });
              }
            });
          });
          showPackingListModal.value = true;
          console.log('productsToShip', productsToShip);
        }*/

    // const showPrintPackingListModal = ref(false);

    const ordersForPrint = ref([]);

    /*    const getOrdersForPrint = () => {
          showPrintPackingListModal.value = true;
          ordersForPrint.value = [];
          selectedOrders.value.forEach(orderId => {
            let order = filteredOrders.value.find(order => order.crm_order_id === orderId);
            ordersForPrint.value.push(order);
          });
          console.log('ordersForPrint', ordersForPrint.value);
        }*/

    const getOrdersForPrint = () => {
      let htmlContent = `<html>
    <head>
      <style>
        body { font-family: 'Arial', sans-serif; font-size: 12px; margin: 0; padding: 5px; }
        .page-content { width: 210mm; min-height: 297mm; margin: auto; }
        .header, .row { display: flex; }
        .column { padding: 4px; text-align: center; }
      </style>
    </head>
    <body onload="window.print();">
      <div class="page-content">
        <div class="header">
          <div class="column" style="width: 10%">Джерело</div>
          <div class="column" style="width: 8%">Перевізник</div>
          <div class="column" style="width: 15%">ТТН</div>
          <div class="column" style="width: 10%">Одержувач</div>
          <div class="column" style="width: 10%">Комментар</div>
          <div class="column" style="width: 45%">Товари</div>
        </div>`;

      selectedOrders.value.forEach(orderId => {
        let order = filteredOrders.value.find(order => order.crm_order_id === orderId);
        htmlContent += `<div class="row">
      <div class="column" style="width: 10%">${order.SourceType}</div>
      <div class="column" style="width: 8%">${order.deliveryData.PostName}</div>
      <div class="column" style="width: 15%">${order.deliveryData.TtnNumber}</div>
      <div class="column" style="width: 10%">${order.deliveryData.DeliveryFio}</div>
      <div class="column" style="width: 10%">${order.orderComment}</div>
      <div class="" style="width: 45%">
      <div class="row" style="width: 100%">`;

        order.products.forEach(product => {
          htmlContent += `<div class="column" style="width: 85%">[${product.product.article}] ${product.product.name}</div>
                      <div class="column" style="width: 15%">${product.quantity} од.</div>`;
        });

        htmlContent += `</div></div></div>`;
      });

      htmlContent += `</div></body></html>`;

      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(htmlContent);
      printWindow.document.close();
    }


    function showSummaryProductsToShip() {
      productsToShip.value = [];
      console.log('selectedOrders.value', selectedOrders.value);
      selectedOrders.value.forEach(orderId => {
        let order = filteredOrders.value.find(order => order.crm_order_id === orderId);
        let products = order.products;
        // calculate summary product quantity to shop
        products.forEach(product => {
          let productToShip = productsToShip.value.find(item => item.product_id === product.product_id);
          if (productToShip) {
            productToShip.quantity += product.quantity;
          } else {
            productsToShip.value.push({
              product_id: product.product_id,
              article: product.product.article,
              product_name: product.product.name,
              quantity: product.quantity,
              image_url: product.product.thumbnail_url
            });
          }
        });
      });

      // Sort products by quantity in descending order
      productsToShip.value.sort((a, b) => b.quantity - a.quantity);

      showPackingListModal.value = true;
      console.log('productsToShip', productsToShip.value);
    }


    const closePackingListModal = (newValue) => {
      showPackingListModal.value = newValue;
      productsToShip.value = [];
    }

    const fillOrderStatuses = () => {

      orderStatuses.value = store.getters['order/getOrderStatuses'];

    }


    /*   const getSmsSentStatus = (item) => {
         console.log('item', item);
         if(item){
             return item?.message_value !== null ? item?.message_value : item?.sms_template_name;
         }
         else {
           return 'Не відправлялося';
         }
       };*/

    const getSmsSentStatus = computed(() => {
      return (item) => {
        if (item) {
          return (item.message_value !== null && item.message_value !== '') ? item.message_value : item.sms_template_name;
        } else {
          return 'Не відправлялося';
        }
      };
    });


    const getPaymentType = (item) => {
      return item.sourceData.MarketplacePaymentType === null ? paymentTypeNames[item.PaymentTypeNameId]?.name : item.sourceData.MarketplacePaymentType;

    }


    const tabsInUse = [
      'new',
      'waitForShip',
      'waitForPay',
      'onWayWIthAfterpay',
      'onWayWIthoutAfterpay',
      'storageCanceled',
      'successful',
      'unSuccessful',
      'waitForStock',
      'returned',
      'onComplec',
      'broken',
      'allOrders',
      'removed',
    ]

    const tabs = ref({
      "new": {
        name: 'new',
        title: 'Нові',
        filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'date',
          'BuyerName',
          'BuyerPhone',
          'callsHistory',
          'products',
          'dontCall',
          'SourceType',
          'SourceName',
          'MarketplaceOrderStatus',
          'total_amount',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'orderComment',
        ]
      },

      "waitForShip": {
        name: 'waitForShip',
        title: 'Очікує відправку',
        filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryPhone',
          'packing_status',
          'BuyerPhone',
          'callsHistory',
          'DeliveryFio',
          'products',
          'SourceType',
          'CheckCreated',
          'CheckActions',
          'last_sms_status',
          'SourceName',
          'date',
          'total_amount',
          'MarketplacePaymentType',
          'MarketplaceOrderStatus',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'DeliveryCity',
          'DeliveryType',
          'TtnNumber',
          'DeliveryStatus',
          'TtnPrintedStatus',
          'ReestrNumber',
          'ReestrPrintedStatus',
          'seatsAmounts',
          'orderComment',
        ]
      },

      "waitForPay": {
        name: 'waitForPay',
        title: 'Очікує оплату',
        filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'BuyerName',
          'BuyerPhone',
          'callsHistory',
          'last_sms_status',
          'SourceType',
          'CheckCreated',
          'CheckActions',
          'SourceName',
          'products',
          'date',
          'total_amount',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'DeliveryType',
          'orderComment',
        ]
      },
      "waitForStock": {
        name: 'waitForStock', title: 'Очікує наявності', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'products',
          'SourceType',
          'SourceName',
          'orderComment',
          'TtnNumber',
          'MarketplaceOrderStatus',
          'date',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',

        ]
      },
      "onWayWIthAfterpay": {
        name: 'onWayWIthAfterpay', title: 'У дорозі з наложкою', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'packing_status',
          'last_sms_status',
          'products',
          'CheckCreated',
          'CheckActions',
          'SourceType',
          'SourceName',
          'MarketplaceOrderStatus',
          'date',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'roboCallStatus',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnNumber',
          'BackTtnStatus',
          'orderComment',
        ]
      },
      "onWayWIthoutAfterpay": {
        name: 'onWayWIthoutAfterpay', title: 'У дорозі без наложки', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'products',
          'SourceType',
          'packing_status',
          'last_sms_status',
          'CheckCreated',
          'ReestrNumber',
          'CheckActions',
          'SourceName',
          'MarketplaceOrderStatus',
          'date',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'roboCallStatus',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnNumber',
          'BackTtnStatus',
          'orderComment',
        ]
      },
      "storageCanceled": {
        name: 'storageCanceled', title: 'Зберігання/відмови', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'BuyerPhone',
          'callsHistory',
          'roboCallStatus',
          'products',
          'SourceType',
          'SourceName',
          'CheckCreated',
          'CheckActions',
          'MarketplaceOrderStatus',
          'CrmOrderStatus',
          'date',
          'total_amount',
          'MarketplacePaymentType',
          'PaymentStatus',
          'DeliveryCity',
          'PostName',
          'SenderName',
          'TtnNumber',
          'DeliveryStatus',
          'TtnPrintedStatus',
          'BackTtnNumber',
          'BackTtnStatus',
          'orderComment',
        ]
      },
      "returned": {
        name: 'returned', title: 'Одержали повернення', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'products',
          'SourceType',
          'SourceName',
          'MarketplaceOrderStatus',
          'date',
          'CheckCreated',
          'CheckActions',
          'total_amount',
          'orderComment',
          'BuyerPhone',
          'callsHistory',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnNumber',
          'BackTtnStatus',
        ]
      },
      "onComplec": {
        name: 'onComplec', title: 'На комплектації', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'products',
          'SourceType',
          'SourceName',
          'MarketplaceOrderStatus',
          'date',
          'total_amount',
          'BuyerPhone',
          'TtnNumber',
          'callsHistory',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'DeliveryCity',
          'DeliveryType',
          'seatsAmounts',
          'orderComment',
        ]
      },
      "successful": {
        name: 'successful', title: 'Виконані', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'BuyerName',
          'DeliveryPhone',
          'products',
          'SourceType',
          'SourceName',
          'date',
          'CheckCreated',
          'CheckActions',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'orderComment',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnNumber',
          'BackTtnStatus',
        ]
      },
      "unSuccessful": {
        name: 'unSuccessful', title: 'Не успішні', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'products',
          'SourceType',
          'BuyerName',
          'SourceName',
          'MarketplaceOrderStatus',
          'date',
          'orderComment',
          'CheckCreated',
          'CheckActions',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'SenderName',
          'DeliveryCity',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnStatus',
        ]
      },
      "broken": {
        name: 'broken', title: 'Брак', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'DeliveryFio',
          'DeliveryPhone',
          'BuyerName',
          'products',
          'SourceType',
          'orderComment',
          'SourceName',
          'CheckCreated',
          'CheckActions',
          'MarketplaceOrderStatus',
          'date',
          'total_amount',
          'BuyerPhone',
          'callsHistory',
          'MarketplacePaymentType',
          'PaymentStatus',
          'PostName',
          'TtnNumber',
          'DeliveryStatus',
          'BackTtnNumber',
          'BackTtnStatus',
        ]
      },
      "allOrders": {
        name: 'allOrders', title: 'Усі замовлення', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'BuyerPhone',
          'callsHistory',
          'dontCall',
          'orderComment',
          'PostName',
          'TtnPrintedStatus',
          'last_sms_status',
          'products',
          'DeliveryStatus',
          'MarketplacePaymentType',
          'PaymentStatus',
          'SenderName',
          'TtnNumber',
          'BackTtnNumber',
          'BackTtnStatus',
          'ReestrNumber',
          'CheckCreated',
          'CheckActions',
          'ReestrPrintedStatus',
          'SourceType',
          'SourceName',
          'DeliveryType',
          'MarketplaceOrderStatus',
          'CrmOrderStatus',
          'total_amount',
          'DeliveryFio',
          'BuyerName',
          'DeliveryPhone',
          'DeliveryCity',
        ]
      },
      "packing": {
        name: 'packing',
        title: 'Пакування',
        filteredHeaders: [
          'checkbox',
          'openOrder',
          'packing_status',
          'SourceType',
          'PostName',
          'TtnNumber',
          'BuyerName',

          'products',
          'PaymentStatus',
          'orderComment',
          'ReestrNumber',
          //  'DeliveryCity',
        ]
      },
      "removed": {
        name: 'removed', title: 'Видалені', filteredHeaders: [
          'checkbox',
          'OrderNumber',
          'BuyerPhone',
          'callsHistory',
          'dontCall',
          'orderComment',
          'PostName',
          'TtnPrintedStatus',
          'last_sms_status',
          'products',
          'DeliveryStatus',
          'MarketplacePaymentType',
          'PaymentStatus',
          'SenderName',
          'TtnNumber',
          'BackTtnNumber',
          'BackTtnStatus',
          'ReestrNumber',
          'ReestrPrintedStatus',
          'CheckCreated',
          'CheckActions',
          'SourceType',
          'SourceName',
          'DeliveryType',
          'MarketplaceOrderStatus',
          'CrmOrderStatus',
          'total_amount',
          'DeliveryFio',
          'BuyerName',
          'DeliveryPhone',
          'DeliveryCity',
        ]
      },
    });

    const headers = ref([

      {
        'text': 'Select',
        'columnId': 'checkbox',
        'columnWidth': 'selectOrderWidth',
        'width': 25,
        'filter': selectAllOrders,
        'type': 'checkbox',
        ev: (isChecked) => toggleSelectAllOrders(isChecked)
      },
      {
        'text': '#',
        'columnId': 'rowId',
        'columnWidth': 'rowNumWidth',
        'width': 100
      },
      {
        'text': 'Заказ №',
        'columnId': 'OrderNumber',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'width': 150,
        'filter': orderNumberSearch,
        'options': '',
        'type': 'text'
      },
      {
        'text': 'Дія',
        'columnId': 'openOrder',
        'columnWidth': 'SourceTypeWidth',
        'width': 80,
        'filter': '',
        'options': '',
        'type': '',
      },
      {
        'text': 'Упаковано',
        'columnId': 'packing_status',
        'columnWidth': 'SourceTypeWidth',
        'width': 80,
        'filter': selectedPackingStatus,
        'options': packingStatuses,
        'type': 'text'
      },
      {
        'text': 'Дата',
        'columnId': 'date',
        'columnWidth': 'dateWidth',
        'width': 100,
        'filter': '',
        'options': ''
      },

      {
        'text': 'Джерело',
        'columnId': 'SourceType',
        'columnWidth': 'SourceTypeWidth',
        'width': 80,
        'filter': selectedSourceTypes,
        'options': sourceTypes
      },

      {
        'text': 'Чек створено',
        'columnId': 'CheckCreated',
        'columnWidth': 'SourceTypeWidth',
        'width': 80,
        'filter': selectedCheckCreatedStatus,
        'options': checkStatuses
      },
      {
        'text': 'Чек статус',
        'columnId': 'CheckActions',
        'columnWidth': 'SourceTypeWidth',
        'width': 80,
        'filter': selectedCheckActions,
        'options': checkActions
      },

      {
        'text': 'Перевізник',
        'columnId': 'PostName',
        'width': 100,
        'columnWidth': 'PostNameWidth',
        'filter': selectedPosts,
        'options': posts
      },
      {
        'text': 'ТТН',
        'columnId': 'TtnNumber',
        'columnWidth': 'TtnNumberWidth',
        'width': 100,
        'filter': ttnSearch,
        'options': ''
      },
      {
        'text': 'Друк ТТН',
        'columnId': 'TtnPrintedStatus',
        'columnWidth': 'TtnPrintedStatusWidth',
        'width': 100,
        'filter': selectedPrintTtnStatus,
        'options': ttnPrintedStatus
      },
      {
        'text': 'Реєстр',
        'columnId': 'ReestrNumber',
        'columnWidth': 'ReestrNumberWidth',
        'width': 100,
        'filter': selectedReestr,
        'options': ReestrsOptions,
      },
      {
        'text': 'Друк Реєстра',
        'columnId': 'ReestrPrintedStatus',
        'columnWidth': 'ReestrPrintedStatusWidth',
        'width': 100,
        'filter': selectedReestrPrintStatus,
        'options': ReestrPrintOptions
      },
      {
        'text': 'Дзвонити',
        'columnId': 'dontCall',
        'columnWidth': 'dontCallWidth',
        'width': 70,
        //'filter': phoneSearch,
      },

      {
        'text': 'Телефон пок.',
        'columnId': 'BuyerPhone',
        'columnWidth': 'DeliveryPhoneWidth',
        'width': 100,
        'filter': phoneSearch,
        'type': 'text'
      },
      {
        'text': 'Виклики',
        'columnId': 'callsHistory',
        'columnWidth': 'CallHistoryWidth',
        'width': 165,
        'filter': '',
        'type': 'text'
      },
      {
        'text': 'Автообзвін',
        'columnId': 'roboCallStatus',
        'columnWidth': 'CallHistoryWidth',
        'width': 100,
        'filter': selectedRobocallState,
        'options': roboCallStatuses,
      },
      {
        'text': 'Покупець',
        'columnId': 'BuyerName',
        'columnWidth': 'DeliveryFioWidth',
        'width': 150,
        'filter': buyerNameSearch,
        'options': ''
      },

      {
        'text': 'Товари',
        'columnId': 'products',
        'columnWidth': 'productsWidth',
        'width': 150,
        'filter': productSearch,
        'options': '',
        'class': 'custom-width'
      },

      {
        'text': 'Сумма',
        'columnId': 'total_amount',
        'columnWidth': 'total_amountWidth',
        'width': 100,
        'filter': '',
        'options': ''
      },

      {
        'text': 'До сплати',
        'columnId': 'PaymentStatus',
        'columnWidth': 'PaymentStatusWidth',
        'width': 100,
        'filter': selectedMarketPlacesPaymentStatuses,
        'options': marketPlacesPaymentStatuses
      },
      {
        'text': 'Тип оплати',
        'columnId': 'MarketplacePaymentType',

        'columnWidth': 'MarketplacePaymentTypeWidth',
        'width': 100,
        'filter': selectedMarketplacePaymentTypes,
        'options': marketplacePaymentTypes
      },
      {
        'text': 'Комментар',
        'columnId': 'orderComment',
        'columnWidth': 'OrderCommentWidth',
        'width': 150,
        'filter': commentSearch,
        'options': '',
      },
      {
        'text': 'Сайт статус',
        'columnId': 'MarketplaceOrderStatus', 'columnWidth': 'MarketplaceOrderStatusWidth',
        'width': 100,
        'filter': selectedMarketplacesStatuses,
        'options': marketPlaceStatuses
      },
      {
        'text': 'Сайт',
        'columnId': 'SourceName',
        'width': 100,
        'columnWidth': 'SourceNameWidth',
        'filter': selectedSources,
        'options': sources
      },


      {
        'text': 'SMS',
        'columnId': 'last_sms_status',
        'columnWidth': 'productsWidth',
        'width': 100,
        'filter': selectedSmsStatus,
        'options': smsStatuses
      },

      {
        'text': 'Статус доставки',
        'columnId': 'DeliveryStatus',
        'columnWidth': 'DeliveryStatusWidth',
        'width': 100,
        'filter': selectedDeliveryStatuses,
        'options': deliveryStatuses
      },

      {
        'text': 'Відправник',
        'columnId': 'SenderName',
        'columnWidth': 'SenderNameWidth',
        'width': 100,
        'filter': selectedSenders,
        'options': senders
      },

      {
        'text': 'ТТН зворот. дост.',
        'columnId': 'BackTtnNumber',
        'columnWidth': 'BackTtnNumberWidth',
        'width': 100,
        'filter': '',
        'options': ''
      },
      {
        'text': 'Статус зворот. дост',
        'columnId': 'BackTtnStatus',
        'columnWidth': 'BackTtnStatusWidth',
        'width': 100,
        'filter': selectedBackTtnStatuses,
        'options': backTtnStatuses
      },


      {
        'text': 'DeliveryType',
        'columnId': 'DeliveryType',
        'columnWidth': 'DeliveryTypeWidth',
        'width': 100,
        'filter': selectedDeliveryType,
        'options': deliveryTypes
      },


      {
        'text': 'CRM статус',
        'columnId': 'CrmOrderStatus',
        'columnWidth': 'CrmOrderStatusWidth',
        'width': 100,
        'filter': selectedCrmStatuses,
        'options': crmStatuses
      },


      /* {text: 'Перед-та', columnId: 'prePaidAmount', filter: '', options: ''},*/

      /*   {text: 'Тип оплати', columnId: 'PaymentTypeName', filter: '', options: '' },*/

      {
        'text': 'Одержувач',
        'columnId': 'DeliveryFio',
        'columnWidth': 'DeliveryFioWidth',
        'width': 150,
        'filter': deliveryFioSearch,
        'options': ''
      },

      //deliveryFioSearch


      {
        'text': 'Телефон одер',
        'columnId': 'DeliveryPhone',
        'filter': DeliveryPhoneSearch,
        'columnWidth': 'DeliveryPhoneWidth',
        'width': 100,
        'type': 'text'
      },


      {
        'text': 'Місто',
        'columnId': 'DeliveryCity',
        'columnWidth': 'DeliveryCityWidth',
        'width': 100,
        'filter': selectedCities,
        'options': cities
      },

      {
        'text': 'Місць',
        'columnId': 'seatsAmounts',
        'columnWidth': 'seatsAmountsWidth',
        'width': 100,
        'filter': '',
        'options': ''
      },
    ]);

    // const filteredTabs = ref()

    // switch tabs id to tabs name:
    /*
    packing
    new
    waitForShip
    waitForPay
    onWayWIthAfterpay
    onWayWIthoutAfterpay
    storageCanceled
    successful
    unSuccessful
    waitForStock
    returned
    onComplec
    broken
    allOrders
    * */


    function switchTabsName(tabid) {
      // console.log('switchtabs', tabid);
      switch (tabid) {
        case 0:
          return 'new'

        case 1:
          return 'waitForShip'

        case 2:
          return 'waitForPay'

        case 3:
          return 'onWayWIthAfterpay'

        case 4:
          return 'onWayWIthoutAfterpay'

        case 5:
          return 'storageCanceled'

        case 6:
          return 'successful'

        case 7:
          return 'unSuccessful'

        case 8:
          return 'waitForStock'

        case 9:
          return 'returned'

        case 10:
          return 'onComplec'

        case 11:
          return 'broken'

        case 12:
          return 'allOrders'
        case 13:
          return 'removed'

        case 14:
          return 'packing'

      }
    }

    function updateCommonHeaders(newVal = 1) {
      let tabKey = switchTabsName(newVal);
      commonHeaders.value =
          headers.value.filter(header => tabs.value[tabKey]?.filteredHeaders.includes(header.columnId));
    }

    const waitForShipButton = ref(false);
    const parselInRoadOrCanceledOrderStatusButtons = ref(false);

    /*
     'checkbox',
      'OrderNumber',
      'DeliveryFio',
      'DeliveryPhone',
      'products',
      'SourceType',
      'SourceName',
      'MarketplaceOrderStatus',
      'CrmOrderStatus',
      'date',
      'total_amount',
      'BuyerPhone',
      'MarketplacePaymentType',
      'PaymentStatus',
      'PostName',
      'SenderName',
      'DeliveryCity',
      'DeliveryType',
      'TtnNumber',
      'DeliveryStatus',
      'TtnPrintedStatus',
      'BackTtnNumber',
      'BackTtnStatus',
      'ReestrNumber',
      'ReestrPrintedStatus',
      'seatsAmounts',
      */

    const prepareProducts = (orderedProducts) => {
      console.log('orderedProducts called');
      return orderedProducts.map(product => `${product.product.name} - ${product.quantity} шт. `).join(' \n');
      /*let da = orderedProducts.map(product => {
        let orderdProductsLength = orderedProducts.length;

        return `${product.product.name} - ${product.quantity} шт. `
      });
      return da;*/
    }

    const filteredOrders = computed(() => {

      if (selectedSources.value.length === 0
          && selectedMarketplacesStatuses.value.length === 0
          && selectedSmsStatus.value.length === 0
          && selectedCrmStatuses.value.length === 0
          && !buyerNameSearch.value
          && !commentSearch.value
          && !deliveryFioSearch.value
          && !orderNumberSearch.value
          && !phoneSearch.value
          && !DeliveryPhoneSearch.value
          && !productSearch.value
          && selectedMarketplacePaymentTypes.value.length === 0
          && selectedPackingStatus.value.length === 0
          && selectedMarketPlacesPaymentStatuses.value.length === 0
          && selectedPosts.value.length === 0
          && selectedReestr.value.length === 0
          && selectedCities.value.length === 0
          && selectedPrintTtnStatus.value.length === 0
          && selectedRobocallState.value.length === 0
          && selectedReestrPrintStatus.value.length === 0
          && selectedSenders.value.length === 0
          && selectedDeliveryType.value.length === 0
          && !ttnSearch.value
          && selectedSourceTypes.value.length === 0
          && selectedCheckCreatedStatus.value.length === 0
          && selectedCheckActions.value.length === 0
          && selectedDeliveryStatuses.value.length === 0
          && selectedBackTtnStatuses.value.length === 0
      ) {
        return orders.value;
      }
      console.log('no filtered orders');


      return orders.value.filter(order => {
        // console.log('order', order);
        const sourceName = order.sourceData?.SourceName;
        const marketplaceStatus = order.sourceData?.MarketplaceOrderStatus;
        const smsStatus = order.last_sms_status;
        const marketplacePaymentType = order.sourceData?.MarketplacePaymentType !== null ? order.sourceData?.MarketplacePaymentType : order.PaymentTypeName;
        const marketplacePaymentStatus = order.sourceData?.PaymentStatus;
        const orderNumber = order.sourceData?.OrderNumber;
        const crmStatus = order.CrmOrderStatus;
        const packingStatus = order.packing_status ?? '';
        const sourceType = order.sourceData?.source_type;
        const postName = order.deliveryData?.PostName;
        const reestrName = order.deliveryData?.ReestrNumber;
        const deliveryType = order.deliveryData?.DeliveryType;
        const deliveryCity = order.deliveryData?.DeliveryCity;
        const isTtnPrinted = order.deliveryData?.TtnPrintedStatus;
        const robocalState = roboCallStats?.value[order.crm_order_id]?.roboCallStatus;
        const isReestrPrinted = order.deliveryData?.ReestrPrintedStatus;
        const senderName = order.deliveryData?.SenderName;
        const deliveryStatus = order.deliveryData?.DeliveryStatus;
        const backDeliveryStatus = order.deliveryData?.BackTtnStatus;
        const CheckCreated = order.check_data?.CheckCreated;
        const CheckActions = order.check_data?.CheckActions;

        let productsMatches = true;
        if (productSearch.value) {
          if (order.products && Array.isArray(order.products)) {
            productsMatches = order.products.some(product =>
                product.product.name.toLowerCase().includes(productSearch.value.toLowerCase())
            );
          } else {
            productsMatches = false;
          }
        }

        let sourceTypeMatches = true;
        if (selectedSourceTypes.value.length > 0) {
          sourceTypeMatches = sourceType
              ? selectedSourceTypes.value.map(opt => opt.value).includes(sourceType.toLowerCase())
              : false;
        }


        let checkCreatedMatches = true;
        if (selectedCheckCreatedStatus.value.length > 0) {
          checkCreatedMatches = CheckCreated
              ? selectedCheckCreatedStatus.value.map(opt => opt.value).includes(CheckCreated)
              : false;
        }

        //selectedCheckActions

        let checkActionsMatches = true;
        if (selectedCheckActions.value.length > 0) {
          checkActionsMatches = CheckActions
              ? selectedCheckActions.value.map(opt => opt.value).includes(CheckActions)
              : false;
        }

        let packingStatusMatches = true;
        if (selectedPackingStatus.value.length > 0) {
          packingStatusMatches = packingStatus
              ? selectedPackingStatus.value.map(opt => opt.value).includes(packingStatus)
              : false;
        }


        let sourceNameMatches = true;
        if (selectedSources.value.length > 0) {
          sourceNameMatches = sourceName
              ? selectedSources.value.map(opt => opt.value).includes(sourceName)
              : false;
        }

        let orderNumberMatches = true;
        if (orderNumberSearch.value) {
          orderNumberMatches = orderNumber
              ? String(orderNumber).includes(String(orderNumberSearch.value))
              : false;
        }

        let marketplaceStatusMatches = true;
        if (selectedMarketplacesStatuses.value.length > 0) {
          marketplaceStatusMatches = marketplaceStatus
              ? selectedMarketplacesStatuses.value.map(opt => opt.value).includes(marketplaceStatus)
              : false;
        }

        // selectedSmsStatus

        let smsMatches = true;
        if (selectedSmsStatus.value.length > 0) {
          smsMatches = smsStatus
              ? selectedSmsStatus.value.map(opt => opt.value).includes(smsStatus)
              : false;
        }

        let marketplacePaymentTypeMatches = true;
        if (selectedMarketplacePaymentTypes.value.length > 0) {
          marketplacePaymentTypeMatches = marketplacePaymentType
              ? selectedMarketplacePaymentTypes.value.map(opt => opt.value).includes(marketplacePaymentType)
              : false;
        }


        let crmStatusMatches = true;
        if (selectedCrmStatuses.value.length > 0) {
          //  console.log('crm status', crmStatus);
          crmStatusMatches = crmStatus
              ? selectedCrmStatuses.value.map(opt => opt.value).includes(crmStatus)
              : false;
        }

        let DeliveryPhoneMatches = true;
        // DeliveryPhone search matches
        if (DeliveryPhoneSearch.value) {
          let cleanedPhoneSearch = String(DeliveryPhoneSearch.value).replace(/^0+/, ''); // Convert to string and remove leading zeros
          DeliveryPhoneMatches = order.deliveryData.DeliveryPhone
              ? String(order.deliveryData.DeliveryPhone).includes(cleanedPhoneSearch)
              : false;
        }

        let phoneMatches = true;
        if (phoneSearch.value) {
          let cleanedPhoneSearch = String(phoneSearch.value).replace(/^0+/, ''); // Convert to string and remove leading zeros
          phoneMatches = order.BuyerPhone
              ? String(order.BuyerPhone).includes(cleanedPhoneSearch)
              : false;
        }

        let marketPlacesPaymentStatusMatches = true;
        if (selectedMarketPlacesPaymentStatuses.value.length > 0) {
          marketPlacesPaymentStatusMatches = marketplacePaymentStatus
              ? selectedMarketPlacesPaymentStatuses.value.map(opt => opt.value).includes(marketplacePaymentStatus)
              : false;
        }

        let deliveryCityMatches = true;
        if (selectedCities.value.length > 0) {
          deliveryCityMatches = deliveryCity ? selectedCities.value.map(opt => opt.value).includes(deliveryCity)
              : false;
        }

        let ttnPrintedMatches = true;
        if (selectedPrintTtnStatus.value.length > 0) {
          ttnPrintedMatches = isTtnPrinted
              ? selectedPrintTtnStatus.value.map(opt => opt.value).includes(isTtnPrinted)
              : false;
        }

        let selectedRobocallStateMatches = true;
        if (selectedRobocallState.value.length > 0) {
          selectedRobocallStateMatches = robocalState
              ? selectedRobocallState.value.map(opt => opt.value).includes(robocalState)
              : false;
        }

        let reestrPrintedMatches = true;
        if (selectedReestrPrintStatus.value.length > 0) {
          reestrPrintedMatches = isReestrPrinted
              ? selectedReestrPrintStatus.value.map(opt => opt.value).includes(isReestrPrinted)
              : false;
        }

        let senderNameMatches = true;
        if (selectedSenders.value.length > 0) {
          senderNameMatches = senderName
              ? selectedSenders.value.map(opt => opt.value).includes(senderName)
              : false;
        }

        let postNameMatches = true;
        if (selectedPosts.value.length > 0) {
          postNameMatches = postName
              ? selectedPosts.value.map(opt => opt.value).includes(postName)
              : false;
        }



        let reestrMatches = true;
        if (selectedReestr.value.length > 0) {
          reestrMatches = reestrName
              ? selectedReestr.value.map(opt => opt.value).includes(reestrName)
              : false;
        }

        let deliveryTypeMatches = true;
        if (selectedDeliveryType.value.length > 0) {
          deliveryTypeMatches = deliveryType
              ? selectedDeliveryType.value.map(opt => opt.value).includes(deliveryType)
              : false;
        }

        let deliveryStatusesMatches = true;
        if (selectedDeliveryStatuses.value.length > 0) {
          deliveryStatusesMatches = deliveryStatus
              ? selectedDeliveryStatuses.value.map(opt => opt.value).includes(deliveryStatus)
              : false;
        }


        let beckDeliveryStatusesMatches = true;
        if (selectedBackTtnStatuses.value.length > 0) {
          beckDeliveryStatusesMatches = backDeliveryStatus
              ? selectedBackTtnStatuses.value.map(opt => opt.value).includes(backDeliveryStatus)
              : false;
        }

        let orderNumberMutches = true;
        if (orderNumberSearch.value) {
          orderNumberMutches = String(order.sourceData.OrderNumber).includes(String(orderNumberSearch.value));
        }

        let buyerMatches = true;
        if (buyerNameSearch.value) {
          buyerMatches = order.BuyerName
              ? order.BuyerName.toLowerCase().includes(buyerNameSearch.value.toLowerCase())
              : false;
        }

        // commentSearch

        let commentMatches = true;
        if (commentSearch.value) {
          commentMatches = order.orderComment
              ? order.orderComment.toLowerCase().includes(commentSearch.value.toLowerCase())
              : false;
        }

        let namesMatches = true;
        if (deliveryFioSearch.value) {
          namesMatches = order.deliveryData.DeliveryFio
              ? order.deliveryData.DeliveryFio.toLowerCase().includes(deliveryFioSearch.value.toLowerCase())
              : false;
        }


        let ttnMatches = true;
        if (ttnSearch.value) {
          console.log('ttnSearch.value', ttnSearch.value)
          console.log('order.deliveryData.TtnNumber', order.deliveryData.TtnNumber)
          ttnMatches = order.deliveryData.TtnNumber
              ? String(order.deliveryData.TtnNumber).toLowerCase().includes(String(ttnSearch.value).toLowerCase())
              : false;
        }

        return sourceNameMatches && packingStatusMatches && buyerMatches && commentMatches && productsMatches && DeliveryPhoneMatches && orderNumberMatches && marketplaceStatusMatches && crmStatusMatches && namesMatches && phoneMatches
            && marketplacePaymentTypeMatches && marketPlacesPaymentStatusMatches && postNameMatches && reestrMatches && deliveryCityMatches && smsMatches
            && ttnPrintedMatches && selectedRobocallStateMatches && checkActionsMatches && reestrPrintedMatches && senderNameMatches && sourceTypeMatches && checkCreatedMatches && deliveryTypeMatches && ttnMatches && deliveryStatusesMatches && orderNumberMutches && beckDeliveryStatusesMatches;
      });
    });


    const npSenders = ref([]);
    npSenders.value = JSON.parse(localStorage.getItem('npSenders'));

    // let senderSettings = null;

    const senderSettings = ref(null);


    async function getSenderSettings() {
      try {
        const response = await store.dispatch('order/senderSettings');
        // console.log('get sender settings response', response);
        if (response) {
          senderSettings.value = JSON.parse(localStorage.getItem('senderSettings'));
          userData.value = store.getters['order/getUsersData'];
          runPusherListener();
        }

      } catch (error) {
        console.error(error);
      }
    }

    getSenderSettings();

    getCustomSources();

    const customSources = ref([]);

    async function getCustomSources() {
      await store.dispatch('order/getCustomSources');
    }


    /*    const settingsModalIsVisible = ref(false);

        const ChangeStateSettingsModal = () => {
          settingsModalIsVisible.value = !settingsModalIsVisible.value;
        };
        settingsModalIsVisible.value = npSenders.value.length === 0;*/


    const orderStatus = Array.isArray(route.query.orderstatus) ? route.query.orderstatus[0] : route.query.orderstatus;

    if (orderStatus !== undefined && orderStatus !== activeTab.value.toString()) {
      activeTab.value = parseInt(orderStatus, 10) || 0;
    }


    function updateTtnNumber(ordersDataFromAPI) {
      store.commit('order/setTtnNumbersByFullInfoId', ordersDataFromAPI.data);
      // console.log('createttn not empty');
      createTtnButtonLabel.value = 'Створити ТТН';
      spinerActive.value.createTtn = false;
    }

    function updateCheckData(ordersDataFromAPI) {
      store.commit('order/updateCheckData', {ordersDataFromAPI: ordersDataFromAPI, activeTab: activeTab.value});
      spinerActive.value.createCheck = false;
    }


    //   let getOrderStatusesCount = async () => {
    async function getOrderStatusesCount() {
      try {
        const response = await store.dispatch('order/getOrderStatusesCount');

        //  console.log('getOrderStatusesCount response', response);
        return response[activeTab.value + 1];

      } catch (error) {
        console.error(error);
      }
    }

    let isPusherListenerInitialized = false;

    let connectionLost = false;

    const runPusherListener = () => {
      // console.log('if senderSettings.value !== null');
      // console.log('channel', `return-ttns-channel.${senderSettings.value['user_id']}.2`)

      if (isPusherListenerInitialized) return;
      isPusherListenerInitialized = true;

      const channelName = `return-ttns-channel.${senderSettings.value['user_id']}.2`;

      if (Echo.connector.channels[channelName]) {
        Echo.leave(channelName);
      }

      /* Echo.private(`return-ttns-channel.${senderSettings.value['user_id']}.2`)
           .listen('TrackingNumberCreated', (ordersDataFromAPI) => {*/
      Echo.private(channelName)
          .listen('TrackingNumberCreated', (ordersDataFromAPI) => {
            console.log('Event Received:', ordersDataFromAPI);
            if (ordersDataFromAPI.type === 'ttnCreated') {
              updateTtnNumber(ordersDataFromAPI);
            } else if (ordersDataFromAPI.type === 'ttnUpdated') {
              spinerActive.value.createTtn = false;
              store.dispatch('alert/triggerAlert', {
                show: 3000,
                variant: ordersDataFromAPI.data.status,
                message: ordersDataFromAPI.data.message
              }, {root: true});
            } else if (ordersDataFromAPI.type === 'reestrCreated') {
              console.log('reestrCreated');
              store.commit('order/setReestrNumbersByTtnNumber', ordersDataFromAPI.data);
              // createReestrButtonLabel.value = 'Створити реєстр';
              spinerActive.value.createReestr = false;
              reestrButton.value = 'Реєстр';

            } else if (ordersDataFromAPI.type === 'alert') {

              console.log('alert joined', ordersDataFromAPI);

              store.dispatch('alert/triggerAlert', {
                show: 3000,
                variant: ordersDataFromAPI.data.status,
                message: ordersDataFromAPI.data.message
              }, {root: true});
            } else if (ordersDataFromAPI.type === 'smsSent') {
              if (ordersDataFromAPI.data.withErrors) {
                store.commit('order/setSmsSentStatus', ordersDataFromAPI.data.withErrors)
              }
              if (ordersDataFromAPI.data.forDelete) {
                store.commit('order/deleteOrder', {
                  crmOrderId: ordersDataFromAPI.data.forDelete,
                  orderStatusId: activeTab.value
                });
                store.dispatch('alert/triggerAlert', {
                  show: 3000,
                  variant: 'success',
                  message: 'Замовлення з післяплатою перемістились у статус "У дорозі з наложкою", ' +
                      'а ішні перемістились у статус "У дорозі без наложки"'
                }, {root: true});
              }
              selectedOrders.value = [];
              isAllRowsSelected.value = false
              spinerActive.value.sendBulkSms = false;
            } else if (ordersDataFromAPI.type === 'changeOrderStatusesOnFront') {

              /* const crmOrderId = ordersDataFromAPI.data.ordersIds;
               const orderStatusId = ordersDataFromAPI.data.orderStatusId;
               store.commit('order/deleteOrder', {crmOrderId,  orderStatusId});*/


              /*
              {
                    "type": "changeOrderStatusesOnFront",
                    "data": {
                        "1": [
                            805874489
                        ]
                    }
              }
           */

              Object.keys(ordersDataFromAPI.data).forEach((statusId) => {
                console.log('orderStatus', statusId);
                console.log('orderids', ordersDataFromAPI.data[statusId]);
                let orderStatusId = parseInt(statusId, 10) - 1;
                let crmOrderId = ordersDataFromAPI.data[statusId];
                store.commit('order/deleteOrder', {crmOrderId, orderStatusId});
              });


              /*   store.dispatch('alert/triggerAlert', {
                   show: 3000,
                   variant: 'success',
                   message: 'Статуси замовлень були змінені'
                 }, {root: true});*/
            } else if (ordersDataFromAPI.type === 'new_orders') {


              /*  store.dispatch('alert/triggerAlert', {
                  show: 3000,
                  variant: 'success',
                  message: 'Завантажено нові замовлення'
                }, {root: true});*/

              Object.keys(ordersDataFromAPI.data).forEach((key) => {

                if (ordersDataFromAPI.data.length > 0) {
                  ordersDataFromAPI.data.forEach((order) => {
                    //    console.log('ordersDataFromAPI in loop', order);
                    store.commit('order/updateOrderById', order);
                  });
                  /*            store.dispatch('alert/triggerAlert', {
                                show: 3000,
                                variant: 'success',
                                message: 'Завантажено нові замовлення'
                              }, {root: true});*/

                }
              });

              // console.log('new_orders');
              /*  store.commit('order/deleteTtnNumber', ordersDataFromAPI.data);*/

            } else if (ordersDataFromAPI.type === 'orderStatusesCount') {

              /*  {
                  "7": 1,
                    "4": 1
                }*/

              //  console.log('ordersDataFromAPI.data', ordersDataFromAPI.data)

              setOrderStatusesCount(ordersDataFromAPI.data);
            }
            /*else if (ordersDataFromAPI.type === 'callsHistory') { // незрозуміло чому невикористано

            }*/
          })
          .error((error) => {
            console.error('Error:', error);
          });
    }

    function setOrderStatusesCount(data) {
      Object.keys(data).forEach((statusId) => {
        store.commit('order/setOrdersStatusesCount', {ordersStatusId: statusId, ordersCount: data[statusId]});
      });
    }

// Скидання прапорця при втраті з'єднання
    const resetPusherListener = () => {
      console.log('З\'єднання втрачено. Скидаємо обробник...');
      isPusherListenerInitialized = false;

    };

// Відстеження змін стану з'єднання
    window.Echo.connector.pusher.connection.bind('state_change', (states) => {
      //console.log(`Стан змінився: ${states.previous} → ${states.current}`);
     // console.log('connectionLost', connectionLost)
      if (states.previous === 'disconnected' || states.current === 'unavailable') {
        resetPusherListener();
        connectionLost = true
      //  console.log('connectionLost must be true!', connectionLost)
      }
      if (states.current === 'connected') {
        if (connectionLost === true) {
       //   console.log('fetch orders');
          fetchOrders(activeTab.value)
          connectionLost = false;
          console.log('connectionLost must be false!', connectionLost)
        }
      }
    });

// Спроба перепідключення при зміні видимості вкладки
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        console.log('Вкладка стала активною');
        if (window.Echo.connector.pusher.connection.state !== 'connected') {
          console.log('Перепідключення...');
          window.Echo.connector.pusher.connect();
          runPusherListener();
          fetchOrders(activeTab.value)
        }
      }
    });

    /*
        window.addEventListener('focus', () => {
          console.log('Користувач активний у браузері.');
        });
    */


    /*  function setDynamicWidth() {
        // Use reduce to sum up all the widths
        let width = commonHeaders.value.reduce((sum, header) => {
            // Extract the numeric value from the width string (e.g. '100px' -> 100)
            return sum + header.width ;
          }, 0);

        console.log('headers count', commonHeaders.value.length);
        return width + commonHeaders.value.length * 5;

      }*/

    const totalInnerWrapperWidth = computed(() => {
      let width = commonHeaders.value.reduce((sum, header) => {
        // Extract the numeric value from the width string (e.g. '100px' -> 100)
        return sum + header.width;
      }, 0);

      //  console.log('headers count', commonHeaders.value.length);
      return width + commonHeaders.value.length * 24; // 14px - padding
    });

// Call the function to set the width when necessary

    const roboCallStats = ref({});

    watch(activeTab, (newVal, oldVal) => {
      waitForShipButton.value = newVal === 1;
      parselInRoadOrCanceledOrderStatusButtons.value = newVal === 3 || newVal === 4 || newVal === 5;

      //  console.log('newVal', newVal);
      if (newVal !== oldVal) {
        if (route.query.orderstatus !== newVal.toString()) {
          router.push({query: {...route.query, orderstatus: newVal}});
          selectedOrders.value = [];

        }

        updateCommonHeaders(newVal);

        if (newVal !== 1) {
          packingMode.value = false;
        }

        selectedMarketplacesStatuses.value = [];
        selectedCrmStatuses.value = [];
        buyerNameSearch.value = '';
        commentSearch.value = '';
        deliveryFioSearch.value = '';
        orderNumberSearch.value = '';
        phoneSearch.value = '';
        DeliveryPhoneSearch.value = '';
        productSearch.value = '';
        selectedMarketplacePaymentTypes.value = [];
        selectedPackingStatus.value = [];
        selectedMarketPlacesPaymentStatuses.value = [];
        selectedPosts.value = [];
        selectedSmsStatus.value = [];
        selectedReestr.value = [];
        selectedCities.value = [];
        selectedSenders.value = [];
        selectedDeliveryType.value = [];
        ttnSearch.value = '';
        selectedSourceTypes.value = [];
        selectedCheckCreatedStatus.value = [];
        selectedCheckActions.value = [];
        selectedDeliveryStatuses.value = [];
        selectedBackTtnStatuses.value = [];
        selectedReestrPrintStatus.value = [];
        selectAllOrders.value = false;
        selectedRobocallState.value = [];
        selectedSources.value = [];
        selectedPrintTtnStatus.value = [];

        fetchOrders(newVal)
        // console.log('get orders with status id - ', newVal);
      }

    }, {immediate: true});


    watch(packingMode, (newVal, oldVal) => {
      // console.log('packingMode enabled', newVal);
      if (newVal !== oldVal && newVal) {
        updateCommonHeaders(14);
      } else {
        updateCommonHeaders(activeTab.value)
      }
    });


    updateCommonHeaders(activeTab.value);

    /*    function handleKeyDown(event) {
          if (event.key === 'Shift') {
            isShiftKeyPressed.value = true;
          }
          if (event.key === 'Control' || event.key === 'Meta') {
            isCtrlKeyPressed.value = true;
          }
          if (isCtrlKeyPressed.value && event.key === 'a') {
            toggleSelectAllOrders(!isAllRowsSelected.value);
            //  console.log('call togle all orders', !isAllRowsSelected.value)

            event.preventDefault();  // Prevent default Ctrl + A behavior
          }
        }*/

    function handleKeyDown(event) {
      const target = event.target;
      const tagName = target.tagName.toLowerCase();
      const isInputField = tagName === 'input' || tagName === 'textarea' || target.isContentEditable;

      if (isInputField) {
        // Якщо фокус на полі вводу або текстовій області, не виконуємо кастомну логіку
        return;
      }

      if (event.key === 'Shift') {
        isShiftKeyPressed.value = true;
      }
      if (event.key === 'Control' || event.key === 'Meta') {
        isCtrlKeyPressed.value = true;
      }
      if (isCtrlKeyPressed.value && event.code === 'KeyA') {
        toggleSelectAllOrders(!isAllRowsSelected.value);
        event.preventDefault(); // Запобігаємо стандартній поведінці Ctrl + A
      }
    }


    function handleKeyUp(event) {
      if (event.key === 'Shift') isShiftKeyPressed.value = false;
      if (event.key === 'Control' || event.key === 'Meta') isCtrlKeyPressed.value = false;
    }

    /*    function handleRowClick(event, orderId) {
          // TODO розкоментувати
           event.preventDefault();

          //console.log('handleRowClick', orderId);
          if (lastClickedRowIndex.value === null) {
            lastClickedRowIndex.value = orderId;
          }

          const currentOrderIndex = filteredOrders.value.findIndex(order => order.crm_order_id === orderId);

          if (isCtrlKeyPressed.value) {
            const orderIndex = selectedOrders.value.indexOf(orderId);
            if (orderIndex >= 0) {
              selectedOrders.value.splice(orderIndex, 1); // Remove from selection
            } else {
              selectedOrders.value.push(orderId); // Add to selection
            }
          } else if (isShiftKeyPressed.value) {
            if (lastClickedRowIndex.value === null) {
              lastClickedRowIndex.value = orderId;
              return; // No further action needed if no previous click with Shift key
            }

            const lastClickedOrderIndex = filteredOrders.value.findIndex(order => order.crm_order_id === lastClickedRowIndex.value);
            const start = Math.min(currentOrderIndex, lastClickedOrderIndex);
            const end = Math.max(currentOrderIndex, lastClickedOrderIndex);
            let selectionCount = 0;

            for (let i = start; i <= end; i++) {
              const id = filteredOrders.value[i].crm_order_id;
              const indexInSelected = selectedOrders.value.indexOf(id);
              if (indexInSelected === -1) {
                selectedOrders.value.push(id); // Add new selections
                selectionCount++;
              }
            }

            // If more than one item is selected, set lastClickedRowIndex to null
            if (selectionCount > 1) {
              lastClickedRowIndex.value = null;
            } else {
              lastClickedRowIndex.value = orderId;
            }
          }

        }*/

    function handleRowClick(event, orderId) {
      // TODO розкоментувати


      //console.log('handleRowClick', orderId);
      if (lastClickedRowIndex.value === null) {
        lastClickedRowIndex.value = orderId;
      }


      if (isCtrlKeyPressed.value) {
        event.preventDefault();
        const orderIndex = selectedOrders.value.indexOf(orderId);
        if (orderIndex >= 0) {
          selectedOrders.value.splice(orderIndex, 1); // Remove from selection
        } else {
          selectedOrders.value.push(orderId); // Add to selection
        }
      } else if (isShiftKeyPressed.value) {
        event.preventDefault();
        if (lastClickedRowIndex.value === null) {
          lastClickedRowIndex.value = orderId;
          return; // No further action needed if no previous click with Shift key
        }
        const currentOrderIndex = filteredOrders.value.findIndex(order => order.crm_order_id === orderId);
        const lastClickedOrderIndex = filteredOrders.value.findIndex(order => order.crm_order_id === lastClickedRowIndex.value);
        const start = Math.min(currentOrderIndex, lastClickedOrderIndex);
        const end = Math.max(currentOrderIndex, lastClickedOrderIndex);
        let selectionCount = 0;

        for (let i = start; i <= end; i++) {
          const id = filteredOrders.value[i].crm_order_id;
          const indexInSelected = selectedOrders.value.indexOf(id);
          if (indexInSelected === -1) {
            selectedOrders.value.push(id); // Add new selections
            selectionCount++;
          }
        }

        // If more than one item is selected, set lastClickedRowIndex to null
        if (selectionCount > 1) {
          lastClickedRowIndex.value = null;
        } else {
          lastClickedRowIndex.value = orderId;
        }
      }

    }

    const isRowSelected = computed(() => {
      return (orderId) => {
        return selectedOrders.value.includes(orderId);
      };
    });

    const isAnyRowSelected = computed(() => {
      return selectedOrders.value.length > 0;
    });

    function toggleSelectAllOrders(isChecked) {
      // console.log('isChecked', isChecked);
      //  console.log('toggleSelectAllOrders')
      if (isChecked) {
        isAllRowsSelected.value = true
        // If the checkbox is checked, select all orders
        selectedOrders.value = filteredOrders.value.map(order => order.crm_order_id);
      } else {
        // If the checkbox is unchecked, clear the selection
        selectedOrders.value = [];
        isAllRowsSelected.value = false
      }

      //  console.log(' selected orders', selectedOrders.value)
      //  console.log('count selected orders', selectedOrders.value.length)


    }


    const handleOpen = (header) => {
      // console.log('header', header);
      // console.log('header.columnId', header.columnId);
      header.options = parseOrdersForOptions(header.columnId);
    };

    const parseOrdersForOptions = (option) => {
      console.log('parseOrdersForOptions', option);
      const valueCounts = {};

      //  console.log('filteredOrders', filteredOrders.value)

      filteredOrders.value.forEach(order => {
        // Cache the properties to avoid repeated lookups

        let orderValue = null;


        if (option === 'MarketplacePaymentType' && order.sourceData?.MarketplacePaymentType === null) {

          // console.log('order.sourceData?', order.sourceData)

          orderValue = order.PaymentTypeName

          //  console.log('orderValue', orderValue);

        } else {
          orderValue =
              order[option]
              || order.sourceData?.[option]
              || order.deliveryData?.[option]
              || order.check_data?.[option]
              || (roboCallStats?.value?.[order.crm_order_id]?.[option]);
        }

        console.log('orderValue', orderValue);

        if (option === 'PaymentStatus' && isFirstCharacterInteger(orderValue)) {
          valueCounts['Наложка'] = (valueCounts[['Наложка']] || 0) + 1;
          // console.log('orderValue1', orderValue);
        } else if (orderValue) {
          // console.log('orderValue2', orderValue);
          // Directly increment the count, initializing with 0 if necessary
          valueCounts[orderValue] = (valueCounts[orderValue] || 0) + 1;
        }
      });


      // Convert valueCounts to options array
      return Object.entries(valueCounts).map(([key, count]) => ({value: key, quantity: count}));
    };

    function isFirstCharacterInteger(str) {

      if (str !== undefined && str !== null && str !== '') {
        return !isNaN(parseInt(str.charAt(0)));
      }

    }

    const userConfirmationStatus = ref(null);
    const modalVisible = ref(false);

    const handleUserConfirmation = (confirmation) => {
      //  console.log('handleUserConfirmation', confirmation);
      userConfirmationStatus.value = confirmation;
      //  console.log('userConfirmationStatus', userConfirmationStatus.value)
    };

    const confirmModalTitle = ref('')
    const confirmModalBody = ref('')
    const confirmButtonValue = ref('')
    const cancelButtonValue = ref('')
    const currentAction = ref(null);


    const ordersWithoutTtns = ref([]);
    const createTtns = async () => {
      // console.log('createTtns')
      let da = chechIfAllOrdersHaveTtn('createTtns');
      //  console.log('da', da)
      if (da.ordersWithTtn.length > 0) {
        /* const ordersWithTtns = ref([]);
         selectedOrders.value.map(crm_order_id => {
           filteredOrders.value.find(order => {
             if (order.crm_order_id === crm_order_id
                 && order.deliveryData.ttnNumber !== null
                 && order.deliveryData.ttnNumber !== 'Не вказано'
                 && order.deliveryData.ttnNumber !== ''
             ) {
               console.log('item.deliveryData', order.deliveryData)
               ordersWithTtns.value.push(order.sourceData.OrderNumber);
             } else {
               ordersWithoutTtns.value.push(crm_order_id);
             }
           })
         })*/


        confirmModalTitle.value = 'Деякі замолвення вже мають ТТН'
        confirmButtonValue.value = 'Створити ТТН для всіх замовлень'
        cancelButtonValue.value = 'Створити ТТН для замовлень без ТТН'
        confirmModalBody.value = 'Створити ТТН повторно для замовлень: ' + da.ordersWithTtn.join(', ') + '?';
        currentAction.value = 'createTtns';
        modalVisible.value = true;
        console.log('modal visible', modalVisible.value)
      } else {
        const selectedOrdersForCreateTtns = {
          'ordersIds': selectedOrders.value,
        };
        await createNewTtns(selectedOrdersForCreateTtns);
      }
    }


    function createCheks(returnOrNot) {
      spinerActive.value.createChecks = true;
      let queryData = {
        'ordersIds': selectedOrders.value,
        'isReturn': returnOrNot
      };

      createCheksQuery(queryData).then((res) => {
        console.log('res', res);

        spinerActive.value.createChecks = false;
        if (res.status === 'success') {
          updateCheckData(res.data);
        }

        // add alert
        store.dispatch('alert/triggerAlert', {
          show: 3000,
          message: res.message,
          variant: res.status
        });

      });
    }


    function printCheks() {
      spinerActive.value.createChecks = true;
      let queryData = {
        'ordersIds': selectedOrders.value,
        'format': 'html'
      };

      printCheksQuery(queryData).then((res) => {
        // open in new tab with html content
        spinerActive.value.createChecks = false;
        store.commit('order/updateCheckPrintStatus', {
          ordersDataFromAPI: res.printedOrders,
          activeTab: activeTab.value
        });
        let newWindow = window.open();
        newWindow.document.write(res.data);
      })
    }


    const callToClient = async (phone) => {
      /*  await makeCall({
          'channel': userData.value.sip_number,
          'destination': phone,
          'callerName': userData.value.name
        });*/

      // is length of phone number is 10 digits add +38

      phone = String(phone);

      console.log('phone length', phone); //  984528434

      console.log('phone length', phone.length); //  undefined


      if (phone.length === 9) {
        phone = '0' + phone;
      }

      await useCallToClient(userData.value.sip_number, phone, '')


    }

    const crmOrderIdsWithPrintedTtn = ref([]);
    const crmOrderIdsWithoutPrintedTtn = ref([]);

    const printType = ref('zebra');

    const printmarkings = async (type) => {

      console.log('type', type);

      printType.value = type;

      let da = chechIfAllOrdersHaveTtn('printmarkings');
      if (da.ordersWithoutTtn.length > 0) {
        showAlert(da.ordersWithoutTtn);
      }
      const orderWithPrintedTtn = ref([]);
      selectedOrders.value.map(order => {
        filteredOrders.value.find(item => {
          if (item.crm_order_id === order && item.deliveryData.TtnPrintedStatus === 'Так') {
            orderWithPrintedTtn.value.push(item.deliveryData.TtnNumber);
            crmOrderIdsWithPrintedTtn.value.push(item.crm_order_id);
          } else {
            crmOrderIdsWithoutPrintedTtn.value.push(item.crm_order_id);
          }
        })
      })

      //  console.log('orderWithPrintedTtn.value', orderWithPrintedTtn.value);
      if (orderWithPrintedTtn.value.length > 0) {
        let lengthIsSimilar = orderWithPrintedTtn.value.length === selectedOrders.value.length;
        confirmModalTitle.value = 'Деякі ТТН вже роздруковані'
        confirmButtonValue.value = lengthIsSimilar ? 'Друкувати повторно' : 'Друкувати усе'
        cancelButtonValue.value = lengthIsSimilar ? '' : 'Друкувати лише не роздруковані'
        confirmModalBody.value = 'Роздрукувати повторно ТТН: ' + orderWithPrintedTtn.value.join(', ') + '?';
        currentAction.value = 'printmarkings';
        modalVisible.value = true;
        console.log('modal visible', modalVisible.value)

        /*     if(userConfirmationStatus === true && selectedOrders.value.length > 0){
               spinerActive.value.printTtn = true;
               const requestChangeStatus = {
                 'ordersIds': selectedOrders.value,
               }
               await sendPrintmarkingsRequest(requestChangeStatus);
               spinerActive.value.printTtn = false;
             }*/
      } else {

        await printMarkingsFunc({
          'ordersIds': selectedOrders.value,
          'type': printType.value
        })
      }
    }
    const crmOrderIdsWithoutTtn = ref([]);

    const printMarkingsFunc = async (orderIds) => {
      console.log('printMarkingsFunc', orderIds)
      spinerActive.value.printTtn = true;
      modalVisible.value = false;
      await sendPrintmarkingsRequest(orderIds);
      spinerActive.value.printTtn = false;
    }

    watch(userConfirmationStatus, async (newStatus) => {
      if (currentAction.value === 'printmarkings') {
        if (newStatus === true && selectedOrders.value.length > 0) {
          const allOrdersWithPrintedTtns = {
            'ordersIds': selectedOrders.value,
            'type': printType.value
          };
          await printMarkingsFunc(allOrdersWithPrintedTtns);
        } else if (newStatus === false) {
          const requestChangeStatus = {
            'ordersIds': crmOrderIdsWithoutPrintedTtn.value,
            'type': printType.value
          };

          //const ordersWithoutPrintedTtns = selectedOrders.value.filter(order => !crmOrderIdsWithPrintedTtn.value.includes(order));
          /*          console.log('ordersWithoutPrintedTtns', crmOrderIdsWithoutPrintedTtn.value);
                    spinerActive.value.printTtn = true;
                    console.log('requestChangeStatus', requestChangeStatus);
                    modalVisible.value = false;
                    spinerActive.value.printTtn = false;*/

          await printMarkingsFunc(requestChangeStatus);
        }
      } else if (currentAction.value === 'createTtns') {
        if (newStatus === true && selectedOrders.value.length > 0) {
          /*  createTtnButtonLabel.value = 'Створюю ТТН..';
            spinerActive.value.createTtn = true;
            const requestChangeStatus = {
              'ordersIds': selectedOrders.value,
            }
            modalVisible.value = false;
            await sendCreateTtnsRequest(requestChangeStatus);
            spinerActive.value.createTtn = false;
            createTtnButtonLabel.value = 'Створити ТТН';*/

          const requestChangeStatus = {
            'ordersIds': selectedOrders.value,
          }

          console.log('requestChangeStatus', requestChangeStatus);

          console.log('clreate ttns in if watch');
          await createNewTtns(requestChangeStatus);

        } else if (newStatus === false) {
          // drop  orders with printed ttns and print only orders without printed ttns
          /*       createTtnButtonLabel.value = 'Створюю ТТН..';
                 spinerActive.value.createTtn = true;
                 const requestChangeStatus = {
                   'ordersIds': ordersWithoutTtns.value,
                 };
                 modalVisible.value = false;
                 await sendCreateTtnsRequest(requestChangeStatus);
                 spinerActive.value.createTtn = false;
                 createTtnButtonLabel.value = 'Створити ТТН';*/
          console.log('clreate ttns in else watch');
          const requestChangeStatus = {
            'ordersIds': crmOrderIdsWithoutTtn.value,
          };
          await createNewTtns(requestChangeStatus);
        }
      }
      userConfirmationStatus.value = null;
    });


    const createNewTtns = async (selectedOrders) => {
      createTtnButtonLabel.value = 'Створюю ТТН..';
      spinerActive.value.createTtn = true;
      modalVisible.value = false;
      modalVisible.value = false;
      // console.log('selectedOrders', selectedOrders);
      await sendCreateTtnsRequest(selectedOrders);
      // console.log('resp', resp);
      crmOrderIdsWithoutTtn.value = [];

    }

    const dropOrders = async () => {
      //  console.log('dropOrders');
      //  console.log('selected orders', selectedOrders.value);
      const requestChangeStatus = {
        'ordersIds': selectedOrders.value,
      }

      let response = await sendDropOrdersRequest(requestChangeStatus, activeTab.value);
      if (response.status === 'success') {
        selectedOrders.value = [];
        selectAllOrders.value = false;

      }
    }


    // types: printmarkings, createTtns, sendBulkSmsWithTtns
    function chechIfAllOrdersHaveTtn(type) {
      const ordersWithoutTtn = [];
      const ordersWithTtn = [];

      console.log('selectedOrders.value', selectedOrders.value);
      selectedOrders.value.map(crm_order_id => {
        const order = filteredOrders.value.find(order => order.crm_order_id === crm_order_id);
        // console.log('selected order', order);
        if (order.deliveryData.TtnNumber === 'Не вказано'
            || order.deliveryData.TtnNumber === null ||
            order.deliveryData.TtnNumber === '') {
          ordersWithoutTtn.push(order.sourceData?.OrderNumber);
          crmOrderIdsWithoutTtn.value.push(crm_order_id);

          if (type !== 'createTtns') {
            selectedOrders.value = selectedOrders.value.filter(orderId => orderId !== crm_order_id);
          }
        } else {
          ordersWithTtn.push(order.sourceData?.OrderNumber);
        }
      });

      return {ordersWithoutTtn, ordersWithTtn, crmOrderIdsWithoutTtn}

      // console.log('ordersWithoutTtn', ordersWithoutTtn);
      /*      if (ordersWithoutTtn.length > 0) {
              showAlert(ordersWithoutTtn);
            }*/
    }

    const sendBulkSmsWithTtns = async () => {
      chechIfAllOrdersHaveTtn('sendBulkSmsWithTtns');
      console.log('selectedOrders.value', selectedOrders.value);
      if (selectedOrders.value.length > 0) {
        spinerActive.value.sendBulkSms = true;
        let da = await sendRequestWithBulkSmsWithTtns({'ordersIds': selectedOrders.value});
        console.log('da', da);
      }


    }

    function showAlert(ordersWithoutTtn) {
      store.dispatch('alert/triggerAlert', {
        show: 5000,
        variant: 'warning',
        message: 'Замовлення ' + ordersWithoutTtn.join(', ') + ' не мають ТТН',
      });
    }


    async function sendRequestWithBulkSmsWithTtns(ordersIds) {
      try {
        return await store.dispatch('order/sendRequestWithBulkSMSWithTTns', ordersIds);


      } catch (error) {
        console.log('помилка відправки запиту на розсилку ТТН у СМС', error);
        throw error;
      }
    }

    async function sendDropOrdersRequest(ordersIds, orderStatusIds) {
      try {
        return await store.dispatch('order/dropOrdersRequest', {orderIds: ordersIds, orderStatusId: orderStatusIds});
      } catch (error) {
        console.log('помилка видалення замовлень', error);
        throw error;
      }
    }

    async function sendCreateTtnsRequest(ordersIds) {
      try {
        return await store.dispatch('order/createTtnsRequest', ordersIds);
      } catch (error) {
        console.log('помилка створення ТТН', error);
        throw error;
      }
    }

    async function sendPrintmarkingsRequest(ordersIds) {
      try {
        return await store.dispatch('order/printmarkingsRequest', ordersIds);
      } catch (error) {
        console.log('помилка друку ТТН', error);
        throw error;
      }
    }

    const changeOrderStatus = async (orderStatus) => {

      const requestChangeStatus = {
        'ordersIds': selectedOrders.value,
        'orderStatusId': orderStatus,
        'activeTab': activeTab.value,
      }
      let response = await sendChangeOrderStatuses(requestChangeStatus, activeTab.value);
      if (response.status === 'success') {
        selectedOrders.value = [];
        selectAllOrders.value = false;
      }
      // console.log('change statuses response', response);
    }

    const removeFromReestr = async () => {
      reestrButton.value = 'Видаляємо з реєстру...';
      let da = chechIfAllOrdersHaveTtn('printmarkings');
      if (da.ordersWithoutTtn.length > 0) {
        showAlert(da.ordersWithoutTtn);
      }
      if (selectedOrders.value.length > 0) {
        spinerActive.value.removeFromReestr = true;
        const requestData = {
          'ordersIds': selectedOrders.value,
        }

        let response = await sendRequestRemoveFromReestr(requestData);
        console.log('remove from reestr response', response);
        reestrButton.value = 'Реєстр';
        if (response.status === 'success') {
          // dispatch alert
          await store.dispatch('alert/triggerAlert', {
            show: 5000,
            variant: response.status,
            message: response.message,
          });
        }


        console.log('remove from reestr response', response);

      }
    }

    const addToReestr = async () => {
      reestrButton.value = 'Додаємо до реєстру...';
      let da = chechIfAllOrdersHaveTtn('printmarkings');
      if (da.ordersWithoutTtn.length > 0) {
        showAlert(da.ordersWithoutTtn);
      }
      if (selectedOrders.value.length > 0) {
        spinerActive.value.createReestr = true;
        const requestChangeStatus = {
          'ordersIds': selectedOrders.value,
        }

        let response = await sendRequestAddToReestr(requestChangeStatus);
        console.log('add to reestr response', response);

      }
    }

    async function sendRequestAddToReestr(requestChangeStatus) {
      try {
        return await store.dispatch('order/addToReestr', requestChangeStatus);
      } catch (error) {
        console.log('помилка додавання до реєстру', error);
        throw error;
      }
    }

    async function sendRequestCreateReturn(requestChangeStatus) {
      try {
        return await store.dispatch('order/createReturn', requestChangeStatus);
      } catch (error) {
        console.log('помилка створення повернення', error);
        throw error;
      }
    }

    const reestrButton = ref('Реєстр')
    const createReturn = async () => {

      const requestChangeStatus = {
        'ordersIds': selectedOrders.value,
      }

      let response = await sendRequestCreateReturn(requestChangeStatus);
      console.log('create return response', response);

    }

    const printReestr = async () => {
      reestrButton.value = 'Друкуємо реєстр...';
      spinerActive.value.createReestr = true;
      let da = chechIfAllOrdersHaveTtn('printmarkings');
      if (da.ordersWithoutTtn.length > 0) {
        showAlert(da.ordersWithoutTtn);
      }
      if (selectedOrders.value.length > 0) {
        try {
          const requestChangeStatus = {
            'ordersIds': selectedOrders.value,
          }

          await sendRequestPrintReestr(requestChangeStatus);
        } catch (error) {
          console.error('Error in printReestr:', error);
          // Handle the error appropriately
        }
      } else {
        spinerActive.value.createReestr = false;
        reestrButton.value = 'Реєстр';
      }
    }

    function getUniqueReestrIds(orders) {
      let uniqueReestrIds = [];
      // console.log('orders.ordersIds', orders.ordersIds);
      orders.ordersIds.map(orderId => {

        /*        filteredOrders.value.find(item => {
                  if (item.crm_order_id === order && item.deliveryData.TtnPrintedStatus === 'Так') {*/

        filteredOrders.value.find(order => {
          if (orderId === order.crm_order_id) {
            uniqueReestrIds.push(order.deliveryData?.ReestrNumber);
          }
        })
      });

      // console.log('uniqueReestrIds', uniqueReestrIds);
      return uniqueReestrIds;
    }

    async function sendRequestPrintReestr(orderIds) {
      try {
        //   spinerActive.value.printReestr = true;
        const token = store.getters['authModule/token'];
        const response = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_LARAVEL_APP_URL}/print-reestrs`,
          data: orderIds,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/pdf'
          },
          responseType: 'blob' // Ensure response is treated as a Blob
        });

        // console.log('print resster response', response);
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], {type: 'application/pdf'});

        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Open the URL on new Window
        window.open(fileURL);
        //    spinerActive.value.printReestr = false;

        store.commit('order/setReestrPrinted', getUniqueReestrIds(orderIds))
      } catch (error) {
        console.error('Error in sendRequestPrintReestr:', error);
      }
      spinerActive.value.createReestr = false;
      reestrButton.value = 'Реєстр';

    }

    async function sendChangeOrderStatuses(requestChangeStatus, activeTab) {
      try {
        return await store.dispatch('order/changeOrderStatuses', {requestChangeStatus, activeTab});
      } catch (error) {
        console.log('помилка зміни статусів', error);
        throw error;
      }
    }

    const dragging = ref(false);

    const showArrows = ref(false);
    const containerRef = ref(null);


    const checkOverflow = () => {
      const container = containerRef.value;
      showArrows.value = container.scrollWidth > container.clientWidth;
    };

    const handleResize = () => {
      checkOverflow();
    };

    const scrollLeft = () => {
      if (containerRef.value) {
        containerRef.value.scrollLeft -= 150; // Adjust this value as needed
      }
    };

    const scrolledRight = ref(false);

    const scrollRight = () => {
      scrolledRight.value = true;
      if (containerRef.value) {
        containerRef.value.scrollLeft += 150; // Adjust this value as needed
      }
    };


    const relatedPhoneNumbersToOrders = ref({});

    //  console.log('relatedPhoneNumbersToOrders', relatedPhoneNumbersToOrders.value);


    const showPopup = ref(false);

    //// select rows start /////////////////
    onMounted(() => {

      // In your main Vue instance or root component
      //store.commit('order/loadLocalOrders');

      localStorage.removeItem('orders');

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      window.addEventListener('resize', handleResize);

      if (userData.value.ip_telephony === true) {
        // make api request get-calls-by-date
        // date from 3 days ago
        fetchCallHistory();
        relatedPhoneNumbersToOrders.value = JSON.parse(localStorage.getItem('phonesRelatedToOrders'));
      }

      /*      requestNotificationPermission().then((token) => {
              if (token) {
                console.log('Notification permission granted.');
              } else {
                console.log('Notification permission denied.');
              }
            });*/

      // TODO розкоментувати це
      onMessageListener((payload) => {
        //  console.log("Foreground notification:", payload);
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: '/img/icons/android-chrome-192x192.png'
        });
      });





      if ('serviceWorker' in navigator && 'PushManager' in window) {
        const pushStatus = localStorage.getItem('pushStatus');
        console.log('pushStatus if serviceWorker:', pushStatus);//  null

        // перенести умову вище над if serviceWorker
        if (pushStatus === null || pushStatus === 'notset') {
          console.log('push status = null') // push status = null

          navigator.serviceWorker.getRegistrations()
              .then(registrations => {
                if (registrations.length > 1) {
                  console.log('Знайдено реєстрації Service Worker:', registrations);
                  registrations.forEach(registration => {
                    console.log('Scope:', registration.scope);
                    const needleScope = `${window.location.origin}/firebase-cloud-messaging-push-scope`;
                    console.log('needleScope', needleScope);
                    console.log('registered scope:', registration.scope);
                    if (registration.scope !== needleScope) {
                      registration.unregister()
                          .then(success => {
                            if (success) {
                              console.log('Service Worker успішно видалено:', registration.scope);
                            } else {
                              console.warn('Не вдалося видалити Service Worker:', registration.scope);
                            }
                          })
                          .catch(error => {
                            console.error('Помилка під час видалення Service Worker:', error);
                          });
                    } else {
                      console.log('Залишаємо реєстрацію:', registration.scope);
                      if (registration.active) {
                        console.log('Service Worker активний:', registration.active);
                        showPopup.value = true; // Встановлюємо popup
                      }
                    }
                  });
                } else if(registrations.length === 1){
                console.log('зареєстровано 1 sw')
                  registrations.forEach(registration => {
                  registration.pushManager.getSubscription()
                      .then(subscription => {
                        if (subscription) {
                          console.log('Вже існує підписка:', subscription);
                          return subscription; // Використовуємо існуючу підписку
                        } else {
                          console.log('підписка відсутня', subscription);
                          showPopup.value = true; // Встановлюємо popup
                        }
                      })
                  })
                }
                else {
                  console.log('Service Worker не зареєстрований.');
                  if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.register('/firebase-messaging-sw.js?ver=1', {
                      scope: '/firebase-cloud-messaging-push-scope'
                    })
                        .then((registration) => {
                          console.log('Service Worker registered with scope:', registration.scope);
                          showPopup.value = true; // Встановлюємо popup
                        })
                        .catch((error) => {
                          console.error('Service Worker registration failed:', error);
                        });
                  }
                }
              })
              .catch(error => {
                console.error('Помилка отримання реєстрацій Service Worker:', error);
              });


        }
      } else {
        console.error('Service Worker або PushManager не підтримується у вашому браузері.');
      }




      // видалити те що нижче це та фунцію sendSubscriptionToServer
      const pushToken = localStorage.getItem('pushToken');
      if (pushToken !== null) {
        Notification.requestPermission().then((permission) => {
          console.log('permission', permission);

          if (permission === 'denied') {

            // delete token from localstorage and backend

            localStorage.removeItem('pushToken');
            localStorage.setItem('pushStatus', 'false');

            const queryData = {
              client_key: pushToken,
              push_status: 'denied',
              push_themes: null
            }

            sendSubscriptionToServer(queryData).then((res) => {
              console.log('remove token from backend', res)
            });
          } else {
            console.log('permission granted');
          }
        });


      }

    });


    async function sendSubscriptionToServer(queryData) {
      return await savePushKey(queryData)
    }

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('resize', handleResize);
      // console.log('senderSettings.value', senderSettings.value);
      //Echo.leaveChannel(`return-ttns-channel.${senderSettings.value['user_id']}.2`);
    });


    /*    function printPackingList(){
          console.log('printPackingList');
          selectedOrders.value.forEach(orderId => {
            let order = filteredOrders.value.find(order => order.crm_order_id === orderId);
            if (order.deliveryData.TtnPrintedStatus === 'Так') {
              let url = `${process.env.VUE_APP_LARAVEL_APP_URL}/print-packing-list/${orderId}`;
              window.open(url, '_blank');
            }
          });
        }*/


    // Replace 'localhost' with your server's IP address or hostname if necessary
    // Also, update the port if your WebSocket server is running on a different one


    /*    function sendMessage() {
          let message = 'Hello, WebSocket!';
          if (conn.readyState === WebSocket.OPEN) {
            conn.send(message);
            console.log("Message sent: " + message);
          } else {
            console.log("Connection is not open.");
          }
        }*/


    function getLength(phone) {
      const key = '0' + phone;
      console.log('Key:', key);
      console.log('Value:', callHistory[key]);
      return callHistory[key]?.length || 0;
    }


    const showCallInfo = computed(() => {
      return store.getters['callInfoModule/getCallInfoState']
    });


    watch(
        () => showCallInfo.value.status,
        (newStatus) => {
          if (newStatus === true) {
            fetchCallHistory().then(() => {
              //  console.log('phoneNumbersOfCallsForLastTwoDays', Object.keys(callHistory.value));
              store.dispatch('order/findExitedOrdersByPhoneNumbers', Object.keys(callHistory.value))
                  .then((callBack) => {
                    relatedPhoneNumbersToOrders.value = callBack;

                    //   console.log('phoneorders', relatedPhoneNumbersToOrders.value);
                  });
            });
          }
        }
    );


    function closeCallInfo() {
      store.commit('callInfoModule/changeModalStatus', {
        status: false,
        type: 'callInfo',
        title: ''
      });
    }


    // const audioSrc = ref('');

// Reference to the audio player element
    const audioPlayer = ref(null);

// Function to fetch the audio file URL and play the audio


    const isLoading = ref(false);
    const error = ref(null);

    /*    const downloadFileWithAuth = async (fileName) => {
          isLoading.value = true;
          error.value = null;

          try {
            // Використовуємо apiCall для завантаження файлу з авторизацією
            const response = await apiCall(
                'POST', // Метод
                '/get-wav-file', // URL
                {file: fileName}, // Без payload
                'blob'
            );

            // Якщо відповідь успішна, створюємо посилання для завантаження файлу
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); // Встановлюємо атрибут download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

          } catch (err) {
            error.value = "Не вдалося завантажити файл.";
            console.error(err);
          } finally {
            isLoading.value = false;
          }
        };*/

    const downloadFileWithAuth = async (fileName) => {
      isLoading.value = true;
      error.value = null;

      try {
        // Використовуємо API-запит для отримання файлу
        const response = await apiCall(
            'POST', // Метод
            '/get-wav-file', // URL
            {file: fileName}, // Payload
            'blob' // Очікуваний тип відповіді
        );

        // Отримуємо назву файлу із заголовка Content-Disposition
        const contentDisposition = response.headers['content-disposition'];

        // let sanitizedFileName = 'downloaded-file.wav'; // Значення за замовчуванням
        let sanitizedFileName = fileName.replace(/\.(?=.*\.)/g, '');

        if (contentDisposition) {
          // Пошук назви файлу у заголовку
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch && fileNameMatch[1]) {
            sanitizedFileName = fileNameMatch[1];
          }
        }

        // Завантажуємо файл із правильною назвою
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', sanitizedFileName); // Використовуємо отриману назву
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        error.value = 'Не вдалося завантажити файл.';
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };


    const playAudio = async (fileName) => {
      try {
        // URL of the audio file to be played
        // Set the audio source URL


        console.log('fileName', fileName);

        const calldate = fileName.originalDate.split(" ")[0]; // "2024-11-19"

// Replace dashes with slashes
        const formattedDate = calldate.replace(/-/g, "/"); // "2024/11/19"

// Concatenate the formatted date with the recording file
        const result = `${formattedDate}/${fileName.recordingfile}`;

        console.log('result', result);

        const audioSrc = 'http://' + userData.value.host + '/recordings/' + result;


        console.log('audioSrc', audioSrc);


        await downloadFileWithAuth(audioSrc);

        // window.open(audioSrc.value, '_blank');
        // Play the audio if the audio player is available
        /*    if (audioPlayer.value) {
              audioPlayer.value.load();  // Reload the audio player with the new source
              audioPlayer.value.play();  // Play the audio
            }*/
      } catch (error) {
        console.error('Error fetching audio file:', error);
      }
    };


    function openOrder(order) {
      //  console.log('openOrder', order);

      // (activeTab.value) || '');

      //  const orders = store.getters['order/getOriginalOrders'];
      // console.log('all orders', orders);

      const findedOrder = store.getters['order/getORderByOrderStatusAndCrmOrderId'](order.CrmOrderStatus.id, order.crm_order_id)
      //   console.log('findedOrder', findedOrder);

      openModal(findedOrder, {'title': 'Редагувати замовлення', 'modalType': 'update'});
      // router.push({name: 'order', params: {id: orderId}});
    }


    function initRoboCall(type) {
      // get phone numbers of selected orders
      spinerActive.value.roboCall = true;
      let orderNumbersForRoboCall = [];

      const phoneNumbers = selectedOrders.value.map(orderId => {
        const order = orders.value.find(order => order.crm_order_id === orderId);
        orderNumbersForRoboCall.push(orderId);
        return {
          'phone': '0' + order.BuyerPhone,
          'orderId': orderId
        }
      });

      // console.log('phoneNumbers', phoneNumbers);

      let stateName = '';
      if (type === 'remindGetParsel1') {
        stateName = 'Повідом про прибуття'
      } else if (type === 'remindGetParsel2') {
        stateName = 'Повідом про поверн через 3 дні'
      }

      const res = roboCallInit({
        'type': type,
        'data': phoneNumbers
      }).then((res) => {
        console.log('res', res);
        spinerActive.value.roboCall = false;
        // triger alert
        store.dispatch('alert/triggerAlert', {
          'variant': res.status,
          'message': res.message,
          'show': 3000
        });

        orderNumbersForRoboCall.forEach(orderId => {
          // TODO тут додати ключ автообзвону для фільтрації
          roboCallStats.value[orderId].roboCallStatus = stateName;
        });

      });


      console.log('res', res);


    }

    const changeDate = ref(false);


    return {
      changeDate,
      tabs,
      showModal,
      activeTab,
      orderNumberSearch,
      sources,
      marketPlaceStatuses,
      smsStatuses,
      checkStatuses,
      checkActions,
      crmStatuses,
      buyerNameSearch,
      commentSearch,
      marketplacePaymentTypes,
      marketPlacesPaymentStatuses,
      posts,

      senders,
      cities,
      DeliveryPhoneSearch,
      selectedSourceTypes,
      selectedCheckCreatedStatus,
      selectedCheckActions,
      sourceTypes,
      prepareProducts,
      productSearch,
      formatDate,
      selectAllOrders,
      deliveryTypes,
      handleRowClick,
      commonHeaders,
      ttnSearch,
      deliveryStatuses,
      selectedSources,
      selectedMarketplacesStatuses,
      selectedCrmStatuses,
      selectedMarketplacePaymentTypes,
      selectedMarketPlacesPaymentStatuses,
      selectedPackingStatus,
      packingStatuses,
      selectedPosts,
      selectedSenders,
      selectedCities,
      selectedPrintTtnStatus,
      selectedRobocallState,
      selectedReestrPrintStatus,
      isAnyRowSelected,
      selectedDeliveryType,
      selectedDeliveryStatuses,
      selectedBackTtnStatuses,
      //    handleCheckboxClick,
      lastClickedRowIndex,
      isShiftKeyPressed,
      isCtrlKeyPressed,
      openChangeOrderStatusesByNumberModal,
      toggleSelectAllOrders,
      selectedOrders,
      headers,
//      shouldShowButton,
      dragging,
//      initializeHeaders,
      filteredOrders,
      handleOpen,
      selectedOrder,
      openModal,
      modalTitle,
      orderStatuses,
      modaslIsVisible,
      createTtnButtonLabel,
      spinerActive,
      fillOrderStatuses,
      // getSelectedOrders,
      changeOrderStatus,
      dropOrders,
      closeModal,
      createTtns,
      createCheks,
      printmarkings,
      addToReestr,
      createReturn,
      printReestr,
      removeFromReestr,
      waitForShipButton,
      //   showSettingsModal,
      isRowSelected,
      hoveredRow,
      sendBulkSmsWithTtns,
      setHoveredRow,
      getPaymentType,
      clearHoveredRow,
      parselInRoadOrCanceledOrderStatusButtons,
      modalVisible,
      userConfirmationStatus,
      confirmModalTitle,
      confirmModalBody,
      confirmButtonValue,
      cancelButtonValue,
      handleUserConfirmation,
      reestrButton,
      getSmsSentStatus,
      tabsCount,
      packingMode,
      totalInnerWrapperWidth,
      showArrows,
      scrollLeft,
      scrollRight,
      containerRef,
      scrolledRight,
      openPackingModal,
      showPackingModal,
      closePackingModal,
      tabsInUse,
      showSummaryProductsToShip,
      productsToShip,
      showPackingListModal,
      closePackingListModal,
      getOrdersForPrint,
      // sendMessage,
      userData,
      callToClient,
      getLength,
      callHistory,
      getIcon,
      svgContent,
      showCallInfo,
      closeCallInfo,
      dateTo,
      dateFrom,
      fetchCallHistory,
      playAudio,
      // audioSrc,
      relatedPhoneNumbersToOrders,
      openOrder,
      printCheks,
      initRoboCall,
      roboCallStats,
      roboCallStatuses,

      showPopup,
      //  paymentReportState

      //ordersForPrint,
      // showPrintPackingListModal
      //changeOrderStatusesByNumberModal,
      /*        ChangeStateSettingsModal,
              settingsModalIsVisible,*/
    };
  },


  components: {
    draggable,
    customModal: customModal,
    RecycleScroller,
    VueMultiselect,
    BModal,
    changeOrderStatusysByNumberModal,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BTabs,
    BSpinner,
    BFormCheckbox,
    BDropdownItem,
    BDropdown,
    BTab,
    BBadge,
    BLink,
    confirmModal,
    PackingModal,
    PackingListModal,
    PrintPackingListModal,
    BOffcanvas,
    Popup,
    importOrdersFromExcel
  },
};
</script>

<style scoped>
.position-top-right {
  position: absolute;
  margin-top: -14px;
  margin-left: -9px;
}

/*@media (max-width: 768px) {
  .button-toolbar {
    flex-direction: column;
  }

  .div-table-cell, .div-table-row {
    display: block;
  }

  .sticky-header {
    position: static;
  }
}*/


span.badge.text-bg-danger.position-top-right {
  z-index: 9;
}

.padding-side-3 {
  #padding-left: 1rem;
  padding-right: 0.25rem;
}

.div-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.font-weight-bold {
  font-weight: bold;
}

.bg-light {
  background-color: #f8f9fa;
}


</style>