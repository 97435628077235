import apiCall from "./apiRequests";

export const createNpSender = async (payload) => {
    return await apiCall('post', `/loadsender`, payload);
}

export const createPromCabinet = async (payload) => {
    return await apiCall('POST', '/promapikey', payload)
}

export const saveOpencartCab = async (payload) => {
    return await apiCall('POST', '/save-oc-cabinet', payload)
}

export const loadMaketFromDb = async (payload) => {
    return await apiCall('GET', '/get-oc-maket' + '?cabinet_id=' + payload)
}


export const createKastaCabinet = async (payload) => {
    return await apiCall('POST', '/save-kasta-settings', payload)
}

export const roboCallInit = async (payload) => {
    return await apiCall('POST', '/robocall-init', payload)
}

export const createHoroshopCabinet = async (payload) => {
    return await apiCall('POST', '/create-horoshop-settings', payload)
}

export const getRobocallState = async (payload) => {
    return await apiCall('POST', '/get-robocall-state', payload)
}

export const createHubberCabinet = async (payload) => {
    return await apiCall('POST', '/create-hubber-cabinet', payload)
}


export const createRozetkaCabinet = async (payload) => {
    return await apiCall('POST', '/save-rozetka-settings', payload)
}

export const sendRequestRemoveFromReestr = async (payload) => {
    return await apiCall('POST', '/remove-from-reestr', payload)
}

export const editContactPerson = async (payload) => {
    return await apiCall('POST', '/edit-contact-person', payload)
}

export const editSenderSettings = async (payload) => {
    return await apiCall('POST', '/edit-sender-settings', payload)
}

export const createUpCabinet = async (payload) => {
    return await apiCall('POST', '/create_ukrpost_sernder', payload)
}

export const PuTUpdateDefaultAlphaName = async (payload) => {
    return await apiCall('POST', '/update-default-alpha-name', payload)
}

export const createCrmUsersRequest = async (payload) => {
    return await apiCall('POST', '/users', payload)
}

export const updateSenderWarehouse = async (payload) => {
    return await apiCall('POST', '/update-sender-warehouse', payload)
}

export const updatePackingStatus = async (payload) => {
    return await apiCall('post', '/set-packed-ordered-products', payload)
}

export const savePaidDropOrders = async (payload) => {
    return await apiCall('post', '/save-paid-drop-orders', payload)
}

export const makeCall = async (payload) => {
    return await apiCall('post', '/make-call', payload)
}

export const saveOrderSource = async (payload) => {
    return await apiCall('POST', '/save-source', payload)
}

export const deleteProducts = async (payload) => {
    return await apiCall('POST', '/delete-products', payload)
}

export const loadAlphaNames = async (payload) => {
    return await apiCall('POST', '/load_alpha_names', payload)
}


////// NO CACHE GET REQUESTS
export const getRozetkaProducts = async () => {
    return await apiCall('POST', '/get-rozetka-products')
}

export const getKastaProducts = async () => {
    return await apiCall('POST', '/import-kasta-products')
}

export const getOpencartProducts = async () => {
    return await apiCall('GET', '/get-oc-products')
}



export const getHoroshopProducts = async () => {
    return await apiCall('GET', '/get-horoshop-products')
}

export const getHubberProducts = async () => {
    return await apiCall('GET', '/import-hubber-products')
}

export const getPromProducts = async () => {
    return await apiCall('POST', '/import-prom-products')
}

export const getAllPriceTypesByUser = async () => {
    return await apiCall('GET', '/price-types')
}



export const savePrices = async (payload) => {
    return await apiCall('POST', '/price-types', payload)
}

export const getCategoriesByUser = async () => {
    return await apiCall('GET', '/categories')
}

export const saveCateg = async (payload) => {
    return await apiCall('POST', '/categories', payload)
}




/// end of no cache get requests

/// POST запити які можуть бути закешовані
export const getOrdersDataForCallsHostoryByPhone = async (payload) => {
    return await apiCall('POST', '/get-orders-data-for-calls-history-by-phone', payload)
}

/*
payload = {
    "date_from": "2024-07-30T21:00:00.000Z",
    "date_to": "2024-08-07T16:06:40.285Z"
}
 */

export const getCallsHistory = async (payload) => {
    return await apiCall('get', '/get-calls-history?' + payload, )
    //   return await apiCall('post', '/get-calls-history', payload)
}

/// end of POST requests

/// 1 day cache
export const getSenderSettings = async () => {
    return await apiCall('GET', '/get-sender-settings')
}

export const getOrderSources = async () => {
    return await apiCall('GET', '/get-source-types')
}

export const getNpCabinetsList = async () => {
    return await apiCall('GET', '/get-np-cabinets-list')
}

export const getCustomSources = async () => {
    return await apiCall('GET', '/get-custom-sources')
}

export const getDeliveryStatuses = async () => {
    return await apiCall('POST', '/get-delivery-statuses')
}

export const getDaysWIthPayments = async () => {
    return await apiCall('GET', '/get-days-with-payments')
}


export const revokeReceivedPayment = async (payload) => {
    return await apiCall('POST', '/revoke-received-payment', payload)
}

/// end of 1 day cache

// small period cache '5s'

export const getDropReportData = async (payload) => {
    return await apiCall('GET', '/get-drop-report', payload)
}

export const getTabsCount = async () => {
    return await apiCall('GET', '/get-tabs-count')
}

export const getUsers = async () => {
    return await apiCall('GET', '/get-users')
}

// end of small period cache


/// багато запитів по юрл (міста, відділення, покупці, товари)

export const getSearchResults = async (url) => {
    return await apiCall('GET', `/${url}`)
}

export const updatePricesStockTypesForSources = async (payload) => {
    return await apiCall('POST', '/update-stock-price-types-for-sources', payload)
}


export const getPricesByProductIdsAndPriceType = async (payload) => {
    return await apiCall('POST', '/get-prices-by-product-ids-and-price-type', payload)
}


export const sendRequestEditKassa = async (payload) => {
    return await apiCall('POST', '/create-rro-cabinet', payload)
}

export const createCheksQuery = async (payload) => {
    return await apiCall('POST', '/create-check', payload)
}

export const printCheksQuery = async (payload) => {
    return await apiCall('POST', '/print-checks', payload)
}


export const savePushKey = async (payload) => {
    return await apiCall('POST', '/save-push-key', payload)
}

export const getThemeSettings = async (payload) => {
    return await apiCall('POST', '/get-theme-settings', payload)
}


export const exportExcelOrders = async (payload) => {
    return await apiCall('POST', '/import-exec-orders', payload, null, 'multipart/form-data')
}





// end of багато запитів по юрл (міста, відділення, покупці, товари)


/*export const setDefaultWarehouse = async (payload) => {
    return await apiCall('POST', '/update-default-sender-warehouse', payload)
}*/


export default {
    createNpSender,
    loadAlphaNames
};

