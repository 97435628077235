<template>

  <draggable-resizable-vue
      v-show="showOrderModal"
      :resizable="false"
      v-model:x="orderModalWindowPosition.x"
      v-model:y="orderModalWindowPosition.y"
      v-model:h="orderModalWindowPosition.height"
      v-model:w="orderModalWindowPosition.width"
      v-model:active="orderModalWindowPosition.isActive"
      :z="orderModalWindowPosition.zIndex"
      handles-type="borders"
      :style="{  position: 'fixed'}"
      drag-handle=".modal-header"
      drag-cancel=".unDraggable"
  >

    <BContainer class="modal-content modal">
      <div class="modal-header justify-content-between">
        <div><h5 class="modal-title unDraggable">{{ modalTitle.title + ' ' + OrderId + ' ' + formattedOrderDate }}</h5>
        </div>
        <div>
          <!--          <button @click="minimize" class="btn unDraggable btn-outline-secondary btn-sm me-2">
                      {{ isMinimized ? 'Maximize' : 'Minimize' }}
                    </button>-->
          <BButton @click="closeModal" size="sm" variant="outline-danger">
            Закрити
          </BButton>

        </div>
      </div>
      <div class="modal-body modal-body">
        <!--        <template>-->
        <BRow class="tests">
          <BCol lg="6" sm="12">
            <BRow class="d-flex justify-content-between align-items-center">
              <!--                  <template v-show="OrderId !== ''">
                                  <BCol>
                                    Заказ №: {{ OrderId }}
                                  </BCol>
                                </template>-->
              <BCol>
                <div class="d-flex justify-content-between">
                  <div>
                    <!--                    <div> myNumber </div>
                                        <BFormInput
                                                    v-model="myNumber"
                                                    @input="validateNumber($event)"
                                        > </BFormInput>-->
                    <BInputGroup>
                      <template #prepend>
                        <BDropdown
                            :text="selectedOrderStatus.name"
                            boundary="viewport"
                            container="body"
                            variant="btn btn-outline-secondary btn-sm me-2">
                          <BDropdownItem v-for="status in orderStatuses"
                                         :key="status.id"
                                         @click="selectOrderStatus(status)"
                          >{{ status.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </div>
                  <div>
                    <BInputGroup class="input-group-sm">
                      <template v-if="SourceName.sourceType !== ''" #prepend>
                        <BInputGroupText size="sm">{{ SourceName.sourceType }}</BInputGroupText>
                      </template>
                      <template #append>
                        <BDropdown
                            :disabled="OrderId !== ''"
                            :text="SourceName.name"
                            boundary="viewport"
                            container="body"
                            variant="btn btn-outline-secondary btn-sm me-2">
                          <BDropdownItem v-for="type in Object.values(customSources)"
                                         :key="type.id"
                                         @click="selectOrderSource(type)"
                          >{{ type.sourceType }} {{ type.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </div>
                  <!--                <BInputGroup size="sm" v-model="SourceType" :prepend="SourceType">
                                    <span>{{ SourceName }}</span>
                                  </BInputGroup>-->
                </div>
              </BCol>
            </BRow>
            <BRow v-if="SourceName.sourceType === 'prom'
             || SourceName.sourceType === 'rozetka'
             || SourceName.sourceType === 'kasta'"
                  class="d-flex justify-content-between align-items-center">
              <BCol>
                <BBadge>
                  {{ marketplaceOrderStatus }}
                </BBadge>
              </BCol>
            </BRow>


            <BRow>
              <BInputGroup class="position-relative">
                <template #prepend>
                  <BDropdown size="sm"
                             :text="buyerDropTypes[selectedBuyerDropTypeId].name"

                             variant="outline-secondary">
                    <BDropdownItem @click="selectBuyerDropType(1)">Покупець</BDropdownItem>
                    <BDropdownItem @click="selectBuyerDropType(2)">Дроп. постачальник</BDropdownItem>
                    <BDropdownItem @click="selectBuyerDropType(3)">Дропшипер/посередник</BDropdownItem>
                  </BDropdown>
                </template>
                <!-- @blur="() => { isFocusWithinDropdown = false; handleBlur('buyerName'); }"-->
                <!--                  <template class="search-container ">-->
                <!--              <div @dblclick="() => console.log('doble click')">doubleclick</div>-->
                <BFormInput
                    ref="buyerNameRef"
                    @keydown.tab="handleTabNavigation($event, 'buyerNameRef')"
                    autocomplete="off" size="sm"
                    :state="inputNameStatus"
                    v-model="buyerName"
                    :placeholder="BuyerDropPlaceholder"
                    @focus="() => { handleFocus('buyerName'); isFocusWithinDropdown = true; }"
                    @blur="() => { isFocusWithinDropdown = false; handleBlur('buyerName'); }"
                    @input="validateBuyerName();"
                    @keydown="handleBuyerNameInputKeydown($event, isNameDropdownVisible)"
                    @keyup.enter.prevent="() => search('name', buyerName, 0)"
                    @dblclick="() => toggleInputEditability('buyerName')"
                    aria-describedby="buyerName"
                    class="search-container buyer-name-search-input"
                />


                <!--            <div v-show="validationMessage && !inputFocusStates.buyerName && buyerName.length > 0"
             class="validation-error">{{ validationMessage }}
        </div>-->

                <!--              :disabled="(buyerId !== '' || (inputNameStatus && !inputFocusStates['buyerName'])) && !buyerNameIsEditable"
                :readonly="(buyerId !== '' || (inputNameStatus && !inputFocusStates['buyerName'])) && !buyerNameIsEditable"
                -->
                <div
                    v-show="isNameDropdownVisible && inputFocusStates.buyerName && filteredBuyerNameResults.length !== 0"
                    class="dropdown-menu show">
                  <template v-for="(result, buyerIndex) in filteredBuyerNameResults">
                    <template v-if="result.np_buyer_cont_per_data && result.np_buyer_cont_per_data.length === 1
                          && result.np_buyer_cont_per_data[0].addresses.length > 1">
                      <button
                          v-for="(address, addressIndex) in result.np_buyer_cont_per_data[0].addresses"
                          :key="addressIndex"
                          @focus="() => { handleFocus('buyerName'); isFocusWithinDropdown = true; }"
                          @blur="() => { isFocusWithinDropdown = false; handleBlur('buyerName'); }"
                          :ref="el => setBuyerNameItemRef(el, buyerIndex, addressIndex )"
                          @keydown="handleBuyerNameKeydown($event, buyerIndex, addressIndex )"
                          class="dropdown-item"
                          @click="selectBuyerName(result, addressIndex)">

                        {{ result?.name }} - 0{{ result?.phone }}
                        ({{ address.city?.name }} № {{ address.warehouse?.number }})
                      </button>
                    </template>
                    <template v-else>
                      <button
                          :key="result.id"
                          @focus="() => { handleFocus('buyerName'); isFocusWithinDropdown = true; }"
                          @blur="() => { isFocusWithinDropdown = false; handleBlur('buyerName'); }"
                          :ref="el => setItemRef(el, buyerIndex)"
                          @keydown="handleKeydown($event, itemRefs.length)"
                          class="dropdown-item"
                          @click="selectBuyerName(result)"
                      >
                        {{ result.name }} - 0{{ result.phone }}
                        <!--                        ({{ address.city.name }} № {{ address.warehouse.number }})-->
                      </button>
                    </template>
                  </template>
                </div>
                <template v-show="buyerName.length > 0">
                  <BInputGroupAppend>
                    <BButton size="sm" variant="outline-secondary" @click="clearBuyerName">x</BButton>
                  </BInputGroupAppend>
                </template>
                <BFormInvalidFeedback id="buyerName"> {{ validationMessage }}</BFormInvalidFeedback>
              </BInputGroup>

            </BRow>


            <BRow>
              <BInputGroup size="sm" :prepend="'Телефон'" class="position-relative">
                <div class="phone-width-10ch">
                  <BFormInput
                      autocomplete="off"
                      size="sm"
                      variant="outline-secondary"
                      ref="buyerPhoneRef"
                      @paste="handlePaste"
                      @blur="handleBlur('phone')"
                      placeholder="093..."
                      :state="buyerPhone.length === 10"
                      v-model="buyerPhone"
                      @focus="() => { handleFocus('phone'); isFocusWithinDropdown = true; }"
                      @keydown="handleInputKeydown($event, isPhoneDropdownVisible, filteredbuyerPhoneResults)"
                      @keyup.enter.prevent="() => search('phone', buyerPhone, 0)"
                      @dblclick="() => toggleInputEditability('buyerPhone')"
                      class="phone-search-input search-container "
                  />
                </div>
                <div
                    v-show="isPhoneDropdownVisible && inputFocusStates.phone && filteredbuyerPhoneResults.length > 0"
                    class="dropdown-menu show">
                  <button v-for="(result, index)  in filteredbuyerPhoneResults"
                          :key="result.id"
                          @focus="() => { handleFocus('phone'); isFocusWithinDropdown = true; }"
                          @blur="() => { handleBlur('phone'); isFocusWithinDropdown = false;  }"
                          :ref="el => setItemRef(el, index)"
                          @keydown="handleKeydown($event, itemRefs.length)"
                          class="dropdown-item"
                          @click="selectBuyerName(result)"
                  >
                    {{ result.name }} - 0{{ result.phone }}
                  </button>
                  <!--              </div>-->
                </div>
                <BInputGroupAppend>
                  <BButton v-show="buyerPhone.length > 0" size="sm" variant="outline-secondary"
                           @click="clearBuyerPhone">x
                  </BButton>

                  <!--                  <BButton size="sm" variant="outline-secondary"
                                             v-bind="buttonAttributes">
                                    </BButton>-->

                  <BButton size="sm" variant="outline-secondary"
                           :href="userData.ip_telephony === false ? `tel:380${buyerPhone}` : ''"
                           @click="userData.ip_telephony === true ? callToClient() : () => {}">
                    Call
                  </BButton>

                  <BButton size="sm" @click="() => handleButtonClick('SMS')" variant="outline-secondary">SMS</BButton>
                  <BButton size="sm" :href="'viber://chat?number=38' + buyerPhone">Viber</BButton>
                </BInputGroupAppend>
                <!--                <div v-show="dontCall">Не дзвонити</div>-->
              </BInputGroup>
            </BRow>
            <BRow v-show="buyerEmail || selectedBuyerDropTypeId !== 1">
              <BInputGroup size="sm" prepend="@">
                <BFormInput autocomplete="off" v-model="buyerEmail" type="email"/>
              </BInputGroup>
            </BRow>
            <div v-if="showSMSDropdown">
              <BDropdown text="Оберіть шаблон СМС"
                         boundary="viewport"
                         container="body"
              >
                <BDropdownItem
                    v-for="smsTemplate in smsTemplates"
                    :key="smsTemplate.id"
                    @click="selectSmsTemplate(smsTemplate)"
                >
                  {{ smsTemplate.name }}
                </BDropdownItem>
              </BDropdown>
              <div class="d-flex mt-2">
                <BFormTextarea
                    v-model="smsTextArea"
                    placeholder="Enter your message"
                    class="mr-2 smaller-text"
                    :rows="textAreaRowsCount"
                ></BFormTextarea>
                <BButton variant="primary" @click="sendMessage">Send</BButton>
              </div>
            </div>
            <!--            <template>-->
            <BRow class="d-flex align-items-center">
              <BCol>
                <!--                    <BInputGroup v-show="paymentStatus.id !== 1">-->
                <BInputGroup>
                  <BDropdown
                      boundary="viewport"
                      container="body"
                      class="custom-dropdown"
                      size="sm"
                      :text="selectedPaymentType?.shortName ? selectedPaymentType.shortName : MarketplacePaymentType"
                      :variant="dropdownVariant"
                  >
                    <BDropdownItem
                        v-for="paymentType in paymentTypeNames"
                        :key="paymentType.id"
                        @click="selectPaymentType(paymentType)"
                    >
                      {{ paymentType.name }}
                    </BDropdownItem>
                  </BDropdown>
                </BInputGroup>
              </BCol>
              <BCol v-show="showBadge" class="part-payment-amount">
                <div>
                  <BBadge :variant="paymentStatus.color">{{
                      paymentStatus.status
                    }}
                  </BBadge>
                  <div> {{
                      paymentStatus.status === 1 && MarketplacePaymentType !== 'Післяплата' ? MarketplacePaymentType : ''
                    }}
                  </div>
                </div>
              </BCol>

              <BCol
                  v-show="selectedPaymentType?.id
                       === paymentTypeNames[4]?.id"
                  class="part-payment-chackbox">
                <BFormCheckbox
                    v-model="orderIsPaid"

                >
                  Сплачено
                </BFormCheckbox>
              </BCol>

              <BCol
                  v-show="selectedPaymentType?.id === paymentTypeNames[2]?.id || selectedPaymentType?.id === paymentTypeNames[3]?.id"
                  class="part-payment-chackbox">

                <BFormCheckbox
                    v-model="PartialPayment"
                    title="Часткова оплата"
                >
                  Частк. опл.
                </BFormCheckbox>
              </BCol>
              <BCol class="part-payment-amount">
                <BFormInput
                    v-show="PartialPayment"
                    autocomplete="off"
                    size="sm"
                    type="number"
                    step="0.01"
                    v-model="prePaidAmount"
                    placeholder="Сума предоп."

                />
              </BCol>
            </BRow>
            <!--            <BInputGroup size="sm" prepend="Комментар">-->

            <BFormTextarea placeholder="Комментар" size="sm" autocomplete="off" v-model="orderComment"/>

            <!--            </BInputGroup>-->
            <!--            </template>-->

          </BCol>
          <!-- Друга колонка                -->
          <BCol lg="6" sm="12">
            <BRow>

              <BInputGroup>
                <template #prepend>
                  <BDropdown size="sm"
                             :text="selectedDeliveryType.name"
                             :variant="selectedDeliveryType.color"
                             boundary="viewport"
                             container="body"
                  >
                    <BDropdownItem
                        v-for="deliveryType in deliveryInfoAssociatedTypes"
                        :key="deliveryType.id"
                        @click="selectDeliveryType(deliveryType)"
                    >{{ deliveryType.name }}
                    </BDropdownItem>
                  </BDropdown>
                </template>
                <template #append>
                  <div v-show="selectedDeliveryType.id === 1 && npSenders.length > 1">
                    <BDropdown size="sm"
                               :text="selectedSender"
                               variant="outline-secondary"
                               boundary="viewport"
                               container="body"
                    >
                      <BDropdownItem
                          v-show="npSenders.length > 0"
                          v-for="deliverySenderObj in npSenders"
                          :key="deliverySenderObj.id"
                          @click="selectSender(deliverySenderObj)"
                      >
                        {{ deliverySenderObj.name }}
                      </BDropdownItem>
                    </BDropdown>
                  </div>
                </template>
              </BInputGroup>

              <template v-if="Object.keys(senderAddressNp).length > 1 && selectedDeliveryType.id === 1">
                <BDropdown size="sm"
                           :text="senderAddressNp[selectedSenderWarehouse]?.name"
                           variant="outline-secondary"
                           boundary="viewport"
                           container="body"
                >
                  <BDropdownItem
                      v-for="senderAddress in Object.values(senderAddressNp)"
                      :key="senderAddress.id"
                      @click="selectSenderWarehouse(senderAddress)"
                  >
                    {{ senderAddress.name }}
                  </BDropdownItem>
                </BDropdown>
              </template>
            </BRow>
            <BRow>
              <BInputGroup size="sm" prepend="Місто" class="position-relative">
                <!--                  <div class="search-container ">-->
                <BFormInput size="sm"
                            ref="CityInputRef"
                            @keydown.tab="handleTabNavigation($event, 'CityInputRef')"
                            :state="selectedCity.id !== '' || selectedDeliveryType.id === 2 "
                            :disabled="selectedCity.id !== ''"
                            :readonly="selectedCity.id !== ''"
                            autocomplete="off"
                            v-model="selectedCity.name"
                            :placeholder="placeholderCity"
                            @focus="() => { handleFocus('city'); isFocusWithinDropdown = true; }"
                            @blur="() => { isFocusWithinDropdown = false; handleBlur('city'); }"
                            @keydown="handleInputKeydown($event, isCityDropdownVisible, filteredNpCityResults)"
                            @keyup.enter.prevent="() => search('city', selectedCity.name, 0)"
                            class="search-container  city-search-input"
                />

                <div v-show="isCityDropdownVisible && inputFocusStates.city" class="dropdown-menu show">
                  <button v-for="(result, index) in filteredNpCityResults"
                          :key="result.id"
                          @focus="() => { handleFocus('city'); isFocusWithinDropdown = true; }"
                          @blur="() => { isFocusWithinDropdown = false; handleBlur('city'); }"
                          :ref="el => setItemRef(el, index)"
                          @keydown="handleKeydown($event, filteredNpCityResults.length)"
                          class="dropdown-item"
                          @click="selectCity(result)"
                  >
                    {{ result.name }}
                  </button>
                  <!--                    </div>-->
                </div>
                <BInputGroupAppend>
                  <BButton size="sm" variant="outline-secondary" @click="clearDeliveryCity">x</BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BRow>
            <BRow>
              <BInputGroup class="position-relative">
                <template #prepend>
                  <BDropdown size="sm"
                             :text="selectedDeliveryTechnology.name"
                             variant="outline-secondary"
                             boundary="viewport"
                             container="body"
                  >
                    <BDropdownItem
                        v-for="serviceTypeName in serviceTypes"
                        :key="serviceTypeName.id"
                        @click="selectDeliveryTechnology(serviceTypeName)"
                    >
                      {{ serviceTypeName.name }}
                    </BDropdownItem>
                  </BDropdown>
                </template>
                <!--                  <div class="search-container ">-->

                <template v-if="selectedDeliveryTechnology.id === 3">
                  <BFormInput size="sm"
                              ref="WarehouseInputRef"
                              @keydown.tab="handleTabNavigation($event, 'WarehouseInputRef')"
                              autocomplete="off"
                              :state="getWarehouseStateStatus()"
                              :disabled="DeliveryWarehouse.id !== ''"
                              :readonly="DeliveryWarehouse.id !== ''"
                              v-model="DeliveryWarehouse.name"
                              :placeholder="deliveryTechnologyPlaceholder"
                              @focus="() => { handleFocus('npWarehouse'); isFocusWithinDropdown = true; }"
                              @blur="() => { isFocusWithinDropdown = false; handleBlur('npWarehouse'); }"
                              @keydown="handleInputKeydown($event, isWarehouseDropdownVisible, filteredNpWarehouseResults)"
                              @keyup.enter.prevent="() => search('npWarehouse', DeliveryWarehouse.name, 0)"
                              class="warehouse-search-input search-container"
                  />
                  <div v-show="isWarehouseDropdownVisible && inputFocusStates.npWarehouse"
                       class="dropdown-menu show"

                  >
                    <button v-for="(result, index) in filteredNpWarehouseResults"
                            :key="result.id"
                            @focus="() => { handleFocus('npWarehouse'); isFocusWithinDropdown = true; }"
                            @blur="() => { isFocusWithinDropdown = false; handleBlur('npWarehouse'); }"
                            :ref="el => setItemRef(el, index)"
                            @keydown="handleKeydown($event,  filteredNpWarehouseResults.length)"
                            class="dropdown-item"
                            @click="selectWarehouse(result)"
                            boundary="viewport"
                            container="body"
                    >
                      {{ result.name_ua }}
                    </button>
                  </div>

                  <!--                  </div>-->
                  <BInputGroupAppend>
                    <BButton size="sm" variant="outline-secondary" @click="clearDeliveryWarehouse">x</BButton>
                  </BInputGroupAppend>
                </template>
                <template v-else>
                  <BFormInput
                      size="sm"
                      :state="streetValues.name !== ''"
                      autocomplete="off"
                      v-model="streetValues.name"
                      placeholder="Введіть вулицю повністю"
                  >
                  </BFormInput>
                  <BRow class="mt-2xl">
                    <BRow>
                      <BCol>
                        <BInputGroup size="sm" prepend="Будинок">
                          <BFormInput
                              size="sm"
                              autocomplete="off"
                              :state="streetValues.building !== ''"
                              v-model="streetValues.building"
                              placeholder="Будинок"
                          >
                          </BFormInput>
                        </BInputGroup>
                      </BCol>
                      <BCol>
                        <BInputGroup size="sm" prepend="кв.">
                          <BFormInput
                              size="sm"
                              autocomplete="off"
                              :state="streetValues.flat !== ''"
                              v-model="streetValues.flat"
                              placeholder="квартира"
                          >
                          </BFormInput>
                        </BInputGroup>
                      </BCol>
                    </BRow>
                    <BRow>
                      <BCol>
                        <BInputGroup size="sm" prepend="Коментад до адреси">
                          <BFormInput
                              size="sm"
                              autocomplete="off"

                              v-model="streetValues.comment"
                              placeholder="комментар до адреси"
                          >
                          </BFormInput>
                        </BInputGroup>
                      </BCol>
                    </BRow>
                  </BRow>

                </template>

              </BInputGroup>
            </BRow>

            <template class="d-flex justify-content-between align-items-center other-receiver">
              <BFormCheckbox v-model="otherReceiverChecked">Інший одержувач</BFormCheckbox>
              <BFormCheckbox v-model="urikChecked">Одержувач Юр.особа</BFormCheckbox>
            </template>
            <div v-if="otherReceiverChecked">
              <BRow>
                <BInputGroup size="sm" prepend="Одержувач">
                  <!--                      <div class="search-container ">-->
                  <BFormInput size="sm"
                              :state="inputDeliveryFioStatus"
                              ref="deliveryFioRef"
                              autocomplete="off"
                              v-model="DeliveryFio"
                              placeholder="Введіть ПІБ одержувача"
                              @focus="() => { handleFocus('DeliveryFio'); isFocusWithinDropdown = true; }"
                              @blur="() => { isFocusWithinDropdown = false; handleBlur('DeliveryFio'); }"
                              @input="validateDeliveryFio();"
                              @keydown="handleCombinedInputKeydown($event, isDeliveryFioDropdownVisible, searchDeliveryFioResults)"
                              class="buyer-name-search-input search-container "
                              BuyerContactPersonId
                              @dblclick="() => toggleInputEditability('DeliveryFio')"

                  />
                  <!--
                                    :disabled="(BuyerContactPersonId !== '' || (inputDeliveryFioStatus && !inputFocusStates['DeliveryFio'])) && !DeliveryFioIsEditable"
                                    :readonly="(BuyerContactPersonId !== '' || (inputDeliveryFioStatus && !inputFocusStates['DeliveryFio'])) && !DeliveryFioIsEditable"
                  -->

                  <div v-show="isDeliveryFioDropdownVisible && inputFocusStates.DeliveryFio"
                       class="dropdown-menu show">
                    <div v-for="(result, index) in filteredDeliveryFioResults">
                      <button
                          v-for="(address, addressIndex) in result.addresses"
                          :key="addressIndex"
                          @focus="() => { handleFocus('DeliveryFio'); isFocusWithinDropdown = true; }"
                          @blur="() => { isFocusWithinDropdown = false; handleBlur('DeliveryFio'); }"
                          :ref="el => setCombinedItemRef(el, index, addressIndex)"
                          @keydown="handleCombinedKeydown($event, setCombinedItemRef.length)"
                          class="dropdown-item"
                          @click="selectDeliveryFioName(result, index, addressIndex)">
                        {{ result.counterparty_recipient_full_name }}
                        ({{ address.city.name }} № {{ address.warehouse.number }})
                      </button>
                    </div>
                    <!--                        </div>-->
                  </div>
                </BInputGroup>
              </BRow>
              <div v-show="validationMessageDelivery && !inputFocusStates.DeliveryFio && DeliveryFio.length > 0"
                   class="validation-error">{{ validationMessageDelivery }}
              </div>
              <BRow>
                <BInputGroup size="sm" prepend="телефон одержувача">
                  <BFormInput autocomplete="off"
                              v-model="DeliveryPhone"
                              ref="deliveryPhoneRef"
                              @blur="() => { handleBlur('contactPersonPhone'); }"

                              @dblclick="() => toggleInputEditability('contactPersonPhone')"
                  />

                  <!--                  :readonly="DeliveryPhone.length === 10 && !DeliveryPhoneIsEditable"
                                    :disabled="DeliveryPhone.length === 10 && !DeliveryPhoneIsEditable"-->

                </BInputGroup>
              </BRow>
            </div>
            <!--                  <template v-if="DeliveryPhone !== buyerPhone">
                                <BInputGroup size="sm">
                                  <template #prepend>
                                    <BInputGroupText>Одержувач</BInputGroupText>
                                  </template>
                                  <BFormInput v-model="DeliveryFio"/>
                                  &lt;!&ndash;                    <template #append>
                                                        <BDropdown :text="CounterpartyType" variant="outline-secondary">
                                                          <BDropdownItem @click="setCounterPartyType('Фіз.о')">Фіз. особа</BDropdownItem>
                                                          <BDropdownItem @click="setCounterPartyType('Юр.о')">Юр. особа</BDropdownItem>
                                                        </BDropdown>
                                                      </template>
                                                    </BInputGroup>
                                                    <BInputGroup v-if="CounterpartyType === 'Юр.о'" size="sm" v-model="inn"
                                                                 prepend="ІНН/ЕДПОРУ">
                                                      <BFormInput/>&ndash;&gt;
                                </BInputGroup>

                                <BInputGroup size="sm" prepend="телефон одержувача">
                                  <BFormInput v-model="DeliveryPhone"/>
                                </BInputGroup>
                              </template>-->

            <BRow v-show="urikChecked">
              <BInputGroup prepend="ІНН/ЕДПОРУ">
                <BFormInput
                    autocomplete="off"
                    v-model="inn"
                />
              </BInputGroup>
            </BRow>

            <!--            <span class="btn btn-link"
                              @click="isAddiditionalOrderSettingsVisible = !isAddiditionalOrderSettingsVisible">
                          {{
                            isAddiditionalOrderSettingsVisible
                                ? 'Сховати додаткові налаштування'
                                : 'Показати додаткові налаштування'
                          }}</span>-->

            <BRow class="mt-1">
              <BCol lg="8" class="link"
                    @click="isAddiditionalOrderSettingsVisible = !isAddiditionalOrderSettingsVisible">
                {{
                  isAddiditionalOrderSettingsVisible
                      ? 'Сховати додаткові налаштування'
                      : 'Додаткові налаштування'
                }}
              </BCol>
              <BCol lg="4">
                <BFormCheckbox v-model="createCheck">Треба чек
                </BFormCheckbox>
              </BCol>
            </BRow>

            <div v-show="isAddiditionalOrderSettingsVisible">
              <div v-show="selectedDeliveryType?.id === 1 || selectedDeliveryType?.id === 2">
                <BRow>
                  <BInputGroup size="sm" prepend="Платник за доставку">
                    <template #append>
                      <BDropdown :text="DeliveryPayer?.name"
                                 variant="btn btn-outline-secondary btn-sm me-2">
                        <BDropdownItem
                            v-for="deliveryPayerTypeValue in DeliveryPayerTypes"
                            :key="deliveryPayerTypeValue.id"
                            @click="selectDeliveryPayer(deliveryPayerTypeValue)"
                        >
                          {{ deliveryPayerTypeValue.name }}
                        </BDropdownItem>
                      </BDropdown>
                    </template>
                  </BInputGroup>
                </BRow>

                <BRow>
                  <BInputGroup size="sm" v-show="selectedPaymentType?.id !== 4"
                               prepend="Платник зворотньої доставки">
                    <template #append>
                      <BDropdown :text="BackwardDeliveryPayer?.name"
                                 variant="btn btn-outline-secondary btn-sm me-2"

                      >
                        <BDropdownItem
                            v-for="deliveryPayerTypeValue in DeliveryPayerTypes"
                            :key="deliveryPayerTypeValue.id"
                            @click="selectBackwardDeliveryPayer(deliveryPayerTypeValue)"
                        >
                          {{ deliveryPayerTypeValue.name }}
                        </BDropdownItem>
                      </BDropdown>
                    </template>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup v-show="isNonCashSupported || urikChecked" size="sm"
                               prepend="Розрахунок з перевізником за доставку">
                    <template #append>
                      <BDropdown :text=selectedCashlesType.name variant="btn btn-outline-secondary btn-sm me-2">
                        <BDropdownItem
                            v-for="cashlesTypeValue in cashlesTypes"
                            :key="cashlesTypeValue.id"
                            @click="selectCashlesType(cashlesTypeValue)">
                          {{ cashlesTypeValue.name }}
                        </BDropdownItem>

                      </BDropdown>
                    </template>
                  </BInputGroup>
                </BRow>
              </div>


              <template v-if="selectedDeliveryType.id === 2">
                <BFormCheckbox v-model="upAddiditional.is_standard">Укрпошта Стандарт</BFormCheckbox>
                <BFormCheckbox v-model="upAddiditional.bulky">Громіздке</BFormCheckbox>
                <BFormCheckbox v-model="upAddiditional.fragile">Хрупке</BFormCheckbox>
                <BFormCheckbox v-model="upAddiditional.fitting_allowed">Примірка дозволена</BFormCheckbox>
                <BFormCheckbox v-model="upAddiditional.up_check_on_delivery">Перевірка відправлення одержувачем
                </BFormCheckbox>
                <BFormCheckbox v-model="upAddiditional.up_packed_by_sender">Упаковано відправником</BFormCheckbox>
                <BFormSelect v-model="uponFailReceiveTypes[upAddiditional.onFailReceiveType]"
                             :options="uponFailReceiveTypes" :select-size="4"/>
              </template>


            </div>

          </BCol>
          <!--                товари-->
          <!--              <template>-->

          <BCol class="col-12">

            <BCard class="mt-3 products-area">
              <div class="d-flex justify-content-between align-items-center tabs-width">
                <div>
                  <b-tabs v-model="activeProductTab">
                    <b-tab title="Товари" @click="productAreaType = 1" active>

                    </b-tab>
                    <b-tab title="Чеки" @click="productAreaType = 2">
                    </b-tab>

                    <b-tab title="Історія замовлень" @click="setProductAreaType(3)" lazy-once>
                    </b-tab>
                  </b-tabs>
                </div>
                <div>
                  <BDropdown
                      size="sm"
                      v-if="Object.keys(priceTypes).length > 1"
                      boundary="viewport"
                      container="body"
                      :text="'Тип ціни: ' + priceTypes[selectedPriceTypeId]?.name" variant="outline-primary">
                    <BDropdownItem v-for="priceType in Object.values(priceTypes)" :key="priceType.id"
                                   @click="selectPriceType(priceType.id)">
                      {{ priceType.name }}
                    </BDropdownItem>
                  </BDropdown>
                </div>
              </div>
              <template v-if="productAreaType === 1">
                <BTableSimple hover small caption-top responsive class="product-width">
                  <BThead class="text-nowrap" head-variant="dark">
                    <BTr>
                      <BTh class="col-img"></BTh>
                      <BTh class="col-article">Артикуль</BTh>
                      <BTh class="col-name">Назва</BTh>
                      <BTh class="col-qty">К-сть</BTh>
                      <BTh class="col-price">Ціна</BTh>
                      <BTh class="col-price">Всього</BTh>
                      <!--  <BTh class="col-stock">Залиш</BTh>
                                        <BTh class="col-stock">Поверн</BTh>-->
                      <BTh class="col-weight">Вага</BTh>
                      <BTh class="col-stock" title="Залишок/Резерв/Повернення. Загальний(по поточ. замовленню)">
                        З/Р/П
                      </BTh>
                      <BTh class="col-remove"></BTh>
                    </BTr>
                  </BThead>

                  <BTr v-for="(inputProductField, index) in inputProductFields" :key="index">
                    <BTd class="position-relative">
                      <div class="d-flex justify-content-around align-items-center">
                        <div v-if="inputProductFields[index].product.image_url">
                          <BImg
                              :id="'preview-img' + index"
                              :src="previewImgUrl"
                              alt="Image description"
                          />

                          <BPopover
                              :target="'preview-img' + index"
                              triggers="hover click"
                              strategy="fixed"
                          >
                            <template #imgpreview></template>
                            <BImg style="width: 200px; height: auto"
                                  :src="inputProductFields[index].product.image_url"
                                  alt=""/>

                          </BPopover>
                        </div>
                        <div v-show="inputProductFields[index].product.unknown_product === 1">
                          <div>
                            <template
                                :id="'circle-input' + index"
                                class="exclamation-circle">
                              !
                            </template>
                            <BPopover
                                :target="'circle-input' + index"
                                triggers="hover click"
                                strategy="fixed"
                            >
                              <template #tooltipSlot></template>
                              <div> Цей товар не був знайдений серед існуючих,
                                перевірте чи це не дублікат (якщо товар вже існує,
                                то додайте в нього артикуль та код товару), а цей товар видаліть
                              </div>
                            </BPopover>
                          </div>
                        </div>
                      </div>

                    </BTd>
                    <BTd>
                      <div
                          :ref="el => { if (inputProductFields[index]) inputProductFields[index].inputField.article.element = el }">
                        <BFormInput
                            autocomplete="off" size="sm"
                            v-model="inputProductFields[index].product.article"
                            placeholder="артикуль"
                            @focus="() => { handleFocus('article', index); currentProductIndex = index; }"
                            @dblclick="() => toggleInputEditability('article', index)"
                            @keydown="handleProductInputKeydown($event,
                                                    inputProductFields[index].inputField.name.isProductNameDropdownVisible,
                                                     filteredProductsResults, index)"
                            @keyup.enter.prevent="() => search('article', inputProductFields[index].product.article, 0, index)"
                        />
                      </div>
                      <!--                      <div
                                                :ref="el => {
                                                if (inputProductFields[index]) {
                                                  inputProductFields[index].inputField.article.element = el;
                                                }
                                              }"
                                            >
                                              &lt;!&ndash; Вхідний елемент &ndash;&gt;
                                              <BFormInput

                                                  autocomplete="off"
                                                  size="sm"
                                                  v-model="inputProductFields[index].product.article"
                                                  placeholder="артикуль"
                                                  @focus="() => {
                                                      handleFocus('article', index);
                                                      currentProductIndex = index;
                                                    }"
                                                  @dblclick="() => toggleInputEditability('article', index)"
                                                  @keydown="handleProductInputKeydown($event,
                                                                  inputProductFields[index].inputField.name.isProductNameDropdownVisible,
                                                                  filteredProductsResults, index)"
                                                  @keyup.enter.prevent="() => search('article', inputProductFields[index].product.article, 0, index)"
                                              />





                                              <div>
                                                <div  class="tooltip bs-tooltip-top show" role="tooltip">
                                                  <div class="tooltip-arrow"></div>
                                                  <div class="tooltip-inner">
                                                    Це завжди видима підказка
                                                  </div>
                                                </div>
                                              </div>


                                            </div>-->

                    </BTd>
                    <BTd>
                      <div class="search-container ">
                        <div :title="inputProductFields[index].product.name"
                             :ref="el => { if (inputProductFields[index]) inputProductFields[index].inputField.name.element = el }">

                          <div>
                            <BFormInput
                                ref="inputField"
                                size="sm"
                                autocomplete="off"
                                :state="inputProductFields[index].product.name.length > 0"
                                v-model="inputProductFields[index].product.name"
                                placeholder="Введіть назву товару"
                                @focus="() => {
                                handleFocus('productName', index);
                                currentProductIndex = index;
                              }"
                                @dblclick="() => toggleInputEditability('productName', index)"
                                @keydown="handleProductInputKeydown($event, inputProductFields[index].inputField.name.isProductNameDropdownVisible, filteredProductsResults, index)"
                                @keyup.enter.prevent="() => search('productName', inputProductFields[index].product.name, 0, index)"
                            />
                            <BOffcanvas
                                v-model="isProductNameDropdownVisible"
                                noFocus
                                backdrop="false"
                                title="Вибір товару"
                                size="lg"

                            >
                              <button
                                  v-for="(result, dropdownIndex) in filteredProductsResults"
                                  :key="result.id"
                                  :ref="el => setMultipleItemRef(el, dropdownIndex, index)"
                                  @focus="() => {  handleFocus('productName', index);}"
                                  @keydown="handleProductKeydown($event, filteredProductsResults.length, index)"
                                  class="dropdown-item"
                                  @click="selectProduct(result, currentProductIndex)"
                              >
                                <template>
                                  <!--                                <div
                                                                      class="dropdown-item border-bottom display-flex justify-content-between align-items-center white-space-normal"
                                                                      :style="`max-width: ${orderModalWindowPosition.width} + 'px'`">
                                                                    <div class="w-25" v-show="result.thumbnail_url !== ''">
                                                                      <BImg class="img max-height-130px" :src="result.thumbnail_url" alt=""/>
                                                                    </div>

                                                                    <div class="w-75">
                                                                      <div >{{ result.article }} - {{ result.name }}</div>
                                                                      <div class="d-flex">
                                                                        <div > <b> {{ calcPriceWithCurrency(result.price, result.currency_id) }}
                                                                          грн</b>;
                                                                          Залиш: <b> {{ result.stock?.stock_qty }} шт</b>, {{
                                                                            result.stock?.reserved_qty
                                                                          }} шт)
                                                                        </div>
                                                                      </div>
                                                                    </div>

                                                                  </div>-->

                                  <div
                                      class="dropdown-item border-bottom display-flex justify-content-between align-items-center white-space-normal"
                                      :style="`max-width: ${orderModalWindowPosition.width} + 'px'`">

                                    <div v-if="result.thumbnail_url !== null" class="w-25">
                                      <BImg class="img max-height-130px" :src="result.thumbnail_url" alt=""/>
                                    </div>

                                    <div :class="{'w-75': result.thumbnail_url === null}">
                                      <div>{{ result.article }} - {{ result.name }}</div>
                                      <div class="d-flex">
                                        <div>
                                          <b>{{
                                              calcPriceWithCurrency(result.pricesByPriceTypeIds[selectedPriceTypeId]?.price, result.currency_id)
                                            }} грн</b>;
                                          Залиш: <b>{{ result.stock?.stock_qty }} шт</b>, {{
                                            result.stock?.reserved_qty
                                          }}
                                          шт)
                                        </div>
                                      </div>
                                    </div>

                                  </div>


                                </template>
                              </button>
                            </BOffcanvas>
                          </div>
                        </div>
                      </div>
                    </BTd>

                    <BTd>
                      <div
                          :ref="el => { if (inputProductFields[index]) inputProductFields[index].inputField.qty.element = el }">
                        <BFormInput
                            autocomplete="off"
                            size="sm"
                            v-model.number="inputProductFields[index].product.quantity"
                            :style="{ 'border-color': inputProductFields[index].product.quantity !== '' ? 'green' : 'red' }"
                            type="number"
                            @focus="() => { handleFocus('qty', index);}"
                            @blur="() => { handleBlur('qty', index); }"
                            @dblclick="() => toggleInputEditability('qty', index)"

                        />

                      </div>
                    </BTd>

                    <BTd>
                      <div
                          :ref="el => { if (inputProductFields[index]) inputProductFields[index].inputField.price.element = el }">
                        <BFormInput
                            class="no-background-image-input-state no-arrows"
                            :style="{ 'border-color': inputProductFields[index].product.price?.length > 0 ? 'green' : 'red' }"
                            autocomplete="off"
                            size="sm"
                            @change="validatePriceValue(index)"
                            v-model="inputProductFields[index].product.price"
                        />
                      </div>
                    </BTd>
                    <BTd>
                      <span autocomplete="off" size="sm"
                      >{{ inputProductFields[index].product.totalPrice }} </span>
                    </BTd>
                    <BTd>
                      <div>
                        <BFormInput
                            class="no-background-image-input-state no-arrows"
                            :style="{ 'border-color': inputProductFields[index].product.weight !== 0 ? 'green' : 'red' }"
                            autocomplete="off"
                            size="sm"
                            @change="validateWeightNumber(index)"
                            v-model="inputProductFields[index].product.weight"
                        />
                      </div>
                    </BTd>
                    <BTd>
                      <div class="white-space-nowrap" autocomplete="off" size="sm"
                      >{{
                          inputProductFields[index].product.stockQty
                        }} {{
                          inputProductFields[index].product.reservedQty
                        }} {{ inputProductFields[index].product.onReturn }}
                      </div>
                    </BTd>
                    <BTd>
                      <BButton size="sm" variant="outline-secondary" @click="removeProduct(index)">x</BButton>
                    </BTd>
                  </BTr>

                </BTableSimple>


                <BRow class="d-flex justify-content-between">
                  <BCol xs="6" sm="6" md="6" lg="5">
                    <BButton size="sm" @click="addProduct">Додати товар</BButton>
                  </BCol>
                  <BCol xs="6" sm="6" md="6" lg="7" class="d-flex"
                        :class="{
            'justify-content-end': selectedBuyerDropTypeId === 1,
            'justify-content-between': selectedBuyerDropTypeId !== 1}"
                  >
                    <BRow>
                      <BCol lg="8" v-if="selectedBuyerDropTypeId !== 1">
                        <BInputGroup size="sm"
                                     prepend="Вартість дропшипера" append="грн">
                          <BFormInput size="sm"
                                      :state="selectedPaymentType.id === 4
                                    || (parseFloat(prePaidAmount) + parseFloat(dropPrice) >= parseFloat(totalAmount))"

                                      type="number"
                                      step="0.01"
                                      v-model="dropPrice"/>
                        </BInputGroup>
                      </BCol>
                      <BCol :lg="selectedBuyerDropTypeId !== 1 ? 4 : 12">
                        <div>{{ totalAmount }} грн</div>
                      </BCol>
                    </BRow>
                  </BCol>
                </BRow>
              </template>
              <template v-else-if="productAreaType === 2">
                <BRow>
                  <BInputGroup v-if="checkData.prepay_link !== null" size="sm">
                    <BInputGroupText>Чек передплати</BInputGroupText>
                    <BFormInput size="sm" v-model="checkData.prepay_link"/>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'prepay_link', 'watch')">Див</BButton>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'prapay_guid', 'print')">Друк</BButton>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup size="sm">
                    <BInputGroupText variant="primary">Основний чек</BInputGroupText>
                    <BFormInput size="sm" v-model="checkData.postpay_link"/>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'postpay_link', 'watch')">Див</BButton>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'postpay_guid', 'print')">Друк</BButton>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup size="sm">
                    <BInputGroupText>Скасування чеку</BInputGroupText>
                    <BFormInput size="sm" v-model="checkData.return_uuid"/>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'return_link', 'watch')">Див</BButton>
                    <BButton variant="outline-secondary" @click="openCheckLink( 'return_uuid', 'print')">Друк</BButton>
                  </BInputGroup>
                </BRow>
                <BBadge v-if="checkData?.postpay_print_status === 1" variant="success">Основний чек роздруковано
                </BBadge>
                <BBadge v-if="checkData?.postpay_sms_sent === 1" variant="success">Смс з чеком надіслано</BBadge>
              </template>
              <template v-else-if="productAreaType === 3">
                <template v-if="Object.keys(relatedPhoneNumbersToOrders).length > 0">
                  <template v-for="order in relatedPhoneNumbersToOrders[buyerPhone]" :key="order.crm_order_id">
                    <div v-if="crmOroderId !== order.crm_order_id">
                      <b-accordion>
                        <b-accordion-item
                            :title="'Заказ ' + order.order_id + ' | ' + order.totalPrice + ' грн | Статус: ' + order.CrmOrderStatus.name">
                          <div class="d-flex flex-column p-2">
                            <div>Заказ {{ order.order_id }} від {{ order.orderDate }}</div>
                            <div>Джерело: {{ order.source }}</div>
                            <div>{{ order.deliveryType }} <span
                                v-if="order.TtnNumber !== 'Не вказано'"> {{ order.TtnNumber }} </span></div>
                            <div>Статус замовлення: {{ order.CrmOrderStatus.name }}</div>
                            <div v-if="order.deliveryStatus !== null">Статус доставки: {{ order.deliveryStatus }}</div>
                            <div v-if="order.paymentStatus">Сплачено</div>
                            <div>{{ order.totalPrice }} грн</div>
                          </div>
                        </b-accordion-item>
                      </b-accordion>
                    </div>
                  </template>

                </template>
              </template>


            </BCard>
          </BCol>

          <div class="submit-button" ref="submitButtonRef">
            <BButton type="" id="saveOrderButton" @click="saveOrder" :variant="saveButtonColor">
              {{ modalTitle.modalType === 'insert' ? 'Створити замовлення' : 'Зберегти зміни' }}
              <span
                  v-if="selectedBuyerDropTypeId === 1 && afterPaymentValue !== 0 && (selectedPaymentType.id === 2 || selectedPaymentType.id === 3)">&nbsp;з післяплатою {{
                  afterPaymentValue
                }} грн</span>

              <span
                  v-else-if=" selectedBuyerDropTypeId === 3 && dropPrice !== 0.00 && (selectedPaymentType.id === 2 || selectedPaymentType.id === 3)">&nbsp;з післяплатою {{
                  dropPrice
                }} грн</span>

              <span v-else-if="saveButtonColor === 'danger'">&nbsp; без наложки НЕ СПЛАЧЕНО не має бути переміщений в "очікує відправки</span>
              <BSpinner v-if="spinnerStatuses.saveOrder" small label="Збереження..."/>
            </BButton>
          </div>
          <div class="seats-amount-area">
            <BFormCheckbox v-model="showSeatsDetalsParams" class="col-form-label col-lg-2 mb-2 me-sm-2 mb-sm-0">
              Вказати вагу або параметри кожного місця
            </BFormCheckbox>

            <BTableSimple class=" hover small" v-show="showSeatsDetalsParams">
              <BTr>
                <BTd class="text-center seats-amount p-10">Місця</BTd>
                <BTd class="text-center seats-weight  p-10">Вага</BTd>
                <BTd class="text-center seats-volume  p-10">Об'єм</BTd>
                <BTd class="text-center seats-packing-name  p-10">Упаковка</BTd>
                <BTd class="text-center seats-length  p-10">Довжина</BTd>
                <BTd class="text-center seats-length  p-10">Ширина</BTd>
                <BTd class="text-center seats-length  p-10">Висота</BTd>
                <BTd class="text-center p-10">Р/о</BTd>
                <BTd class="add-place-button"></BTd>
              </BTr>
              <BTr v-for="(seatsAmount, seatsAmountindex) in seatsAmounts" :key="seatsAmountindex">
                <BTd class="text-center  p-10">
                  <BFormInput autocomplete="off"
                              size="sm"
                              class="text-center"
                              type="number"
                              placeholder="1"
                              v-model="seatsAmounts[seatsAmountindex].seats_amount"/>
                </BTd>
                <BTd class="text-center  p-10">
                  <BFormInput autocomplete="off"
                              size="sm"
                              class="text-center w75px"
                              type="number"
                              placeholder="0.1"
                              step="0.1"
                              v-model="seatsAmounts[seatsAmountindex].weight"/>
                </BTd>
                <BTd class="text-center  p-10">
                  <BFormInput
                      autocomplete="off"
                      size="sm"
                      class="text-center"
                      placeholder="0.0004"
                      type="number"
                      step="0.0001"
                      v-model="seatsAmounts[seatsAmountindex].volume"/>
                </BTd>

                <BTd>
                  <!--                    <div class="search-container ">-->
                  <div>
                    <div class="d-flex">
                      <div :ref="el => { SeatsInputFields[seatsAmountindex] = el; }">
                        <BFormInput
                            autocomplete="off"
                            size="sm"
                            id="packing-input"
                            v-model="seatsAmounts[seatsAmountindex].np_pack_list_name"
                            placeholder=""
                            @focus="() => { handlePackingFocus('packingName', seatsAmounts[seatsAmountindex].np_pack_list_name, 'packingName', seatsAmountindex);
                                   isFocusWithinDropdown = true; currentPackingIndex = seatsAmountindex; }"
                            @blur="() => { isFocusWithinDropdown = false; handleBlur('packingName'); }"
                            @keydown="handleInputKeydown($event, isPackingDropdownVisible, searchPackingResults)"
                        />
                      </div>
                      <div>
                        <BInputGroupAppend>
                          <BButton size="sm" variant="outline-secondary"
                                   @click="clearPackingNameAndId(seatsAmountindex)">x
                          </BButton>
                        </BInputGroupAppend>
                      </div>

                    </div>

                    <Teleport v-if="showOrderModal" to="#modalContent" :data-index="seatsAmountindex">
                      <div v-show="isPackingDropdownVisible && inputFocusStates.packingName"
                           class="product-dropdown-menu dropdown-menu show"
                           :style="{ top: dropdownTop + 'px', left: dropdownLeft + 'px' }">

                        <button v-for="(result, dropdownIndex) in searchPackingResults"
                                :key="dropdownIndex"
                                @focus="() => { handlePackingFocus(seatsAmountindex); isFocusWithinDropdown = true; }"
                                @blur="() => { isFocusWithinDropdown = false; handleBlur('packingName'); }"
                                :ref="el => setItemRef(el, dropdownIndex)"
                                @keydown="handleKeydown($event, searchPackingResults.length)"
                                class="dropdown-item"
                                @click="selectPacking(result, currentPackingIndex)"
                        >
                          {{ result.description }}
                        </button>
                      </div>
                    </Teleport>
                  </div>
                </BTd>

                <BTd class="text-center  p-10" size="sm">
                  <BFormInput autocomplete="off" class="text-center" v-show="showSeatsDetalsParams"
                              type="number"
                              size="sm"
                              placeholder="5"
                              v-model="seatsAmount.length"/>
                </BTd>
                <BTd class="text-center  p-10" size="sm">
                  <BFormInput autocomplete="off" class="text-center" v-show="showSeatsDetalsParams"
                              type="number"
                              size="sm"
                              placeholder="5"
                              v-model="seatsAmount.width"/>
                </BTd>
                <BTd class="text-center  p-10" size="sm">
                  <BFormInput autocomplete="off" class="text-center" v-show="showSeatsDetalsParams"
                              type="number"
                              size="sm"
                              placeholder="5"
                              v-model="seatsAmount.height"/>
                </BTd>
                <BTd class="text-center  p-10" size="sm">
                  <BFormCheckbox class="text-center disable-left-margin" v-show="showSeatsDetalsParams"
                                 type="select" size="sm" v-model="seatsAmount.handsPrepare"/>
                </BTd>
                <BTd class=" ">
                  <BButton size="sm" v-show="showSeatsDetalsParams"
                           @click="removeCurrentSeatAmount(seatsAmountindex)">Видалити
                  </BButton>
                </BTd>
              </BTr>
              <BTr>
                <BTd class="text-center p-10 ">шт</BTd>
                <BTd class="text-center p-10 ">кг</BTd>
                <BTd class="text-center p-10 ">м³</BTd>
                <BTd class="text-center p-10 " v-show="showSeatsDetalsParams"></BTd>
                <BTd class="text-center p-10 " v-show="showSeatsDetalsParams">см</BTd>
                <BTd class="text-center p-10 " v-show="showSeatsDetalsParams">см</BTd>
                <BTd class="text-center p-10 " v-show="showSeatsDetalsParams">см</BTd>
                <BTd class="text-center p-10 " v-show="showSeatsDetalsParams"></BTd>
                <BTd v-show="showSeatsDetalsParams"></BTd>
              </BTr>
              <BTd class=" ">
                <BButton size="sm" v-show="showSeatsDetalsParams" @click="addSeatAmount">Додати місце</BButton>
              </BTd>
            </BTableSimple>
          </div>

          <BRow class="d-flex justify-content-center align-items-center">
            <BCol>
              <BInputGroup lass="padding-top-10px" size="sm" prepend="ТТН">

                <BFormInput size="sm"
                            autocomplete="off"
                            variant="outline-secondary"
                            v-model="ttnNumber"
                            @focus="() => { handleFocus('ttnNumber');  }"
                            @blur="() => { handleBlur('ttnNumber'); }"
                />
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm">
                <BFormInput
                    autocomplete="off"
                    placeholder='Дата доставки' type="text" min="0.00"/>
                <BInputGroupAppend>
                  <BButton variant="outline-secondary">Розрах</BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm">
                <BFormInput autocomplete="off" placeholder='Ціна доставки' type="text"/>
                <BInputGroupAppend>
                  <BButton variant="outline-secondary">Розрах</BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>
          </BRow>

          <!--          <BButton @click="() => console.log('sender warehouse id', selectedSenderWarehouse)"> show sender warehouse id</BButton>-->

          <BInputGroup size="sm" v-show=" trackingStatus !== null && trackingStatus !== ''" prepend="Статус доставки">
            <div class="search-container ">
              <span size="sm"
                    v-text="trackingStatus"
              />
            </div>
          </BInputGroup>


          <!--              </template>-->
          <!--            <BCol lg="12">
                        <div class="modal-footer">
                          &lt;!&ndash;                  <BButton type="submit" variant="primary">Submit</BButton>&ndash;&gt;
                          <BButton type="" @click="resetModalState" variant="btn btn-secondary">Reset</BButton>
                          <button @click="closeModal" class="btn btn-secondary">Cancel</button>
                        </div>
                      </BCol>-->
        </BRow>

        <!--        </template>-->

      </div>

    </BContainer>
  </draggable-resizable-vue>
  <!--  </BModal>-->


</template>

<script>
import {computed, nextTick, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {useStore} from 'vuex';


import {formatDate} from '../../utils/orderFunctions';
import {debounce} from 'lodash';
import {
  BBadge,
  BButton,
  BCard,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BOffcanvas,
  BPopover,
  BTableSimple,
  BTd,
  BTh,
  BThead,
  BTr
} from 'bootstrap-vue-next';
import {getPricesByProductIdsAndPriceType, getSearchResults} from "@/axios/settignsRequests";
import {useCallToClient} from "@/asterisk/useCallToClient";


export default {
  name: 'customModal',
  props: {
    showOrderModal: {
      type: Boolean,
    },

    activeTab: {
      type: Number
    },

    modalTitle: {
      type: Object,
      default: () => ({})
    },

    orderForUpdate: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['update:showOrderModal'],

  async setup(props, {emit}) {
    const store = useStore();
    const PartialPayment = ref(false);
    const orderModalWindowPosition = ref({
      x: 50,
      y: 50,
      width: 900,
      height: 663,
      isActive: false,
      zIndex: 10,
    })

    /*  watch( () => orderModalWindowPosition.value.width) => (value){
        if
      }*/

    const userData = ref(store.getters['order/getUsersData']);


    const activeProductTab = ref(0);

    const productAreaType = ref(1) // 1 products, 2 - checks


    const selectedPriceTypeId = ref(0);
    selectedPriceTypeId.value = localStorage.getItem('senderSettings')
        ? JSON.parse(localStorage.getItem('senderSettings')).default_price_type_id : null;


    const senderAddressNp = ref({});

    const customSources = ref({});

    customSources.value = store.getters['order/getCustomSources'];

    const paymentTypeNames = ref({});

    onMounted(() => {
      setInitialDimensions();

    });


    paymentTypeNames.value = store.getters['order/getPaymentTypes'];
    //senderAddressNp.value = store.getters['order/senderAddressesNp'];
    senderAddressNp.value = JSON.parse(localStorage.getItem('senderAddressesNp'));
    const priceTypes = ref(JSON.parse(localStorage.getItem('price_types')));

    //  console.log('existed priceTypes', priceTypes.value);

    let usdVal = 0
    let eurVal = 0
    let zlVal = 0

    const currencyes = {
      '1': 0, // uah
      '2': 0, // usd_val
      '3': 0, // eur_val
      '4': 0 // zl_val
    }


    const active = ref(false);
    //const isVisible = ref(props.show);
    const {orderForUpdate} = toRefs(props);
    // console.log('order in open modal', orderForUpdate.value);
    const currentPackingIndex = ref('');
    const currentProductIndex = ref(0);
    const isMinimized = ref(false);
    const showSeatsDetalsParams = ref(false);


    //  senderAddressNp.value = JSON.parse(localStorage.getItem('senderAddressesNp'));
    //  console.log('senderAddressNp', senderAddressNp.value);

    /*    const x = ref(100);
        const y = ref(100);
        const w = ref(700); // Default width
        const h = ref(75); // Default height*/

    const placeholderCity = ref('Введіть місто');
    const OrderId = ref('');
    const orderDate = ref('');
    const SourceType = ref('')
    /*    const SourceName = ref({
          name: userData.value.source_name ?? null,
          id: userData.value.source_id ?? null,
          sourceType: userData.value.source_type ?? null
        })*/
    const SourceName = ref({
      id: 15,
      name: null,
      sourceType: 'from_frontend',
    })


    const sourceRelatedToUser = userData.value.source_type === null
        ? null
        : userData.value.source_type + userData.value.source_id;


    if (sourceRelatedToUser !== null && SourceName.value.name === null) {

      selectOrderSource(customSources.value[sourceRelatedToUser])
    }


    let firstFullOrderData = null;
    const marketplaceOrderStatus = ref('')
    const totalAmount = ref(0)
    const prePaidAmount = ref(0.00)
    const paymentType = ref('')
    const paymentStatus = ref({})
    const crmOroderId = ref(0);
    const ttnNumber = ref('')
    const trackingStatus = ref('')
    const SenderContactPersonId = ref('')
    const dontCall = ref(false);
    const usersOrderNumber = ref('');
    const selectedCity = reactive({
      name: '',
      id: '',
    });
    const dropPrice = ref(0.00);

    const inn = ref('')
    let orderStatuses;
    const currentOrderStatus = ref({})

    const DeliveryWarehouse = reactive({
      name: '',
      id: '',
    }) // id and
    const serviceTypes = {
      3: {id: 3, name: 'Відділення'},
      4: {id: 4, name: 'Адреса (вулиця)'},
    }

    const streetValues = reactive({
      name: '',
      building: '',
      flat: '',
      comment: '',
    })

    const addressChangedBySender = ref(false);

    const selectedDeliveryTechnology = ref({});
    const selectedPaymentType = ref({id: null});
    const npDefaultWeight = ref(0.5);
    let senderDeliveryPayerLimit;

    const DeliveryPayer = ref({});
    const isAutomaticUpdate = ref(false);
    const buyerName = ref('')
    const buyerPhone = ref('');
    const buyerPhoneIsNotEditable = ref(false);
    const buyerNameIsEditable = ref(false);
    const DeliveryFioIsEditable = ref(false);
    const DeliveryPhoneIsEditable = ref(false);
    const ProductNameIsEditable = ref(false);
    const DeliveryFio = ref('')
    const DeliveryPhone = ref('')
    const BuyerContactPersonId = ref(null)
    const buyerId = ref('')
    const createCheck = ref(false);
    const checkData = ref(null);
    const buyerEmail = ref('')
    const orderComment = ref('')
    const intermChecked = ref(false);
    const urikChecked = ref(false);
    const otherReceiverChecked = ref(false);
    const orderIsPaid = ref(false);

    const DeliveryPayerTypes = store.getters['order/getDeliveryPayerTypes'];

    // console.log('DeliveryPayerTypes', DeliveryPayerTypes)

    const BackwardDeliveryPayer = ref(DeliveryPayerTypes[4]);
    let StaticPackingTypes = [];
    const selectBackwardDeliveryPayer = (deliveryPayerTypeValue) => {
      BackwardDeliveryPayer.value = deliveryPayerTypeValue;
      needToUpdate.deliveryData = true;
      // console.log('deliveryData changed 4');
    };

    const BuyerDropPlaceholder = ref('Введіть ФІО покупця')
    const showSMSDropdown = ref(false);
    const comment = ref('');

    const seatsAmounts = ref([]);
    const defaultNpSender = ref({});

    const CityInputRef = ref(null);
    const WarehouseInputRef = ref(null);
    const buyerNameRef = ref(null);
    const buyerPhoneRef = ref(null);
    const deliveryFioRef = ref(null);
    const deliveryPhoneRef = ref(null);
    const productQtyInputRef = ref(null);
    const productPriceInputRef = ref(null);
    const weightInputRef = ref(null);
    const submitButtonRef = ref(null);

    const selectedOrderStatus = ref({
      name: 'Очікує відправки',
      id: 2,
    });


    const getWarehouseStateStatus = (warehouse) => {
      if (selectedDeliveryType[1]) {
        return DeliveryWarehouse.id !== '';
      } else if (selectedDeliveryType[2]) {
        return DeliveryWarehouse.id !== '';
      } else if (selectedDeliveryType[3]) {
        return DeliveryWarehouse.name !== '';
      } else {
        return DeliveryWarehouse.name !== '';
      }

    };


    const handleTabNavigation = async (event, inputType, index = 0) => {
      //  console.log('inputType', inputType);
      //   console.log('index', index);

      let currentRef = null;
      let nextRef = null;

      if (inputType === 'buyerNameRef') {
        currentRef = buyerNameRef.value;
        nextRef = buyerPhoneRef.value;

      } else if (inputType === 'buyerName') {
        currentRef = buyerNameRef.value;
        nextRef = buyerNameRef.value;
      } else if (inputType === 'deliveryFioRef') {
        currentRef = buyerNameRef.value;
        nextRef = deliveryFioRef.value;
      } else if (inputType === 'deliveryPhoneRef') {
        currentRef = deliveryPhoneRef.value;
        nextRef = deliveryPhoneRef.value;

      } else if (inputType === 'buyerPhoneRef') {
        currentRef = buyerPhoneRef.value;
        nextRef = CityInputRef.value
      } else if (inputType === 'CityInputRef') {
        currentRef = CityInputRef.value;
        nextRef = WarehouseInputRef.value;
      } else if (inputType === 'WarehouseInputRef') {
        currentRef = WarehouseInputRef.value;

        nextRef = inputProductFields.value[index].inputField.name.element.querySelector('input');
      } else if (inputType === 'ProductNameInputRef') {
        currentRef = inputProductFields.value[index].inputField.name.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.qty.element.querySelector('input');
      } else if (inputType === 'ProductQtyInputRef') {
        currentRef = inputProductFields.value[index].inputField.qty.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.price.element.querySelector('input');
      } else if (inputType === 'ProductPriceInputRef') {
        //  console.log('ProductPriceInputRef');
        currentRef = inputProductFields.value[index].inputField.price.element.querySelector('input');
        nextRef = submitButtonRef.value.querySelector('button');
      } else if (inputType === 'focusedArticle') {
        currentRef = inputProductFields.value[index].inputField.article.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.article.element.querySelector('input');
      } else if (inputType === 'focusedProduct') {
        currentRef = inputProductFields.value[index].inputField.name.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.name.element.querySelector('input');
      } else if (inputType === 'focusedProductQty') {
        currentRef = inputProductFields.value[index].inputField.qty.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.qty.element.querySelector('input');
      } else if (inputType === 'focusedProductPrice') {
        console.log('focusedProductPrice');
        currentRef = inputProductFields.value[index].inputField.price.element.querySelector('input');
        nextRef = inputProductFields.value[index].inputField.price.element.querySelector('input');
      }


      /*else if (inputType === 'weightInputRef') {
        currentRef = weightInputRef.value;
        nextRef = submitButtonRef.value;
      } */
      /* else if (inputType === 'submitButtonRef') {
         currentRef = submitButtonRef.value;
       }*/
      // console.log('handleTabNavigation', currentRef, nextRef);
      // Check the value of the input field associated with the currentRef
      /*   if (event && (!currentRef.value || currentRef.value.trim() === '')) {
           event.preventDefault();
           await nextTick();
           nextRef.focus();
         } else {
           await nextTick();
           nextRef.focus();
         }*/


      if (event && (!currentRef.value || currentRef.value.trim() === '')) {
        event.preventDefault();
        await nextTick();
        if (nextRef && nextRef.focus) {
          nextRef.focus();
        }
      } else {
        await nextTick();
        if (nextRef && nextRef.focus) {
          nextRef.focus();
        }
      }


    };


    /*    function setInitialDimensions() {
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          const viewportWidth90 = viewportWidth * 0.9;
          const viewportHeight90 = window.innerHeight * 0.9 * 0.9;

         // console.log('viewportHeight', viewportHeight);
         //   console.log('viewportWidth', viewportWidth);
          // Example logic to set dimensions based on viewport size
          if (viewportWidth < 990) { // 993
             console.log('viewportWidt//h < 990')
            orderModalWindowPosition.value.width = viewportWidth90; // 50% of viewport width

          //  console.log('orderModalWindowPosition.value.width', orderModalWindowPosition.value.width);

            orderModalWindowPosition.value.height = viewportHeight90 > orderModalWindowPosition.value.height
                ? orderModalWindowPosition.value.height
                : viewportHeight90;

            orderModalWindowPosition.value.x =
                (viewportWidth - viewportWidth90) / 2 ;

            // orderModalWindowPosition.value.y = -50;
        //    orderModalWindowPosition.value.y = (viewportHeight - orderModalWindowPosition.value.height) / 2 - 80;

           console.log('orderModalWindowPosition', orderModalWindowPosition.value);

          } else if (viewportWidth > 990 && viewportHeight > 960) {
            // додав бо на фронті ширина невелика
            orderModalWindowPosition.value.width = 900
             console.log('viewportWidth > 990  && viewportHeight > 960')
         //   orderModalWindowPosition.value.y = (viewportHeight - orderModalWindowPosition.value.height) / 2 - 80;

          } else {
             console.log('viewportWidth > 990  && viewportHeight < 960')
            orderModalWindowPosition.value.height = viewportHeight90 > orderModalWindowPosition.value.height
                ? orderModalWindowPosition.value.height : viewportHeight90;

           // orderModalWindowPosition.value.y =  (viewportHeight - orderModalWindowPosition.value.height) / 2 - 80;
            orderModalWindowPosition.value.width = 900

          }


          // ((viewportWidth - orderModalWindowPosition.value.width) / 2) - 30; // 430 - 387 / 2 = 21.5 - 30
          // rderModalWindowPosition.value.x =    (viewportWidth - orderModalWindowPosition.value.width) / 2 ;

          orderModalWindowPosition.value.y = ((viewportHeight - orderModalWindowPosition.value.height) / 2) - 40;

         // orderModalWindowPosition.value.width = 900

         console.log("orderModalWindowPosition.value.width", orderModalWindowPosition.value.width);

        }*/


    /*    function setInitialDimensions() {
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          const viewportWidth90 = viewportWidth * 0.9;
          const viewportHeight90 = viewportHeight * 0.81; // Equivalent to viewportHeight * 0.9 * 0.9

          if (viewportWidth < 990) {
            orderModalWindowPosition.value.width = viewportWidth90;
            orderModalWindowPosition.value.height = Math.min(viewportHeight90, orderModalWindowPosition.value.height);
            orderModalWindowPosition.value.x = (viewportWidth - viewportWidth90) / 2;
          } else if (viewportWidth > 990 && viewportHeight > 960) {
            orderModalWindowPosition.value.width = 900;
          } else {
            orderModalWindowPosition.value.width = 900;
            orderModalWindowPosition.value.height = Math.min(viewportHeight90, orderModalWindowPosition.value.height);
          }

          orderModalWindowPosition.value.y = (viewportHeight - orderModalWindowPosition.value.height) / 2 - 40;

          console.log("orderModalWindowPosition", orderModalWindowPosition.value);
        }*/

    function setInitialDimensions() {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const viewportWidth90 = viewportWidth * 0.9;
      const viewportHeight90 = viewportHeight * 0.81; // 0.9 * 0.9 = 0.81

      if (viewportWidth < 990) {
        // Менше 990px: використовуємо 90% ширини та динамічну висоту
        orderModalWindowPosition.value.width = viewportWidth90;
        orderModalWindowPosition.value.height = Math.min(viewportHeight90, orderModalWindowPosition.value.height);

      } else {
        // Більше 990px: використовуємо фіксовану ширину
        orderModalWindowPosition.value.width = 900;

        if (viewportHeight < 960) {
          // Висота менша за 960px: обмежуємо висоту
          orderModalWindowPosition.value.height = Math.min(viewportHeight90, orderModalWindowPosition.value.height);
        }
      }

      // Центруємо модальне вікно вертикально
      orderModalWindowPosition.value.y = (viewportHeight - orderModalWindowPosition.value.height) / 2 - 40;
      orderModalWindowPosition.value.x = (viewportWidth - viewportWidth90) / 2;
      console.log("orderModalWindowPosition", orderModalWindowPosition.value);
    }


    const itemRefs = ref([]);

    const SeatsInputFields = ref([]);
    const handsPrepare = ref(false);


    const inputProductFields = ref([{

      dropdownItemRefs: [],
      product: {
        weight: 0.1,
        product_id: '',
        article: '',
        name: '',
        quantity: 1,
        price: '',
        unknown_product: 0,
        prices: {
          [selectedPriceTypeId.value]: 0
        },

        height: 0,
        width: 0,
        length: 0,
        image_url: null,
        originalWeight: '',
        needUpdateWeight: false,
        originalHeight: 0,
        originalLength: 0,
        originalWidth: 0,
        totalPrice: '',
        stockQty: '',
        reservedQty: '',
      },
      inputField: {
        article: {
          element: null,
          editable: true,
          isFocused: false,
          isFocusWithinDropdown: false,
          isProductNameDropdownVisible: false,
        },
        name: {
          element: null,
          editable: true,
          isFocused: false,
          isFocusWithinDropdown: false,
          isProductNameDropdownVisible: false,
        },
        qty: {
          element: null,
          editable: true,
          isFocused: false,
        },
        price: {
          element: null,
          editable: true,
          isFocused: false,
        },
        weight: {
          element: null,
          editable: true,
          isFocused: false,
        },
      }
    }
    ]);


    const addProduct = () => {
      inputProductFields.value.push({
        dropdownItemRefs: [],
        product: {
          product_id: '',
          article: '',
          name: '',
          quantity: 1,
          unknown_product: 0,
          price: '',
          prices: {
            [selectedPriceTypeId.value]: 0
          },
          weight: 0.1,
          height: 0,
          width: 0,
          length: 0,
          image_url: null,
          originalHeight: 0,
          originalLength: 0,
          originalWidth: 0,
          originalWeight: '',
          needUpdateWeight: false,
          totalPrice: '',
          stockQty: '',
          reservedQty: '',
        },
        inputField: {
          article: {
            element: null,
            editable: true,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          name: {
            element: null,
            editable: true,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          qty: {
            element: null,
            editable: true,
            isFocused: false,
          },
          price: {
            element: null,
            editable: true,
            isFocused: false,
          },
          weight: {
            element: null,
            editable: true,
            isFocused: false,
          },
        }
      });
      lastSearchValueLengths.value['productName'] = 0;
      needToUpdate.productsData = true;

      //  handleTabNavigation(null, 'WarehouseInputRef');
    };


    // weight Localizer


    // END weight Localizer


    const removeProduct = (index) => {


      currentProductIndex.value = 0;
      inputProductFields.value.splice(index, 1);

      needToUpdate.productsData = true;
    };

    const sendMessageResponse = ref(null);


    const sendMessage = async () => {
      console.log(' sendMessage');
      let message = {
        sms_body: smsTextArea.value,
        phone: buyerPhone.value,
        order_id: crmOroderId.value,
      };

      if (message) {
        try {
          let data = await sendSMS(message);
          console.log('message', data);
          store.commit('alert/setAlertState', {
            show: 3000,
            variant: data.status,
            message: data.message
          });
        } catch (error) {
          console.log('Error sending message:', error);
        }
      }
    };

    const closeAlert = () => {
      store.commit('alert/setAlertState', {show: false});
    };


    async function sendSMS(message) {
      try {
        const response = await store.dispatch('order/sendSms', message);
        // sendMessageResponse.value = response;
        smsTextArea.value = '';
        showSMSDropdown.value = false;
        return response;
      } catch (error) {
        console.log('помилка відправки повідомлення', error);
        throw error;
      }
    }

    const addSeatAmount = () => {
      seatsAmounts.value.push({
        id: crmOroderId.value.toString() + seatsAmounts.value.length.toString(),
        order_id: crmOroderId.value,
        seats_amount: 1,
        weight: npDefaultWeight.value,
        height: 0,
        width: 0,
        length: 0,
        volume: "0.0004",
        np_pack_list_id: '',
        np_pack_list_name: '',
      });
      SeatsInputFields.value.push(null);

      needToUpdate.seatsAmounts = true;

    };

    const removeCurrentSeatAmount = (index) => {
      seatsAmounts.value.splice(index, 1);
      SeatsInputFields.value.splice(index, 1);
      needToUpdate.seatsAmounts = true;
    };


    const handleButtonClick = (type) => {

      smsTemplates.value = JSON.parse(localStorage.getItem('sms_templates'));
      if (type === 'SMS') {

        showSMSDropdown.value = !showSMSDropdown.value;
      } else {
        showSMSDropdown.value = false;
      }
      console.log('smstemplates', smsTemplates.value);
    };

    const selectBuyerDropType = (value) => { // Покупець, Постачальник, Дропшипер
      selectedBuyerDropTypeId.value = value;
      setBuyerDropPlaceholder(value);
      otherReceiverChecked.value = true;
    };
    const nalozhkaTrue = ref(false);
    const dropdownVariant = computed(() => {
      return nalozhkaTrue.value ? 'success' : 'outline-secondary';
    });
    const CounterpartyType = ref('фіз.о');
    const showBadge = ref(false);
    const deliveryTechnologyPlaceholder = ref('Введіть номер відділення');

    const formattedOrderDate = computed(() => {
          return orderDate.value ? 'від ' + orderDate.value : '';
        }
    );


    const clearBuyerName = () => {
      buyerName.value = '';
      buyerId.value = '';
      inputNameStatus.value = false;
      buyerNameIsEditable.value = true;
      needToUpdate.buyerData = true;
      handleTabNavigation(null, 'buyerName');
    };

    const clearBuyerPhone = () => {
      buyerPhone.value = '';
      buyerPhoneIsNotEditable.value = false;
      buyerId.value = '';
      needToUpdate.buyerData = true;
      handleTabNavigation(null, 'buyerNameRef');
    };

    const clearDeliveryCity = () => {
      selectedCity.name = '';
      selectedCity.id = '';
      DeliveryWarehouse.name = '';
      DeliveryWarehouse.id = '';
      needToUpdate.deliveryData = true;
      addressChangedBySender.value = true;
      console.log('deliveryData changed 5');
      handleTabNavigation(null, 'buyerPhoneRef');
    }
    const clearDeliveryWarehouse = () => {
      DeliveryWarehouse.name = '';
      DeliveryWarehouse.id = '';
      needToUpdate.deliveryData = true;
      console.log('deliveryData changed 6');
      handleTabNavigation(null, 'CityInputRef');
    }

    const clearPackingNameAndId = (index) => {
      seatsAmounts.value[index]['np_pack_list_name'] = '';
      seatsAmounts.value[index]['np_pack_list_id'] = '';
    }


    const buyerDropTypes = {
      1: {id: 1, name: 'Покупець'},
      2: {id: 2, name: 'Постачальник'},
      3: {id: 3, name: 'Дропшипер'},
    }

    const selectedBuyerDropTypeId = ref(1); //  // Покупець, Постачальник, Дропшипер


    const cashlesTypes = {
      1: {id: 1, name: 'Безготівка'},
      2: {id: 2, name: 'Готівка'},
    };

    //const selectedAddiditionalPaymentType = ref({});
    const selectedCashlesType = ref(cashlesTypes[2]);
    const isAddiditionalOrderSettingsVisible = ref(false);
    const deliveryInfoAssociatedTypes = {
      1: {id: 1, name: 'Нова Пошта', color: 'danger'},
      2: {id: 2, name: 'Укрпошта', color: 'warning'},
      3: {id: 3, name: 'Розетка Дел.', color: 'success'},
      4: {id: 4, name: 'Delivery', color: 'orange'},
      5: {id: 5, name: 'Sat', color: 'light'},
      6: {id: 6, name: 'Самовивіз', color: 'light'},
      7: {id: 7, name: 'Доставка по місту', color: 'light'},
      8: {id: 8, name: 'Інші', color: 'light'},
      9: {id: 9, name: 'Meest', color: 'light'}
    }
    const selectedDeliveryType = ref(deliveryInfoAssociatedTypes[1]); // default value

    const selectDeliveryType = (value) => {
      // console.log('delivery type', value);
      BuyerContactPersonId.value = ''; // TODO краще додати під різних перевізників збереження id, що б не очищати повністю
      // бо типу при виборі перевізника підставляється ід одержувача якщо він є в залежності від типу перевізника
      searchCityResults.value = [];
      searchWarehouseResults.value = [];
      if (value.id === 1) {
        fillNpCityWarehouse(selectedBuyerData.value.buyerData, selectedBuyerData.value.addressIndex);
      } else if (value.id === 2) {
        fillUpCityWarehouse(selectedBuyerData.value.buyerData, selectedBuyerData.value.addressIndex);
      } else {
        selectedCity.name = '';
        selectedCity.id = '';
        DeliveryWarehouse.name = '';
        DeliveryWarehouse.id = '';
        BuyerContactPersonId.value = '';
      }

      // console.log('selectedDeliveryType.value', value);

      selectedDeliveryType.value = value;
      setPlaceholder(value.name);

      needToUpdate.deliveryData = true;

      if (value.id === 1) { // Нова Пошта
        selectSender(defaultNpSender.value);
      } else if (value.id === 2) { // Укрпошта
        selectUpSender(defaultUpSender.value);
      } else {
        paymentTypeNames.value = {
          2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
          4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
          5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
        }
        selectedPaymentType.value = paymentTypeNames.value[2];
      }
      console.log('deliveryData changed 7');
    };

    function fillUpCityWarehouse(buyerData, andressIndex) {
      andressIndex = 0

      //  console.log('buyerData', buyerData);

      if (buyerData && buyerData.ukr_post_contact_recipient && buyerData.ukr_post_contact_recipient.length > 0) {
        BuyerContactPersonId.value = buyerData.ukr_post_contact_recipient[0].id;
        const upIndexes = buyerData.ukr_post_contact_recipient[0].ukr_post_buyer_addresses[andressIndex].ukrposhta_indexes
        selectedCity.name = upIndexes.ukr_poshta_cities.name
        selectedCity.id = upIndexes.ukr_poshta_cities.id
        DeliveryWarehouse.name = upIndexes.urkpost_index_value
        DeliveryWarehouse.id = upIndexes.id
      } else {
        selectedCity.name = '';
        selectedCity.id = '';
        DeliveryWarehouse.name = '';
        DeliveryWarehouse.id = '';
        BuyerContactPersonId.value = '';
      }

    }

    const selectOrderStatus = (value) => {
      selectedOrderStatus.value = value;
      needToUpdate.orderStatus = true;
    };

    const smsTextArea = ref('');
    const textAreaRowsCount = ref(3);
    const dropdownTop = ref(0);
    const dropdownLeft = ref(0);
    const npSenders = ref([]);
    const smsTemplates = ref([]);

    const replacements = {
      '@Sum': totalAmount,
      '@orderNumber': OrderId,
      '@ttn': ttnNumber,
      '@products': '',
      '@name': buyerName,
      '@phone': buyerPhone,
      '@del': ''
    };


    const selectSmsTemplate = (smsTemplateValue) => {
      let resultText = smsTemplateValue.sms_text_value;

      for (let placeholder in replacements) {
        console.log('placeholder', placeholder);

        if (placeholder === '@products') {
          textAreaRowsCount.value = 8;
          let products = '';
          inputProductFields.value.forEach((prod, index) => {
            products += `\n${index + 1}. ${prod.product.name} - ${prod.product.quantity} шт. - ${prod.product.prices[selectedPriceTypeId.value]} грн.`;
          });
          resultText = resultText.replaceAll(placeholder, products);
        } else if (placeholder === '@del') {

          /*
          selectedCity.name
          DeliveryWarehouse.name
          selectedDeliveryType
          */

          resultText = resultText.replaceAll(placeholder, ` ${selectedDeliveryType.value.name}, ${selectedCity.name}, ${DeliveryWarehouse.name}`);


        } else {
          resultText = resultText.replaceAll(placeholder, replacements[placeholder].value);
        }
      }

      smsTextArea.value = resultText;
    };


    const isNonCashSupported = ref(false); // non_cash
    const paymentControl = ref(false); // payment_control
    selectedDeliveryTechnology.value = serviceTypes[3];

    /*    function formatNpSenders(npSendersArray) {
          return npSendersArray.flatMap(sender =>
              sender.contact_persons.map(person => ({
                name: person.name,
                contact_person_id: person.id,
                non_cash: sender.non_cash,
                payment_control: sender.payment_control,
                payment_method_id: person.payment_method_id,
                redelivery_payer: person.types_payers_redelivery_id,
                default_sender: sender.default_sender,
                defaultContactPerson: person.defaultContactPerson,
              }))
          );
        }*/


    /*    const paymentTypeNames = ref({
          2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
          3: {id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати'},
          4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
          5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
        });*/

    selectedPaymentType.value = paymentTypeNames.value[2];

    const selectedSender = ref({});
    const deliverySenders = ref([]);

    const selectSender = (deliverySenderObj) => {
      //  console.log('select sender called', deliverySenderObj);
      //  console.log('defaultUpSender', defaultUpSender.value);
      //  console.log('return money on bank', defaultUpSender?.value['return_money_on_bank']);

      // console.log('select sender called', deliverySenderObj);

      if (deliverySenderObj) { // TODO тут не той що у замовленні!!!
        const paymentMethodId = deliverySenderObj.payment_method_id ?? 2;
        const deliveryPayer = deliverySenderObj.redelivery_payer ?? 4;
        // console.log('deliveryPayer', deliveryPayer);
        selectedSender.value = deliverySenderObj.name;
        SenderContactPersonId.value = deliverySenderObj.contact_person_id;
        isNonCashSupported.value = deliverySenderObj.non_cash;
        paymentControl.value = deliverySenderObj.payment_control;
        selectedSenderWarehouse.value = findDefaultWarehouseId();
        /* if (deliverySenderObj.payment_control === 1
             || defaultUpSender?.value['return_money_on_bank'] === 1) { // Cannot read properties of undefined (reading 'return_money_on_bank')
           paymentTypeNames.value = {
             2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
             3: {id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати'},
             4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
             5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
           }*/

        setPaymentType(deliverySenderObj);

        //selectPaymentType(paymentType)

        /*    if (deliverySenderObj.payment_control === 1 || defaultUpSender?.value?.return_money_on_bank === 1) {
              paymentTypeNames.value = {
                2: { id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ' },
                3: { id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати' },
                4: { id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата' },
                5: { id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)' },
              };
            }
             else {
              //  console.log('paymentMethodId', paymentMethodId);
              paymentTypeNames.value = {
                2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
                4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
                5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
              }
            }*/

        //  console.log('props.modalTitle.modalType', props.modalTitle.modalType);
        //  console.log('selectedPaymentType.value', selectedPaymentType.value);

        if (props.modalTitle.modalType === 'insert' && selectedPaymentType.value?.id !== 4) {
          selectedPaymentType.value = paymentTypeNames.value[paymentMethodId];
        }

        if (selectedPaymentType.value === {}) {
          selectedPaymentType.value = paymentTypeNames.value[paymentMethodId];
        }


        DeliveryPayer.value = DeliveryPayerTypes[deliveryPayer];

        BackwardDeliveryPayer.value = DeliveryPayerTypes[deliveryPayer] ?? 4; // додав, бо невідомо звідки береться 5, яке не існує

        if (!isAutomaticUpdate.value) {
          needToUpdate.deliveryData = true;
          //   console.log('deliveryData changed 8');
        }

      }
    };

    function setPaymentType(deliverySenderObj) {

      //  console.log('deliverySenderObj', deliverySenderObj);
      //  console.log('defaultUpSender', defaultUpSender.value);


      if (deliverySenderObj.payment_control === 1
          || deliverySenderObj.payment_method_id === 3
          || defaultUpSender?.value?.return_money_on_bank === 1) {
        paymentTypeNames.value = {
          2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
          3: {id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати'},
          4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
          //  5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
        };
      } else {
        //  console.log('paymentMethodId', paymentMethodId);
        paymentTypeNames.value = {
          2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
          4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
          //  5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
        }
      }
    }

    const upAddiditional = ref({
      'is_standard': true,
      'up_check_on_delivery': true,
      'fragile': true,
      'bulky': false,
      'fitting_allowed': true,
      'onFailReceiveType': 'RETURN_AFTER_7_DAYS',
      'up_packed_by_sender': false
    });

    const selectUpSender = (deliverySenderObj) => {
      /*
        {
            "id":8, +
            "default_cabinet":1,
            "sender_name":"ФОП Філь Р.І.", +
            "backward_delivery_payer":4, +
            "delivery_payer_id":4, +
            "return_money_on_bank":0, +
            "is_standard":1,+
            "paid_by_recipient":4,

            "fragile":1,
            "bulky":1,
            "fitting_allowed":1,
            "delivery_tehnology":3,
            "check_on_delivery":1,+
            "payment_method_id":2 +
          }
        */


      console.log('deliverySenderObj', deliverySenderObj);

      if (deliverySenderObj) {
        const paymentMethodId = deliverySenderObj.payment_method_id ?? 2;
        upAddiditional.value.payment_method_id = deliverySenderObj.payment_method_id;
        const deliveryPayer = deliverySenderObj.backward_delivery_payer ?? 4;
        upAddiditional.value.deliveryPayer = deliverySenderObj.delivery_payer_id;
        upAddiditional.value.is_standard = deliverySenderObj.is_standard === 1;
        upAddiditional.value.up_check_on_delivery = deliverySenderObj.check_on_delivery === 1;
        upAddiditional.value.fragile = deliverySenderObj.fragile === 1;
        upAddiditional.value.bulky = deliverySenderObj.bulky === 1;
        upAddiditional.value.fitting_allowed = deliverySenderObj.fitting_allowed === 1;
        upAddiditional.value.onFailReceiveType = 'RETURN_AFTER_7_DAYS';
        upAddiditional.value.up_packed_by_sender = deliverySenderObj.packed_by_sender === 1;
        selectedSender.value = deliverySenderObj.sender_name;
        SenderContactPersonId.value = deliverySenderObj.id;
        isNonCashSupported.value = false;
        paymentControl.value = deliverySenderObj.return_money_on_bank;
        selectedSenderWarehouse.value = deliverySenderObj.ukrposhta_index_id;

        //setPaymentType(deliverySenderObj);

        if (deliverySenderObj.payment_method_id === 3) {
          paymentTypeNames.value = {
            2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
            3: {id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати'},
            4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
            //  5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
          }
        } else {
          //  console.log('paymentMethodId', paymentMethodId);
          paymentTypeNames.value = {
            2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
            4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
            //  5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
          }
        }
        if (props.modalTitle.modalType === 'insert' && selectedPaymentType.value?.id !== 4) {
          selectedPaymentType.value = paymentTypeNames.value[paymentMethodId];
        }
        DeliveryPayer.value = DeliveryPayerTypes[deliveryPayer];
        BackwardDeliveryPayer.value = DeliveryPayerTypes[deliverySenderObj.backward_delivery_payer] ?? 4; // додав, бо невідомо звідки береться 5, яке не існує
        if (!isAutomaticUpdate.value) {
          needToUpdate.deliveryData = true;
        }

        if (paymentMethodId === 3) {
          // need check false
          createCheck.value = false;
        }

        console.log('paymentMethodId', paymentMethodId);


      }
    };

    const root = ref(null);
    const validationMessage = ref('');
    const validationMessageDelivery = ref('');
    const inputNameStatus = ref(false);
    const inputDeliveryFioStatus = ref();
    inputDeliveryFioStatus.value = false;

    const validateBuyerName = () => {
      // console.log('buyerName', buyerName.value);
      // console.log('validate buyer name')
      const wordCount = buyerName.value.trim().split(/\s+/).length;
      if (wordCount < 2) {
        validationMessage.value = 'Має бути 2 або 3 слова';
        if (validationMessage.value.length > 0 && inputFocusStates.buyerName) {
          inputNameStatus.value = false;
        }
      } else if (containsLatinCharacters(buyerName.value) === true) {
        validationMessage.value = 'Ім\'я покупця не може містити латинські символи';
        inputNameStatus.value = false;
      } else {
        validationMessage.value = '';
        inputNameStatus.value = true;
      }

      // console.log('inputNameStatus', inputNameStatus.value);

    };

    const validateDeliveryFio = () => {
      if (DeliveryFio.value !== undefined) {
        //   console.log('DeliveryFio.value', DeliveryFio.value);
        const wordCount = DeliveryFio.value.trim().split(/\s+/).length;
        if (wordCount < 2) {
          validationMessage.value = 'Add two words or more';
          if (validationMessageDelivery.value.length > 0 && inputFocusStates.DeliveryFio) {
            inputDeliveryFioStatus.value = false;
          }
        } else {
          validationMessageDelivery.value = '';
          inputDeliveryFioStatus.value = true;
        }
      }
    };

// Usage for buyerName:
// Usage for deliveryFio:
    const isReseted = ref(false);
    const resetModalState = () => {

      // console.log('reset called');

      selectedBuyerDropTypeId.value = 1;

      activeProductTab.value = 0;

      crmOroderId.value = 0;
      isReseted.value = true;
      selectedSenderWarehouse.value = findDefaultWarehouseId();
      currentProductIndex.value = 0;

      OrderId.value = '';  //
      orderDate.value = ''; //
      selectedCashlesType.value = cashlesTypes[2];

      SourceName.value = {
        id: null,
        name: null,
        sourceType: 'from_frontend',
      };

      /* SourceName.value = {
         id: userData.value.source_id ?? null,
         name: userData.value.source_name ? userData.value.source_name : 'from_frontend',
         sourceType: userData.value.source_type ? userData.value.source_type : '',
       }; //

      */

      streetValues.value = {
        name: '',
        building: '',
        flat: '',
        comment: '',
      };

      productAreaType.value = 1;

      textAreaRowsCount.value = 3;
      smsTextArea.value = '';
      showSMSDropdown.value = false;
      firstFullOrderData = null;
      addressChangedBySender.value = false;
      senderUphoneKey.value = ''; //
      usersOrderNumber.value = ''; //
      marketplaceOrderStatus.value = ''; //
      buyerEmail.value = ''; // +
      orderComment.value = ''; // ++
      totalAmount.value = 0; //
      inn.value = '';
      ttnNumber.value = ''; //
      orderIsPaid.value = false; //
      showBadge.value = false; //
      BuyerContactPersonId.value = ''; //
      paymentType.value = ''; //
      paymentStatus.value = {}; //
      selectedPaymentType.value = {}; //
      selectedDeliveryType.value = deliveryInfoAssociatedTypes[1]; //
      selectedDeliveryTechnology.value = serviceTypes[3]; //
      DeliveryPayer.value = DeliveryPayerTypes[4].name; //
      BackwardDeliveryPayer.value = DeliveryPayerTypes[4].name; //
      urikChecked.value = false; //
      isAutomaticUpdate.vaue = false
      otherReceiverChecked.value = false; //
      showSeatsDetalsParams.value = false; //
      DeliveryFio.value = '';
      DeliveryPhone.value = '';
      buyerName.value = '';
      inputNameStatus.value = false;
      buyerPhone.value = '';
      buyerPhoneIsNotEditable.value = true;
      buyerNameIsEditable.value = true;
      DeliveryFioIsEditable.value = true;
      ProductNameIsEditable.value = true;
      buyerId.value = '';
      selectedCity.name = '';
      selectedCity.id = '';
      DeliveryWarehouse.name = '';
      DeliveryWarehouse.id = '';
      PartialPayment.value = false;
      prePaidAmount.value = 0.00;
      inputProductFields.value = [{
        dropdownItemRefs: [],
        product: {
          product_id: '',
          article: '',
          name: '',
          unknown_product: 0,
          quantity: 1,
          price: '',
          prices: {
            [selectedPriceTypeId.value]: 0
          },
          weight: 0.1,
          height: 0,
          width: 0,
          length: 0,
          originalWeight: '',
          originalHeight: 0,
          originalLength: 0,
          originalWidth: 0,
          needUpdateWeight: false,
          totalPrice: '',
          stockQty: '',
          reservedQty: '',
        },
        inputField: {
          article: {
            element: null,
            editable: true,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          name: {
            element: null,
            editable: true,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          qty: {
            element: null,
            editable: true,
            isFocused: false,
          },
          price: {
            element: null,
            editable: true,
            isFocused: false,
          },
          weight: {
            element: null,
            editable: true,
            isFocused: false,
          },
        }
      }];

      selectedOrderStatus.value = orderStatuses[2];
      seatsAmounts.value = [{
        id: crmOroderId.value.toString() + seatsAmounts.value.length.toString(),
        order_id: crmOroderId.value,
        seats_amount: 1,
        weight: npDefaultWeight.value,
        height: 0,
        width: 0,
        length: 0,
        volume: "0.0004",
        np_pack_list_name: '',
        np_pack_list_id: '',
      }];
      SeatsInputFields.value = [null];


      props.modalTitle.modalType = 'insert'

      selectSender(defaultNpSender.value);

      selectedBuyerData.value.deliveryType = null;
      selectedBuyerData.value.buyerData = null;
      selectedBuyerData.value.addressIndex = 0;


      return true;
      // isReseted.value = false;
      //  console.log('isReseted', isReseted.value)
    }

    const paymentStatuses = {
      'unpaid': {id: 0, status: 'Не сплачено', color: 'danger'},
      'paid': {id: 1, status: 'Сплачено', color: 'success'},

    };


    const selectDeliveryTechnology = (value) => {
      selectedDeliveryTechnology.value = value;
      console.log('selectedDeliveryTechnology', selectedDeliveryTechnology.value);
      needToUpdate.deliveryData = true;
      addressChangedBySender.value = true;
      // console.log('deliveryData changed 9');
      clearDeliveryCity();
      setDeliveryTehnologyPlaceholder(value.id);
    };

    const selectCashlesType = (value) => {
      selectedCashlesType.value = value;
      needToUpdate.deliveryData = true;
      // console.log('deliveryData changed 10');
    }

    /*    const selectAddiditionalPaymentType = (value) => {
          selectedAddiditionalPaymentType.value = value;
          needToUpdate.deliveryData = true;
        }*/
    const selectDeliveryPayer = (value) => {
      DeliveryPayer.value = value;
      needToUpdate.deliveryData = true;
      // console.log('deliveryData changed 11');
    }


    const selectPaymentType = (value) => {
      //  console.log('value', value)
      //  console.log('selectedPaymentType.value', selectedPaymentType.value)
      if (selectedPaymentType.value !== undefined && value.id !== selectedPaymentType.value.id) {
        showBadge.value = false
      }

      selectedPaymentType.value = value;
      needToUpdate.paymentData = true;
      //  console.log('select payment type', selectedPaymentType.value);
    }


    const saveButtonColor = computed(() => {


      if (selectedPaymentType.value.id === 4
          && orderIsPaid.value === false
          && selectedOrderStatus.value.id === 2
      ) {
        return 'danger'
      } else {
        return 'success'
      }
    })


    function setBuyerDropPlaceholder(value) {
      switch (value) {
        case 'Покупець':
          BuyerDropPlaceholder.value = 'Введіть ФІО покупця';
          break;
        case 'Постачальник':
          BuyerDropPlaceholder.value = 'Введіть назву контрагента';
          break;
        case 'Дропшипер':
          BuyerDropPlaceholder.value = 'Введіть назву контрагента';
          break;
      }
    }

    function setCounterPartyType(value) {
      CounterpartyType.value = value;
    }

    function setDeliveryTehnologyPlaceholder(value) {
      console.log(value)
      switch (value) {
        case 3:
          deliveryTechnologyPlaceholder.value = 'Введіть номер відділення';

          break;
        case 4:
          deliveryTechnologyPlaceholder.value = 'Введіть назву вулиці';

          break;
      }
    }

    function setPlaceholder(value) {
      //  console.log(value)
      switch (value) {
        case 'Нова Пошта':
          placeholderCity.value = 'Введіть місто одержувача';
          // selectedDeliveryType.value = 'Нова Пошта';
          break;
        case 'Укрпошта':
          placeholderCity.value = 'Введіть місто одержувача';
          //  selectedDeliveryType.value = 'Укрпошта';
          break;
        case 'Розетка Del':
          placeholderCity.value = 'Введіть місто одержувача';
          //   selectedDeliveryType.value = 'Розетка Del';
          break;
        case 'Meest':
          placeholderCity.value = 'Немає інтеграції';
          //  selectedDeliveryType.value = 'meest';
          break;

        case 'Delivery':
          placeholderCity.value = 'Немає інтеграції';
          //   selectedDeliveryType.value = 'Delivery';
          break;

        case 'Sat':
          placeholderCity.value = 'Немає інтеграції';
          // selectedDeliveryType.value = 'Sat';
          break;

        case 'Самовивіз':
          placeholderCity.value = 'Немає інтеграції';
          //  selectedDeliveryType.value = 'Самовивіз';
          break;

      }
    }


    const closeModal = () => {
      emit('update:showOrderModal', false);
      resetModalState()
    };

    /* function getPaymentType(order) {
       // console.log('join in getPaymentType', order);
       if (order.is_paid) {
         console.log('join in is paid')
         paymentStatus.value = paymentStatuses.value.paid;
         orderIsPaid.value = true;
         showBadge.value = true;
        // selectedPaymentType.value = paymentTypeNames[4]; // предоплата
         console.log('selectedPaymentType.value', selectedPaymentType.value);
         return {
           name: order?.sourceData?.MarketplacePaymentType ?? paymentTypeNames[4].name,
           id: 4 // предоплата
         }
       } else if
       ([2, 3].includes(order?.PaymentTypeNameId) || order?.editedBySender ) {
         console.log('наложка або редаговано відправником');
         return paymentTypeNames[order?.PaymentTypeNameId];
       } else if (order?.sourceData) { // якщо онлайн оплата тоды показуэмо маркетплейс спосыб оплати
         console.log('join in else')
         paymentStatus.value = {
           id: 0,
           paymentStatus: order?.sourceData?.PaymentStatus,
           color: 'danger',
         };
         showBadge.value = true;
         return {
           name: order?.sourceData?.MarketplacePaymentType ?? paymentTypeNames[4].name,
           id: 4 // предоплата
         };
       }
     }*/

    function getPaymentType(order) {

      if (order?.sourceData?.isOnlinePayment === 1 && !order?.editedBySender) { // якщо онлайн оплата
        if (order.is_paid) {
          orderIsPaid.value = true;
          paymentStatus.value = paymentStatuses.paid;
        } else {
          paymentStatus.value = paymentStatuses.unpaid;
          orderIsPaid.value = false;
        }
        showBadge.value = true;
        // console.log('payment', res)
        return {
          name: order?.sourceData?.MarketplacePaymentType ?? paymentTypeNames.value[4].shortName,
          id: 4, // предоплата
          shortName: order?.sourceData?.MarketplacePaymentType ?? paymentTypeNames.value[4].shortName,
        };
      } else {
        //    console.log('else payment types', paymentTypeNames.value);
        // console.log('payment type name id', order?.PaymentTypeNameId);

        if (order?.PaymentTypeNameId === null) {
          return paymentTypeNames.value[2];
        }

        return paymentTypeNames.value[order?.PaymentTypeNameId];
      }
    }

    // const stockReserved = ref(false);

    const MarketplacePaymentType = ref('');

    function getPackListName(np_pack_list_id) {
      if (StaticPackingTypes.length > 0) {
        return StaticPackingTypes[np_pack_list_id].description
      } else {
        StaticPackingTypes = JSON.parse(localStorage.getItem('packingTypes'));
        return StaticPackingTypes[np_pack_list_id].description;
      }
    }

    const senderUphoneKey = ref('');

// заповнюємо модалку данними з замовлення - редагування
    const deliveryFioIsBuyerFio = ref(false);

// TODO поставив усюди true, бо seatsAmounts не слідкуються зміни, що б не витрачати зараз, поки спростив. треба фосл поставити та слідкувати за місяцми і вагою
    const needToUpdate = {
      buyerData: true,
      deliveryData: true,
      productsData: true,
      orderComment: true,
      paymentData: true,
      orderStatus: true,
      seatsAmounts: true,
    };


    /*    const isOnlyOrderStatusTrue = () => {
          // First, check if orderStatus is true
          if (!needToUpdate['orderStatus']) {
            return false;
          }

          // Then check if all other keys are false
          return Object.keys(needToUpdate).every(key => {
            return key === 'orderStatus' || !needToUpdate[key];
          });
        };*/


    const isOnlyOrderStatusTrue = () => {
      // Check if orderStatus is true
      if (!needToUpdate['orderStatus']) {
        return false;
      }

      // Check if all other keys are false
      let das = Object.keys(needToUpdate).every(key => {
        // Return true if it's the orderStatus key or if the value is false
        return key === 'orderStatus' || !needToUpdate[key];
      });

      console.log('das', das);
      return das;

    };


// TODO selectedCashlesType

    const toggleInputEditability = (inputFieldType, index = 0) => {
      console.log('phoneType', inputFieldType);
      if (inputFieldType === 'buyerPhone' && buyerPhone.value.length === 10) {
        buyerId.value = '';
        buyerPhoneIsNotEditable.value = !buyerPhoneIsNotEditable.value;
        needToUpdate.buyerData = true;
        handleTabNavigation(null, 'buyerNameRef');
      } else if (inputFieldType === 'buyerName') {
        buyerNameIsEditable.value = !buyerNameIsEditable.value;
        needToUpdate.buyerData = true;
        handleTabNavigation(null, 'buyerName');
      } else if (inputFieldType === 'DeliveryFio') {
        DeliveryFioIsEditable.value = !DeliveryFioIsEditable.value;
        needToUpdate.deliveryData = true;
        handleTabNavigation(null, 'deliveryFioRef');
      } else if (inputFieldType === 'contactPersonPhone' && DeliveryPhone.value.length === 10) {
        BuyerContactPersonId.value = '';
        DeliveryPhoneIsEditable.value = !DeliveryPhoneIsEditable.value;
        needToUpdate.deliveryData = true;
        handleTabNavigation(null, 'deliveryPhoneRef');
      } else if (inputFieldType === 'productName') {
        needToUpdate.productsData = true;
        inputProductFields.value[index].inputField.name.editable = true;
        handleTabNavigation(null, 'focusedProduct', index);
      } else if (inputFieldType === 'article') {
        needToUpdate.productsData = true;
        inputProductFields.value[index].inputField.article.editable = true;
        handleTabNavigation(null, 'focusedArticle', index);
      } else if (inputFieldType === 'qty') {
        needToUpdate.productsData = true;
        inputProductFields.value[index].inputField.qty.editable = true;
        handleTabNavigation(null, 'focusedProductQty', index);
      } else if (inputFieldType === 'price') {
        needToUpdate.productsData = true;
        inputProductFields.value[index].inputField.price.editable = true;
        handleTabNavigation(null, 'focusedProductPrice', index);
      }
    };

    /*    deliveryFioRef
        buyerPhoneRef
        CityInputRef
        WarehouseInputRef

        handleTabNavigation(null, 'WarehouseInputRef');
        handleTabNavigation(null, 'ProductNameInputRef');*/


    const isFocusWithinDropdown = ref(false);

    const inputFocusStates = reactive({
      buyerName: false,
      city: false,
      phone: false,
      npWarehouse: false,
      packingName: false,
      DeliveryFio: false,
    });

    const handleFocus = (inputName, index = null) => {
      inputFocusStates[inputName] = true;
      // console.log('handleFocus index', index); // index is 0
      if (inputName === 'productName') {
        inputProductFields.value[index].inputField.name.isFocusWithinDropdown = true;
        inputProductFields.value[index].inputField.name.isFocused = true;
        inputProductFields.value[index].inputField.name.editable = true;

      } else if (inputName === 'article') {
        inputProductFields.value[index].inputField.article.isFocusWithinDropdown = true;
        inputProductFields.value[index].inputField.article.isFocused = true;
        inputProductFields.value[index].inputField.article.editable = true;
      } else if (inputName === 'qty') {
        inputProductFields.value[index].inputField.qty.isFocusWithinDropdown = true;
        inputProductFields.value[index].inputField.qty.isFocused = true;
        inputProductFields.value[index].inputField.qty.editable = true;
      } else if (inputName === 'price') {
        inputProductFields.value[index].inputField.price.isFocusWithinDropdown = true;
        inputProductFields.value[index].inputField.price.isFocused = true;
        inputProductFields.value[index].inputField.price.editable = true;


      }
    };

    const handleProductFocus = (index, type) => {
      const inputElement = inputProductFields.value[index].inputField.article.element; // TODO тут можливо name є article

      if (inputElement && typeof inputElement.getBoundingClientRect === 'function') {
        nextTick(() => {
          const rect = inputElement.getBoundingClientRect();
          dropdownTop.value = rect.bottom + window.scrollY;
          dropdownLeft.value = rect.left + window.scrollX;
          //inputFocusStates.productName = true;
          inputProductFields.value[index].inputField.name.isFocused = true;
        });
      }
    };

    const handleBlur = (inputName, index) => {
      setTimeout(() => {
        const dropdownMapping = {
          phone: {isVisible: isPhoneDropdownVisible, results: searchResults},
          buyerName: {isVisible: isNameDropdownVisible, results: searchNameResults},
          DeliveryFio: {isVisible: isDeliveryFioDropdownVisible, results: searchDeliveryFioResults},
          city: {isVisible: isCityDropdownVisible, results: searchCityResults},
          npWarehouse: {isVisible: isWarehouseDropdownVisible, results: searchWarehouseResults},
          packingName: {isVisible: isPackingDropdownVisible, results: searchPackingResults}
        };

        //  console.log('inputName', inputName);
        if (inputName !== 'contactPersonPhone'
            && inputName !== 'productName'
            && inputName !== 'article'
            && inputName !== 'qty'
            && inputName !== 'price'
        ) {
          const dropdownActive = dropdownMapping[inputName]?.isVisible.value && dropdownMapping[inputName].results.value.length > 0;

          if (dropdownActive && !isFocusWithinDropdown.value) {
            // console.log('dropdownActive', dropdownActive);
            inputFocusStates[inputName] = false;
          } else if (!dropdownActive) {
            //  console.log('dropdown not active')
            inputFocusStates[inputName] = false;
          }
        }
        // Reset buyerPhoneIsNotEditable to false if the input losing focus is 'phone'
        if (inputName === 'phone') {
          console.log('phone blur');
          //    buyerPhoneIsNotEditable.value = true;
        } else if (inputName === 'contactPersonPhone') {
          console.log('contactPersonPhone blur');
          DeliveryPhoneIsEditable.value = false;
        } else if (inputName === 'buyerName') {
          buyerNameIsEditable.value = false;
        } else if (inputName === 'DeliveryFio') {
          DeliveryFioIsEditable.value = false;
        } else if (inputName === 'qty') {
          inputProductFields.value[index].inputField.qty.isFocused = false;
          inputProductFields.value[index].inputField.qty.editable = false;
        } else if (inputName === 'price') {
          inputProductFields.value[index].inputField.price.editable = false;
          inputProductFields.value[index].inputField.price.isFocused = false;
          console.log('called unfocus price')
        } else if (inputName === 'productName') {
          let inputValue;
          inputValue = inputProductFields.value[index].inputField.name
          const dropdownActive = inputValue.isProductNameDropdownVisible
          if (dropdownActive && !inputValue.isFocusWithinDropdown) {
            // console.log('dropdown is active');
            inputValue.isFocused = false;
            inputValue.editable = false;
            inputValue.isProductNameDropdownVisible = false;
            inputValue.isFocusWithinDropdown = false;
          } else if (!dropdownActive) {
            inputValue.isFocused = false;
            inputValue.editable = false;
            inputValue.isFocusWithinDropdown = false;
            inputValue.isProductNameDropdownVisible = false;
            //  console.log('dropdown is not active');
          }

        } else if (inputName === 'article') {
          let inputValue;
          inputValue = inputProductFields.value[index].inputField.name
          const dropdownActive = inputValue.isProductNameDropdownVisible
          if (dropdownActive && !inputValue.isFocusWithinDropdown) {
            // console.log('dropdown is active');
            inputValue.isFocused = false;
            inputValue.editable = false;
            inputValue.isProductNameDropdownVisible = false;
            inputValue.isFocusWithinDropdown = false;

            //  inputProductFields.value[index].inputField.name.isFocused = false;
            //  inputProductFields.value[index].inputField.name.isProductNameDropdownVisible = false;


          } else if (!dropdownActive) {
            inputValue.isFocused = false;
            inputValue.editable = false;
            inputValue.isFocusWithinDropdown = false;
            inputValue.isProductNameDropdownVisible = false;
            // inputProductFields.value[index].inputField.name.isFocused = false;
            // inputProductFields.value[index].inputField.name.isProductNameDropdownVisible = false;
            //console.log('dropdown is not active');
          }


          //console.log('productName blur for index ' + currentProductIndex.value + 'is: ' + inputProductFields.value[currentProductIndex.value].editable);
        }
      }, 150); // delay to handle dropdown click
    };


    const handlePackingFocus = (packingNameAtr, seatsAmountValue, packingName, index) => {
      const inputElement = SeatsInputFields.value[index];
      if (inputElement && typeof inputElement.getBoundingClientRect === 'function') {
        nextTick(() => {
          const rect = inputElement.getBoundingClientRect();
          dropdownTop.value = rect.bottom + window.scrollY;
          dropdownLeft.value = rect.left + window.scrollX;
          inputFocusStates.packingName = true;
        });
        // const value = seatsAmounts.value[index]?.name || ''; // Get the value of the currently focused input

        fillSearchPackingResults('packingName', seatsAmountValue, 'packingName', index);
      }
    };

    const searchResults = ref([]);
    const searchNameResults = ref([]);
    const searchDeliveryFioResults = ref([]);
    const searchCityResults = ref([]);
    const searchWarehouseResults = ref([]);
    const searchProductsResults = ref([]);
    const searchPackingResults = ref([])
    const searchReceiverResults = ref([])

    const isPhoneDropdownVisible = ref(false);
    const isNameDropdownVisible = ref(false);
    const isDeliveryFioDropdownVisible = ref(false);
    const isCityDropdownVisible = ref(false);
    const isWarehouseDropdownVisible = ref(false);
    const isPackingDropdownVisible = ref(false);


    const filteredBuyerNameResults = computed(() => {
      return searchNameResults.value.filter(result => isRelevantResult(result.name, buyerName.value));
    });

    const filteredbuyerPhoneResults = computed(() => {

      //  console.log('buyerPhone.value', buyerPhone.value);
      return searchResults.value.filter(result => isRelevantResult(result.phone,
          buyerPhone.value.startsWith('0') ? buyerPhone.value.slice(1) :
              buyerPhone.value
      ));
    });

    const filteredNpCityResults = computed(() => {
      //  console.log('searchCityResults.value', searchCityResults.value);
      return searchCityResults.value.filter(result => isRelevantResult(result.name, selectedCity.name));
    });

    const filteredNpWarehouseResults = computed(() => {
      return searchWarehouseResults.value.filter(result => isRelevantResult(result.name, DeliveryWarehouse.name));
    });


    const filteredProductsResults = computed(() => {
      const currentProductName = inputProductFields.value[currentProductIndex.value]?.product.name || '';

      return searchProductsResults.value.filter(result => isRelevantResult(result.name, currentProductName));
    });


    const filteredDeliveryFioResults = computed(() => {
      return searchDeliveryFioResults.value.filter(result => {
        // Check if the name matches
        const nameMatch = result.counterparty_recipient_full_name.toLowerCase().includes(DeliveryFio.value.toLowerCase());

        // Check if any city name matches
        // TODO
        const cityMatch = result.addresses.some(address => address.city.name.toLowerCase().includes(DeliveryFio.value.toLowerCase()));

        return nameMatch || cityMatch;
      });
    });

    const isRelevantResult = (result, inputValue) => {
      //  console.log('result', result)
      //  console.log('inputValue', inputValue)
      //Convert result to string before checking
      return String(result).toLowerCase().includes(inputValue?.toLowerCase());
    };


    const selectedBuyerData = ref({
      'deliveryType': null,
      'buyerData': null,
      'addressIndex': 0
    });

// це вибір покупця та одержувача з пошуку по імені та телефону з бази данних
    const selectBuyerName = (buyerData, addressIndex = 0) => {
      // console.log('buyerData', buyerData);
      //  console.log('addressIndex', addressIndex);

      selectedBuyerData.value.buyerData = buyerData;
      selectedBuyerData.value.addressIndex = addressIndex;


      buyerName.value = buyerData.name;
      buyerPhone.value = '0' + buyerData.phone;
      buyerId.value = buyerData.id;

      //  console.log('buyerData', buyerData);
      if (buyerData.np_buyer_cont_per_data.length === 1
          //  && buyerData.np_buyer_cont_per_data[0].addresses[addressIndex] > 0
          && buyerData.np_buyer_cont_per_data[0].addresses.length > 0
      ) {
        //   console.log('join in if')

        fillNpCityWarehouse(buyerData, addressIndex)

        //  console.log('selectedCity', selectedCity)
        //  console.log('DeliveryWarehouse', DeliveryWarehouse)
        handleTabNavigation(null, 'WarehouseInputRef')
      } else if (buyerData.np_buyer_cont_per_data.length > 1) {
        //isDeliveryFioDropdownVisible && inputFocusStates.DeliveryFio
        searchDeliveryFioResults.value = buyerData.np_buyer_cont_per_data;
        otherReceiverChecked.value = true;
        isDeliveryFioDropdownVisible.value = true;
        inputFocusStates.DeliveryFio = true;
        handleTabNavigation(null, 'deliveryFioRef')
        // console.log('searchDeliveryFioResults', searchDeliveryFioResults.value)
      }
      isNameDropdownVisible.value = false;
      inputFocusStates.buyerName = false;
      validateBuyerName();
      isPhoneDropdownVisible.value = false;
      inputFocusStates.phone = false;
      buyerPhoneIsNotEditable.value = true;
      // handleTabNavigation(null, 'buyerPhoneRef')
    }

    function fillNpCityWarehouse(buyerData, index) {
      // console.log('buyerData12312312', buyerData)

      //  BuyerContactPersonId.value = buyerData.np_buyer_cont_per_data[index].id;

      if (buyerData && buyerData.np_buyer_cont_per_data && buyerData.np_buyer_cont_per_data.length > 0) {

        /*     DeliveryFio.value = buyerData.np_buyer_cont_per_data[index].counterparty_recipient_full_name;
             DeliveryPhone.value = '0' + buyerData.np_buyer_cont_per_data[index].Phone;
             BuyerContactPersonId.value = buyerData.np_buyer_cont_per_data[index].id
             selectedCity.name = buyerData.np_buyer_cont_per_data[index].addresses[index].city.name
             selectedCity.id = buyerData.np_buyer_cont_per_data[index].addresses[index].city.id
             DeliveryWarehouse.name = buyerData.np_buyer_cont_per_data[index].addresses[index].warehouse.name_ua
             DeliveryWarehouse.id = buyerData.np_buyer_cont_per_data[index].addresses[index].warehouse.id*/

        DeliveryFio.value = buyerData.np_buyer_cont_per_data[0].counterparty_recipient_full_name;
        DeliveryPhone.value = '0' + buyerData.np_buyer_cont_per_data[0].Phone;
        BuyerContactPersonId.value = buyerData.np_buyer_cont_per_data[0].id
        selectedCity.name = buyerData.np_buyer_cont_per_data[0].addresses[index]?.city?.name
        selectedCity.id = buyerData.np_buyer_cont_per_data[0].addresses[index]?.city?.id
        DeliveryWarehouse.name = buyerData.np_buyer_cont_per_data[0].addresses[index]?.warehouse?.name_ua
        DeliveryWarehouse.id = buyerData.np_buyer_cont_per_data[0].addresses[index]?.warehouse?.id


      } else {
        selectedCity.name = '';
        selectedCity.id = '';
        DeliveryWarehouse.name = '';
        DeliveryWarehouse.id = '';
      }

      /*      DeliveryFio.value = buyerData.np_buyer_cont_per_data[0].counterparty_recipient_full_name;
            DeliveryPhone.value = '0' + buyerData.np_buyer_cont_per_data[0].Phone;
            BuyerContactPersonId.value = buyerData.np_buyer_cont_per_data[0].id
            selectedCity.name = buyerData.np_buyer_cont_per_data[0].addresses[index].city.name
            selectedCity.id = buyerData.np_buyer_cont_per_data[0].addresses[index].city.id
            DeliveryWarehouse.name = buyerData.np_buyer_cont_per_data[0].addresses[index].warehouse.name_ua
            DeliveryWarehouse.id = buyerData.np_buyer_cont_per_data[0].addresses[index].warehouse.id*/

      validateDeliveryFio();

    }

    const selectDeliveryFioName = (recever, receiverIndex, addressIndex) => {
      DeliveryFio.value = recever.counterparty_recipient_full_name;
      BuyerContactPersonId.value = recever.addresses[addressIndex].np_buyer_cont_per_data_id;
      DeliveryPhone.value = '0' + recever.Phone;
      selectedCity.name = recever.addresses[addressIndex].city.name;
      selectedCity.id = recever.addresses[addressIndex].city.id;
      DeliveryWarehouse.name = recever.addresses[addressIndex].warehouse.name_ua;
      DeliveryWarehouse.id = recever.addresses[addressIndex].warehouse.id;
      isDeliveryFioDropdownVisible.value = false;
      inputFocusStates.DeliveryFio = false;
      validateDeliveryFio();
      validateBuyerName();
    }

    const selectBuyerPhone = (buyerData) => {
      buyerPhone.value = '0' + buyerData.phone;
      buyerName.value = buyerData.name;
      isPhoneDropdownVisible.value = false;
      inputFocusStates.phone = false;
      buyerPhoneIsNotEditable.value = true;
      handleTabNavigation(null, 'buyerPhoneRef')
    }

    const selectCity = (value) => {
      selectedCity.name = value.name;
      selectedCity.id = value.id;
      // console.log('selectedCity.id', selectedCity.id);
      isCityDropdownVisible.value = false;
      inputFocusStates.city = false;
      handleTabNavigation(null, 'CityInputRef');
    }

    const selectWarehouse = (value) => {
      DeliveryWarehouse.name = value.name_ua;
      DeliveryWarehouse.id = value.id;

      console.log('value', value);
      console.log('selectedCity', selectedCity)

      /*
      {
        "id": 19039,
        "name": "54055",
        "name_ua": "54055",
        "city_id": 12878,
        "city_name": "м. Миколаїв, Миколаївська обл., р.Миколаївський"
      }
      */


      if (selectedCity.name === '') {
        selectedCity.name = value.city_name;
        selectedCity.id = value.city_id;
      }


      isWarehouseDropdownVisible.value = false;
      inputFocusStates.npWarehouse = false;
      addressChangedBySender.value = true
      handleTabNavigation(null, 'WarehouseInputRef');
    }


    function calculatePrices(selectedProduct) {
      const calculatedPrices = {};

      // Проходимося циклом по всіх типах цін в об'єкті pricesByPriceTypeIds
      for (const [priceTypeId, priceObj] of Object.entries(selectedProduct.pricesByPriceTypeIds)) {
        const productPrice = priceObj.price; // Отримуємо ціну продукту
        // Обчислюємо ціну з урахуванням валюти
        calculatedPrices[priceTypeId] = calcPriceWithCurrency(productPrice, selectedProduct.currency_id);
      }

      return calculatedPrices;
    }


    const selectProduct = (selectedProduct, productIndex) => {
      if (typeof productIndex !== 'undefined' && productIndex !== null) {


        // console.log('selectedProduct', selectedProduct)
        /*
                {
            "id": 79752,
            "user_id": 57,
            "name": "тест товар",
            "currency_id": 1,
            "thumbnail_url": null,
            "article": "3242334",
            "price": "200.00",
            "weight": 0.1,
            "length": 0,
            "width": 0,
            "height": 0,
            "stock": {
                "product_id": 79752,
                "stock_qty": 0,
                "reserved_qty": 8,
                "on_return_qty": 0,
                "stock_names": [
                    {
                        "id": 70938,
                        "product_id": 79752,
                        "stock_qty": 0,
                        "reserved_qty": 8,
                        "on_return_qty": 0,
                        "stock_name_id": 10,
                        "stock_names": {
                            "id": 10,
                            "name": "Основний склад",
                            "user_id": 57
                        }
                    }
                ]
            },
            "pricesByPriceTypeIds": {
                "3": {
                    "id": 22945,
                    "product_id": 79752,
                    "price_type_id": 3,
                    "price": "835.00"
                }
            }
        }
        */

        if (!selectedProduct.pricesByPriceTypeIds[selectedPriceTypeId.value]) {
          // Ініціалізуємо об'єкт, якщо він не існує
          selectedProduct.pricesByPriceTypeIds[selectedPriceTypeId.value] = {
            id: null,  // або інше значення, яке має бути за замовчуванням
            product_id: selectedProduct.id,  // встановлюємо product_id, якщо потрібно
            price_type_id: selectedPriceTypeId.value,  // встановлюємо price_type_id
            price: 0  // встановлюємо початкове значення для price
          };
        }

        const calculatedPrices = calculatePrices(selectedProduct);

        //let productPrice = selectedProduct.pricesByPriceTypeIds[selectedPriceTypeId.value].price;

        // inputFocusStates.productName = false;
        inputProductFields.value[productIndex].product = {
          product_id: selectedProduct.id ?? '',
          name: selectedProduct.name,
          article: selectedProduct.article ?? '',
          unknown_product: selectedProduct.unknown_product ?? 0,
          quantity: 1,
          weight: selectedProduct.weight,
          originalWeight: selectedProduct.weight,
          originalLength: selectedProduct.length,
          needUpdateWeight: false,
          originalWidth: selectedProduct.width,
          originalHeight: selectedProduct.height,
          height: selectedProduct.height,
          width: selectedProduct.width,
          image_url: selectedProduct.thumbnail_url ?? null, // TODO: Add image url
          length: selectedProduct.length,
          price: calculatedPrices[selectedPriceTypeId.value] ?? 0,
          prices: calculatedPrices,
          totalPrice: calculatedPrices[selectedPriceTypeId.value] ?? 0,
          stockQty: selectedProduct.stock?.stock_qty ?? 0,
          reservedQty: selectedProduct.stock?.reserved_qty ?? 0,
          // Add other properties if needed
        };


        const productName = inputProductFields.value[productIndex].inputField.name;
        productName.isFocused = false;
        productName.editable = false;
        productName.isProductNameDropdownVisible = false;
        productName.isFocusWithinDropdown = false;
        const article = inputProductFields.value[productIndex].inputField.article;
        article.isFocused = false;
        article.editable = false;
        article.isProductNameDropdownVisible = false;
        article.isFocusWithinDropdown = false;
        const price = inputProductFields.value[productIndex].inputField.price;
        price.isFocused = false;
        price.editable = false;
        const quantity = inputProductFields.value[productIndex].inputField.qty;
        quantity.isFocused = false;
        quantity.editable = false;


        // console.log('inputProductFields.value', inputProductFields.value)
        // console.log('selectedPriceTypeId.value', selectedPriceTypeId.value)

      }


      handleTabNavigation(null, 'ProductNameInputRef', productIndex);

    }

    const handlePaste = (event) => {
      // Prevent the default paste action
      event.preventDefault();

      // Get the pasted data from the clipboard
      let pastedData = event.clipboardData.getData('text');

      // Remove all non-integer characters
      let formattedData = pastedData.replace(/\D/g, '');

      // Take the last 10 integers
      formattedData = formattedData.slice(-10);

      // Set the formatted data to buyerPhone
      buyerPhone.value = formattedData;
    };


    const selectPacking = (selectedPacking, rowIndex) => {
      if (typeof rowIndex !== 'undefined' && rowIndex !== null) {
        seatsAmounts.value[rowIndex] = {
          ...seatsAmounts.value[rowIndex], // Spread the existing values
          np_pack_list_name: selectedPacking.description,
          np_pack_list_id: selectedPacking.id,
          // pack_ref: selectedPacking.ref,
          //  packaging_for_place: selectedPacking.packaging_for_place,
        };
      }
      isPackingDropdownVisible.value = false;
      inputFocusStates.packingName = false;
    }


    const setItemRef = (el, index) => {
      if (el) {
        itemRefs.value[index] = el;
      }
    }


    const setMultipleItemRef = (el, dropdownIndex, inputFieldIndex) => {
      if (el) {
        inputProductFields.value[inputFieldIndex].dropdownItemRefs[dropdownIndex] = el;
      }
    }

    const combinedItemRefs = ref([]);
    const setCombinedItemRef = (el, outerIndex, innerIndex) => {
      if (!combinedItemRefs.value[outerIndex]) {
        combinedItemRefs.value[outerIndex] = [];
      }
      combinedItemRefs.value[outerIndex][innerIndex] = el;
    }

    const handleCombinedInputKeydown = (event, dropdownVisible, searchResults) => {
      if (dropdownVisible) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault(); // Prevent default behavior
            if (combinedItemRefs.value[0] && combinedItemRefs.value[0][0]) {
              combinedItemRefs.value[0][0].focus();
            }
            break;
          case 'ArrowUp':
            event.preventDefault(); // Prevent default behavior
            const lastResultIndex = searchResults.length - 1;
            const lastAddressIndex = combinedItemRefs.value[lastResultIndex].length - 1;
            if (combinedItemRefs.value[lastResultIndex] && combinedItemRefs.value[lastResultIndex][lastAddressIndex]) {
              combinedItemRefs.value[lastResultIndex][lastAddressIndex].focus();
            }
            break;
        }
      }
    };

    const handleCombinedKeydown = (event) => {
      let currentOuterIndex, currentInnerIndex;

      for (let i = 0; i < combinedItemRefs.value.length; i++) {
        const innerIndex = combinedItemRefs.value[i].indexOf(event.target);
        if (innerIndex !== -1) {
          currentOuterIndex = i;
          currentInnerIndex = innerIndex;
          break;
        }
      }

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (currentInnerIndex < combinedItemRefs.value[currentOuterIndex].length - 1) {
            combinedItemRefs.value[currentOuterIndex][currentInnerIndex + 1].focus();
          } else if (currentOuterIndex < combinedItemRefs.value.length - 1) {
            combinedItemRefs.value[currentOuterIndex + 1][0].focus();
          }
          break;

        case 'ArrowUp':
          event.preventDefault();
          if (currentInnerIndex > 0) {
            combinedItemRefs.value[currentOuterIndex][currentInnerIndex - 1].focus();
          } else if (currentOuterIndex > 0) {
            combinedItemRefs.value[currentOuterIndex - 1][combinedItemRefs.value[currentOuterIndex - 1].length - 1].focus();
          }
          break;

          /*      case 'Enter':
                case 'Tab':
                  // Handle selection logic here
                  break;*/
      }
    };

    const handleInputKeydown = (event, dropdownVisible, searchResults) => {

      if (dropdownVisible) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            nextTick(() => {
              //  console.log('join in if itemRefs.value[0]')
              if (itemRefs.value[0]) {
                itemRefs.value[0].focus();
              }
            });
            break;
          case 'ArrowUp':
            event.preventDefault(); // Prevent default behavior
            if (itemRefs.value[searchResults.length - 1]) {
              itemRefs.value[searchResults.length - 1].focus();
            }
            break;
        }
      }
    };


    const itemBuyerNameRefs = ref([]); // Initialize an empty array to store references

    const setBuyerNameItemRef = (el, buyerIndex, addressIndex) => {
      if (el) {
        if (!itemBuyerNameRefs.value[buyerIndex]) {
          itemBuyerNameRefs.value[buyerIndex] = [];
        }
        itemBuyerNameRefs.value[buyerIndex][addressIndex] = el;
        // console.log('itemBuyerNameRefs', itemBuyerNameRefs.value)
      }
    }


    const handleBuyerNameKeydown = (event, buyerIndex, addressIndex) => {
      //  console.log('itemBuyerNameRefs', itemBuyerNameRefs.value)
      //  console.log('buyerIndex', buyerIndex)
      //  console.log('addressIndex', addressIndex)
      const totalLength = itemBuyerNameRefs.value[buyerIndex].length;
      //  console.log('totalLength', totalLength)

      switch (event.key) {
          /*case 'ArrowDown':
            event.preventDefault();
            nextTick(() => {
            if (addressIndex < totalLength - 1) {
              itemBuyerNameRefs.value[buyerIndex][addressIndex + 1].focus();
            }
            });
            break;*/
        case 'ArrowDown':
          event.preventDefault();
          nextTick(() => {
            if (addressIndex < totalLength - 1) {
              itemBuyerNameRefs.value[buyerIndex][addressIndex + 1].focus();
            } else {
              // Navigate to the first address of the next buyer
              const nextBuyerIndex = buyerIndex + 1;
              if (itemBuyerNameRefs.value[nextBuyerIndex] && itemBuyerNameRefs.value[nextBuyerIndex].length > 0) {
                itemBuyerNameRefs.value[nextBuyerIndex][0].focus();
              }
            }
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (addressIndex === 0) {
            // Navigate to the last address of the previous buyer
            const prevBuyerIndex = buyerIndex - 1;
            if (prevBuyerIndex >= 0 && itemBuyerNameRefs.value[prevBuyerIndex]) {
              const lastAddressIndex = itemBuyerNameRefs.value[prevBuyerIndex].length - 1;
              if (lastAddressIndex >= 0) {
                itemBuyerNameRefs.value[prevBuyerIndex][lastAddressIndex].focus();
              }
            }
          } else {
            itemBuyerNameRefs.value[buyerIndex][addressIndex - 1].focus();
          }
          break;
      }
    };


    const handleBuyerNameInputKeydown = (event, dropdownVisible) => {

      if (dropdownVisible) {
        const itemRefs = itemBuyerNameRefs.value[0];
        //  console.log('itemRefs', itemRefs)
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            nextTick(() => {
              //    console.log('join in if itemRefs.value[0]')
              if (itemRefs[0]) {
                itemRefs[0].focus();
              }
            });
            break;
            /*case 'ArrowUp':
              event.preventDefault();
              if (itemRefs[searchResults.length - 1]) {
                itemRefs[searchResults.length - 1].focus();
              }
              break;*/
        }
      }
    };


    const handleKeydown = (event, totalLength) => {


      // console.log('itemRefs.value', itemRefs.value)
      // console.log('totalLength', totalLength)

      let currentIndex = itemRefs.value.findIndex((ref) => ref === event.target);
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault(); // Prevent default behavior
          if (currentIndex < totalLength - 1) {
            itemRefs.value[currentIndex + 1].focus();
          }
          break;
        case 'ArrowUp':
          event.preventDefault(); // Prevent default behavior
          if (currentIndex > 0) {
            itemRefs.value[currentIndex - 1].focus();
          }
          break;
          /*  case 'Enter':
            case 'Tab':
              // Handle selection logic here
              break;*/
      }
    };

    const handleProductInputKeydown = (event, dropdownVisible, searchResults, currentIndex) => {

      if (dropdownVisible) {
        let itemRefs = inputProductFields.value[currentIndex].dropdownItemRefs;
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            nextTick(() => {
              //    console.log('join in if itemRefs.value[0]')
              if (itemRefs[0]) {
                itemRefs[0].focus();
              }
            });
            break;
          case 'ArrowUp':
            event.preventDefault();
            if (itemRefs[searchResults.length - 1]) {
              itemRefs[searchResults.length - 1].focus();
            }
            break;
        }
      }
    };

    const handleProductKeydown = (event, totalLength, currentIndex) => {


      let itemRefs = inputProductFields.value[currentIndex].dropdownItemRefs;
      let currentFocusedIndex = itemRefs.findIndex((ref) => ref === event.target);
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (currentFocusedIndex < totalLength - 1) {
            itemRefs[currentFocusedIndex + 1]?.focus();
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (currentFocusedIndex > 0) {
            itemRefs[currentFocusedIndex - 1]?.focus();
          }
          break;
          /*  case 'Enter':
            case 'Tab':
              // Handle selection logic here
              break;*/
      }
    };

    /*    const handleKeydown = (event, index, length) => {
          switch (event.key) {
            case 'ArrowDown':
              // console.log('ArrowDown');
              event.preventDefault(); // Prevent default behavior
              if (index < length - 1) {
                itemRefs.value[index + 1].focus();
              }
              break;
            case 'ArrowUp':
              //  console.log('ArrowUp');
              event.preventDefault(); // Prevent default behavior
              if (index > 0) {
                itemRefs.value[index - 1].focus();
              }
              break;
            case 'Enter':
            case 'Tab':
              // Handle selection logic here
              break;
          }
        };*/


    const lastSearchValues = ref({
      phone: '',
      name: '',
      city: '',
      npWarehouse: '',
      productName: ''
    });

// Also, define an object to store the last search results count for each type
    const lastSearchResultsCount = ref({
      phone: null,
      name: null,
      city: null,
      npWarehouse: null,
      productName: null
    });

    const lastSearchValueLengths = ref({
      phone: 0,
      name: 0,
      city: 0,
      npWarehouse: 0,
      productName: 0
    });


    const debounseTiming = ref(300);

    const search = debounce(async (type, value, debounseTime, InputIndex = null) => {
      //  console.log('search function called')
      //  console.log('type', type);
      //  console.log('search value', value)
      debounseTiming.value = debounseTime ?? 300;
      if (!value) {
        return;
      }
      let DropdownStatus = false;
      let queryurl = '';
      let cachePeriod = '5s';


      if (type === 'phone') {
        queryurl = 'search-buyers?phone=' + value;
        cachePeriod = '5s';
        DropdownStatus = isPhoneDropdownVisible.value
      } else if (type === 'name') {
        queryurl = 'search-buyers?name=' + value;
        cachePeriod = '5s';
        DropdownStatus = isNameDropdownVisible.value
        /* } else if (type === 'city') {
           queryurl = 'cities?city_name=' + value;
           DropdownStatus = isCityDropdownVisible.value
         } else if (type === 'npWarehouse') {
           queryurl = `cities/${selectedCity.id}/warehouses/` + value;
           DropdownStatus = isWarehouseDropdownVisible.value
         }*/

      } else if (type === 'city') {

        if (selectedDeliveryType.value.id === 1) { // нова пошта

          console.log('nova poshta selected')
          if (selectedDeliveryTechnology.value.id === 3) { // відділення

            console.log('warehouse');
            queryurl = 'cities?city_name=' + value;
          } else if (selectedDeliveryTechnology.value.id === 4) { // адресна доставка
            queryurl = 'cities?settlement_name=' + value;
            console.log('settlement');
          }
          DropdownStatus = isCityDropdownVisible.value
        } else if (selectedDeliveryType.value.id === 2) { // укрпошта
          // console.log('call search by up city name')
          queryurl = 'cities?up-city-name=' + value;
          DropdownStatus = isCityDropdownVisible.value
        }

        cachePeriod = '7d';

      } else if (type === 'npWarehouse') {
        if (selectedDeliveryType.value.id === 1) { // нова пошта
          queryurl = `cities/${selectedCity.id}/warehouses/` + value;
          cachePeriod = '7d';
          DropdownStatus = isWarehouseDropdownVisible.value
        } else if (selectedDeliveryType.value.id === 2) { // укрпошта
          // console.log('call search by up index ', value)
          cachePeriod = '7d';
          queryurl = `get-up-warehouse?index-value=` + value;
          DropdownStatus = isWarehouseDropdownVisible.value
        }


      } else if (type === 'productName') {
        queryurl = `search-products?name=` + value;
        cachePeriod = '5s';
        DropdownStatus = inputProductFields.value[InputIndex].inputField.name.isProductNameDropdownVisible // TODO перевірити чи true тут у момент використання
      } else if (type === 'article') {
        queryurl = `search-products?article=` + value;
        cachePeriod = '5s';
        DropdownStatus = inputProductFields.value[InputIndex].inputField.article.isProductNameDropdownVisible
      } else if (type === 'packingName') {
        queryurl = `np-packings`;
        cachePeriod = '7d';
        DropdownStatus = isPackingDropdownVisible.value
      }

      //  console.log('DropdownStatus', DropdownStatus);

      //  console.log('lastSearchResultsCount.value[type]', lastSearchResultsCount.value[type]);

      if (DropdownStatus === true && lastSearchResultsCount.value[type] < 15
          && value.startsWith(lastSearchValues.value[type])) {
        //  console.log('dropdown status', DropdownStatus);
        //  console.log('lastSearchResultsCount', lastSearchResultsCount.value[type]);
        //  console.log('searchType', type);
        //  console.log('last search values', lastSearchValues.value[type]);
        //  console.log('Поточний пошук є лише розширенням останнього пошуку. Пропуск запиту.');
        return;
      }

      if (value.startsWith(lastSearchValues.value[type])
          && lastSearchResultsCount.value[type] === 0) {
        //  console.log('пропуск бо нема результатів');
        return;
      }

      /*    if (lastSearchResultsCount.value[searchType] === 1 && value.startsWith(lastSearchValues.value[searchType])
              && DropdownStatus === true
          ) {
            console.log('Пропуск пошуку, оскільки попередній пошук дав один результат і користувач продовжує вводити дані.');
            return;
          }*/

      /*      if(value.length === '') {
              lastSearchValueLengths.value[searchType] = 0;
            }*/


      // TODO НЕ ВИДАЛЯТИ те що нижче, а пропрацювати "ТЕ ЩО ВИДАЛЕНО", що б нормально працювало
      /*  if (value.length < lastSearchValueLengths.value[type] && value.length !== 1) {
          console.log('value.length', value.length);
          console.log('old value length пропуск бо видалення символів', lastSearchValueLengths.value[type]);
          lastSearchValueLengths.value[type] = value.length; // Update the length
          return;
        }*/

      // console.log('value', value)
      if (typeof value === 'undefined' || value === 'undefined') {
        console.error('Value is undefined. Not sending request.');
        return;
      }

      // const prevResultsCount = lastSearchResultsCount.value[type];

      try {

        let searchResponse = await getSearchResults(queryurl, cachePeriod);
        // console.log('search response', searchResponse);
        lastSearchValues.value[type] = value;

        lastSearchValueLengths.value[type] = value.length; // Update the length
        /*
                if (currentResultsCount < prevResultsCount && DropdownStatus === true) {
                  console.log('Пошук дав менше результатів ніж попереднього разу. Пропускаємо.');
                  return;
                }
        */

        lastSearchResultsCount.value[type] = searchResponse.length;
        if (type === 'phone') {
          searchResults.value = searchResponse;
          isPhoneDropdownVisible.value = searchResults.value.length > 0;
        } else if (type === 'name') {

          // console.log('name response', searchResponse)

          searchNameResults.value = searchResponse;
          isNameDropdownVisible.value = searchNameResults.value.length > 0;
        } else if (type === 'city') {
          if (searchResponse.length === 1) {
            selectCity(searchResponse[0]);
          } else {
            console.log('city response', searchResponse);
            searchCityResults.value = searchResponse;
            isCityDropdownVisible.value = searchCityResults.value.length > 0;
          }
        } else if (type === 'npWarehouse') {
          if (searchResponse.length === 1) {
            selectWarehouse(searchResponse[0]);
          } else {
            // console.log('warehouse response', searchResponse);
            searchWarehouseResults.value = searchResponse;
            isWarehouseDropdownVisible.value = searchWarehouseResults.value.length > 0;
          }
        } else if (type === 'productName' || type === 'article') {
          //  console.log('response.data', searchResponse);
          searchProductsResults.value = searchResponse;
          inputProductFields.value[InputIndex].inputField.name.isProductNameDropdownVisible = searchProductsResults.value.length > 0;
          handleProductFocus(InputIndex, type);
        } else if (type === 'packingName') {
          searchPackingResults.value = searchResponse;
          localStorage.setItem('packingTypes', JSON.stringify(searchResponse));
          isPackingDropdownVisible.value = searchPackingResults.value.length > 0;
        }
        // console.log('searchPackingResults.value', searchPackingResults.value);
      } catch (e) {
        console.error(e);
      }
    }, debounseTiming.value);

// Helper function to check for Latin characters
    const containsLatinCharacters = (str) => /[a-zA-Z]/.test(str);

    function prepareSeatsAmounts() {
      const seatsAmountsEdited = [];
      seatsAmounts.value.forEach((item, index) => {
        //  console.log('item', item);
        if (item) {
          // Create a shallow copy of the item
          const itemCopy = {...item};
          // Delete the property from the copy
          delete itemCopy.np_pack_list_name;
          // Push the modified copy to the new array
          seatsAmountsEdited.push(itemCopy);
        }
      });
      return seatsAmountsEdited;
    }

    function getSeatsAmounts() {
      //  console.log('seatsAmounts.value', seatsAmounts.value)
      if (seatsAmounts.value.length > 0) {
        return prepareSeatsAmounts();
      } else {
        addSeatAmount();
        return prepareSeatsAmounts();
      }
    }


    function getCounterpartyRecipientFullName() {
      if (!otherReceiverChecked.value) {
        return buyerName.value;
      } else {
        return DeliveryFio.value;
      }
    }

    function getCounterpartyRecipientPhone() {
      if (!otherReceiverChecked.value) {
        if (buyerPhone.value !== DeliveryPhone.value) {
          BuyerContactPersonId.value = null;
        }
        return parseInt(buyerPhone.value, 10);
      } else {
        return parseInt(DeliveryPhone.value, 10);
      }
    }

    const changeOrderStatus = async (crmOrderId, orderStatusId) => {
      const requestChangeStatus = {
        'ordersIds': [crmOrderId],
        'orderStatusId': orderStatusId,
      }

      let response = await sendChangeOrderStatuses(requestChangeStatus);
      console.log('change statuses response', response);
    }

    async function sendChangeOrderStatuses(requestChangeStatus) {
      try {
        return await store.dispatch('order/changeOrderStatuses', requestChangeStatus);
      } catch (error) {
        console.log('помилка зміни статусів', error);
        throw error;
      }
    }

    function getCurrentDateTime() {
      const now = new Date();

      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth returns 0-11
      const day = String(now.getUTCDate()).padStart(2, '0');
      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      const seconds = String(now.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000Z`;
    }

    const fillFrontOrder = () => {
      return {
        'crm_order_id': crmOroderId.value,
        // else date in format 2024-01-18T10:50:56.000000Z
        'date': originalDate ? originalDate : getCurrentDateTime(),
        'savingType': props.modalTitle.modalType,
        'deliveryInfoAssociatedType': selectedDeliveryType.value.id,

        'packing_status': false,
        'UsersOrderNumber': usersOrderNumber.value,
        'CrmOrderStatus': selectedOrderStatus.value.name,
        'CrmOrderStatusId': selectedOrderStatus.value.id,
        'orderComment': orderComment.value ?? null,
        'dontCall': dontCall.value,
        'BuyerId': buyerId.value,
        'BuyerName': buyerName.value,
        'BuyerPhone': parseInt(buyerPhone.value, 10),
        'BuyerEmail': buyerEmail.value,
        'full_info_id': fullInfoId.value,
        'total_amount': totalAmount.value,
        'is_paid': orderIsPaid.value === true ? 1 : 0,
        'prePaidAmount': prePaidAmount.value,
        'PaymentTypeNameId': selectedPaymentType.value.id,
        'PaymentTypeName': selectedPaymentType.value.name,
        'sourceData': {
          'source_type': SourceName.value.sourceType,
          'OrderNumber': OrderId.value,
          'MarketplacePaymentType': needToUpdate.paymentData === true ? selectedPaymentType.value.name : MarketplacePaymentType.value,
          'SourceName': SourceName.value.name,
          'PaymentStatus': paymentStatus.value.status ? paymentStatus.value.status : totalAmount.value - prePaidAmount.value + ' грн.', //
          'MarketplaceOrderStatus': marketplaceOrderStatus.value
        },
        'deliveryData': {
          'PostName': selectedDeliveryType.value.name,
          'sender_warehouse_id': selectedSenderWarehouse.value,
          'BuyerContactPersonId': BuyerContactPersonId.value ?? null,
          'DeliveryFio': DeliveryFio.value,
          'DeliveryPhone': parseInt(DeliveryPhone.value, 10),
          'DeliveryCity': selectedCity.name,
          'DeliveryCityId': selectedCity.id,
          'DeliveryWarehouse': DeliveryWarehouse.name,
          'DeliveryWarehouseId': DeliveryWarehouse.id,
          'SenderName': selectedSender.value,
          'SenderId': SenderContactPersonId.value,
          'ServiceTypeId': selectedDeliveryTechnology.value.id,
          'DeliveryType': selectedDeliveryTechnology.value.name,
          'DeliveryStatus': trackingStatus.value,
          'deliveryPayerId': DeliveryPayer.value.id,
          'backwardDeliveryPayer': BackwardDeliveryPayer.value.id,
          'paymentFormId': selectedCashlesType.value.id,
          //KeepDays: KeepDays.value,
          'TtnNumber': ttnNumber.value,

          // 'BackDeliveryStatus': trackingStatus.value,
          //  'TtnPrintedStatus': TtnPrintedStatus.value,
          //  'BackTtnNumber': BackTtnNumber.value,
          //  'BackTtnStatus': BackTtnStatus.value,
          //  'ReestrNumber': ReestrNumber.value,
          //  'ReestrPrintedStatus': ReestrPrintedStatus.value
        },


        'products': getProductsForFront(),
        'seatsAmounts': getSeatsAmounts(),
        'senderUphoneKey': senderUphoneKey.value ?? null,
      }
    }

    function getComment() {
      if (orderForUpdate.orderComment !== undefined && orderForUpdate.orderComment !== orderComment.value) {
        //  console.log('orderForUpdate.orderComment', orderForUpdate.orderComment);
        //  console.log('orderComment.value', orderComment.value);
        //  console.log('comment was changed')
        needToUpdate.paymentData = true;
      }
      return orderComment.value ?? null;
    }

    function getTtnNumber(ttnNumber, deliveryType) {
      //  console.log('ttnNumber', ttnNumber); // ttnNumber 20450931087307
      //  console.log('deliveryType', deliveryType); // 1
      //  console.log('ttnNumber.length', ttnNumber.length); // why here is undefined?

      const ttnNumberStr = ttnNumber?.toString();

      if (deliveryType === 1) {
        return ttnNumberStr?.length === 14 ? ttnNumberStr : '';
      } else if (deliveryType === 2
          || deliveryType === 3) {
        return ttnNumberStr?.length === 13 ? ttnNumberStr : '';
      }
    }

    const spinnerStatuses = ref({
      saveOrder: false,
      isSpinnerTextVisible: false,
      spinnerText: ''
    })

    function getDefaultSenderByDeliveryType(deliveryType) {
      if (deliveryType === 1) {
        return defaultNpSender.value.contact_person_id
      } else if (deliveryType === 2) {
        return defaultUpSender.value.id
      } else {
        return null
      }
    }


// повністю вийти з функції якщо alert
    const saveOrder = (event) => {

      event.preventDefault()
      if (selectedDeliveryType.value.id === 1) {
        if (buyerName.value.length === 0) {
          alert('Поле "Ім\'я покупця" не може бути пустим');
          return;
        } else if (buyerPhone.value.length === 0) {
          alert('Поле "Телефон покупця" не може бути пустим');
          return;
        } else if (containsLatinCharacters(buyerName.value) === true) {
          alert('Ім\'я покупця не може містити латинські символи');
          return;
        } else if (selectedCity.id === '' || selectedCity.id === null) {
          alert('Поле "Місто" не може бути пустим');
          return;
        } else if ((DeliveryWarehouse.id === '' || DeliveryWarehouse.id === null) && selectedDeliveryTechnology.id === 3) {
          alert('Поле "Відділення" не може бути пустим');
          return;
        } else if (inputProductFields.value[0].product.name === '' || inputProductFields.value[0].product.name === null) {
          alert('Поле "Товар" не може бути пустим');
          return;
        } else if (inputProductFields.value[0].product.price === '' || inputProductFields.value[0].product.price === null) {
          alert('Поле "Ціна" не може бути пустим');
          return;
        }
      }

      spinnerStatuses.value.saveOrder = true;

      //  console.log('SourceName', SourceName.value);
      //  console.log('firstFullOrderData', firstFullOrderData);


      //console.log('selectedPaymentType', selectedPaymentType.value);


      if (PartialPayment.value === false && !orderIsPaid.value) {
        //  console.log('orderIsPaid.value', orderIsPaid.value);
        //  console.log('join to set prepaid amount to 0')
        prePaidAmount.value = 0.00
      }

      // додав що б очищало оплату якщо змінили спосіб оплати на наложку
      if (selectedPaymentType.value.id === 2 || selectedPaymentType.value.id === 3) {
        orderIsPaid.value = false;
      }

      console.log('streetValues', streetValues);

      const form = {
        'from_frontend': {
          'state': true,
          'needToUpdate': needToUpdate,
        },

        "order_id": crmOroderId.value !== 0 ? crmOroderId.value : null,
        'buyer_id': buyerId.value,
        "update_type": props.modalTitle.modalType,
        "phone": parseInt(buyerPhone.value, 10),
        "buyer_name": buyerName.value,
        "counterparty_recipient_full_name": getCounterpartyRecipientFullName(),
        "recipient_phone": getCounterpartyRecipientPhone(),
        "np_buyer_cont_per_data_id": BuyerContactPersonId.value ?? null,
        "email": buyerEmail.value,
        "order_comment": getComment(),
        "default_weight": npDefaultWeight.value,
        "order_status_id": selectedOrderStatus.value.id,
        "backward_delivery_cargo_types": selectedPaymentType.value.id,
        "backward_del_payer": DeliveryPayer.value.id,
        "EDRPOU": inn.value ?? null,
        "source_id": SourceName.value.id, // TODO:

        "delivery_info_associated_type": selectedDeliveryType.value.id,
        "total_amount": totalAmount.value,
        "is_paid": orderIsPaid.value, // 4 сплачено
        "pre_paid_amount": prePaidAmount.value,
        "ttn_number": ttnNumber.value !== '' ? getTtnNumber(ttnNumber.value, selectedDeliveryType.value.id) : '',
        //"ttn_number": ttnNumber.value,
        "city_recipient_id": selectedCity.id,
        "warehouse_recipient_id": DeliveryWarehouse.id,
        "np_typ_payer_redel_id": DeliveryPayer.value.id,
        "np_backward_delivery_payer": BackwardDeliveryPayer.value.id,
        "np_payment_forms_id": selectedCashlesType.value.id,
        "cargo_type_id": 2,
        "service_type_id": selectedDeliveryTechnology.value.id,
        "sender_id": SenderContactPersonId.value ? SenderContactPersonId.value : getDefaultSenderByDeliveryType(selectedDeliveryType.value.id), // defaultNpSender.value.contact_person_id,
        "products": getProducts(),
        "seats_amount": getSeatsAmounts(),
        "dont_call": 0,
        'sender_warehouse_id': selectedSenderWarehouse.value,
        'buyer_type': selectedBuyerDropTypeId.value,
        'drop_price': dropPrice.value,
        'marketplace_accociated_type': SourceName.value.sourceType,
        'source_accociated_id': SourceName.value.id,
        'marketplace_order_id': OrderId.value,
        'marketplace_status_id': firstFullOrderData?.sourceData?.MarketplaceOrderStatusId ?? null,
        'selectedPriceType': selectedPriceTypeId.value,
        'address_changed_by_sender': addressChangedBySender.value,
        'create_check': createCheck.value,
        'street_data': streetValues,

      }

      if (selectedDeliveryType.value.id === 2) {
        form.ukrposhta_addiditional = {
          "up_sms": 0,
          "is_standard": upAddiditional.value.is_standard,
          "up_check_on_delivery": upAddiditional.value.up_check_on_delivery,
          "delivery_tehnology": selectedDeliveryTechnology.value.id,
          "fragile": upAddiditional.value.fragile,
          "bulky": upAddiditional.value.bulky,
          "fitting_allowed": upAddiditional.value.fitting_allowed,
          "np_typ_payer_redel_id": DeliveryPayer.value.id,
          "backward_delivery_payer": BackwardDeliveryPayer.value.id,
          "payment_method_id": selectedPaymentType.value.id,
          "onFailReceiveType": upAddiditional.value.onFailReceiveType,
          "up_packed_by_sender": upAddiditional.value.up_packed_by_sender
        }
      }


      //  console.log('form', form);


      let changedOrderForFront = null;
      let finalForm;
      if (senderUphoneKey.value === '' || senderUphoneKey.value == null) {
        finalForm = {
          [props.modalTitle.modalType]: {
            'orders': [form],
          }
        }
      } else {
        finalForm = {
          [props.modalTitle.modalType]: {
            'orders': {
              [senderUphoneKey.value]: form,
            }
          }
        }
      }


      // fill front order

      if (props.activeTab + 1 === selectedOrderStatus.value.id) {

        // console.log('inputProductFields.value', inputProductFields.value);
        changedOrderForFront = fillFrontOrder()
      } else if (props.activeTab !== selectedOrderStatus.value.id) {

        store.commit('order/deleteOrder', {crmOrderId: [crmOroderId.value], orderStatusId: props.activeTab})
      }

      //  console.log('changedOrderForFront', changedOrderForFront);
      //  console.log('create order request data', finalForm)

      createUpdateOrder(finalForm, changedOrderForFront)

      lastSearchValues.value.phone = '';
      lastSearchValues.value.name = '';
      lastSearchValues.value.city = '';
      lastSearchValues.value.npWarehouse = '';
      lastSearchValues.value.productName = '';

// Also, define an object to store the last search results count for each type

      lastSearchResultsCount.value.phone = null;
      lastSearchResultsCount.value.name = null;
      lastSearchResultsCount.value.city = null;
      lastSearchResultsCount.value.npWarehouse = null;
      lastSearchResultsCount.value.productName = null;

      // Also, define an object to store the last search value lengths for each type

      lastSearchValueLengths.value.phone = 0;
      lastSearchValueLengths.value.name = 0;
      lastSearchValueLengths.value.city = 0;
      lastSearchValueLengths.value.npWarehouse = 0;
      lastSearchValueLengths.value.productName = 0;

      // }
      spinnerStatuses.value.saveOrder = false;


    }

    async function createUpdateOrder(finalForm, changedOrderForFront) {
      try {
        // Use await to wait for the Promise to resolve
        let response = await store.dispatch('order/createUpdateOrder', {finalForm, changedOrderForFront});
        if (response.status === 'success') {
          closeModal()
        } else {
          // save in local storage with status 'error' and key 'changedOrderForFront.date'
          localStorage.setItem('changedOrderForFront.date', JSON.stringify(finalForm))
        }
        //  console.log('response dispatch', response); // Now this should show the actual response
      } catch (error) {
        console.error('Error in dispatching createUpdateOrder', error);
        // Handle any errors that occurred during the dispatch
      }
    }


    function getProductsForFront() {

      // console.log('inputProductFields.value', inputProductFields.value);

      return inputProductFields.value.map(item => {
        //  console.log('item333', item)
        return {
          "product_id": item.product.product_id, // +
          "order_id": crmOroderId.value,
          "price": item.product.price,
          "unknown_product": item.unknown_product,
          // "prices": inputProductFields.value.product.prices,
          "quantity": item.product.quantity,
          // "stock_reserved": item.reservedQty,
          // "on_return": ,
          "deleted_by": null,
          "product": {

            "name": item.product.name,
            "short_name": item.product.name,
            "article": item.product.article,
            "weight": item.product.weight,
            "length": item.product.length,
            "width": item.product.width,
            "stock": {
              "stock_qty": item.product.stockQty,
              "reserved_qty": item.product.reservedQty,
              "on_return_qty": item.product.onReturn
            }
          }
        }
      })
    }

    function getProducts() {

      return inputProductFields.value.map(item => {
        // console.log('product', item.product);
        delete item.product.prices;
        return item.product
      })
    }


//////////////// table input  end ////////////////////////


    let savedModalParams = null;

    const minimize = () => {
      if (!isMinimized.value) {

        // console.log('orderModalWindowPosition.value', orderModalWindowPosition.value);

        // Create a deep copy of the object using JSON.parse and JSON.stringify
        savedModalParams = JSON.parse(JSON.stringify(orderModalWindowPosition.value));

        console.log('savedModalParams', savedModalParams);
        orderModalWindowPosition.value.width = 300; // Minimized width
        orderModalWindowPosition.value.height = 50;  // Minimized height
        orderModalWindowPosition.value.x = window.innerWidth - orderModalWindowPosition.value.width - 50; // Subtracting 50 for potential margins/paddings
        orderModalWindowPosition.value.y = window.innerHeight - orderModalWindowPosition.value.height - 80; // Subtracting 70 for potential margins/paddings
        isMinimized.value = true;

      } else {
        unMinimize();
      }
    };

    const unMinimize = () => {
      console.log('savedModalParams', savedModalParams);
      orderModalWindowPosition.value = savedModalParams;

      isMinimized.value = false;
    };

    // get sender settings from local storage
    const senderSettings = JSON.parse(localStorage.getItem('senderSettings'));


    // console.log('senderSettings', senderSettings);

    if (senderSettings !== null) {
      // console.log('sender settings', senderSettings);
      currencyes[2] = senderSettings.usd_val;
      currencyes[3] = senderSettings.eur_val;
      currencyes[4] = senderSettings.zl_val;
      //  console.log('currencyes', currencyes);
      npDefaultWeight.value = senderSettings.default_weight;
      senderDeliveryPayerLimit = senderSettings.senderPayerIfSumBigger;
      // console.log("npDefaultWeight", npDefaultWeight.value);

    }

    /*    async function senderSettings  ()  {
          let response = await getSenderSettings()
         console.log('sender settings', response);
          if(response.sender_settings !== null){
          currencyes[2] = response.sender_settings.usd_val;
          currencyes[3] = response.sender_settings.eur_val;
          currencyes[4] = response.sender_settings.zl_val;

          npDefaultWeight = JSON.stringify(response.sender_settings.default_weight);
          senderDeliveryPayerLimit = JSON.stringify(response.sender_settings.senderPayerIfSumBigger);
            localStorage.setItem('senderSettings', JSON.stringify(response.sender_settings));
          }

          localStorage.setItem('npSenders', JSON.stringify(formatNpSenders(response.delivery_counterparties)));
          localStorage.setItem('dropShipType', JSON.stringify(response.dropship_type));
          localStorage.setItem('sms_templates', JSON.stringify(response.sms_templates));
          localStorage.setItem('ukrPostSenders', JSON.stringify(response.urk_post_sender_data));
        }*/

    /*    const calcPriceWithCurrency = computed((price, currencyId) => {

          if(currencyId === 1) {
            return price;
          } else {
          return (price * currencyes[currencyId]).toFixed(2);
          }
        });*/

    const calcPriceWithCurrency = ((price, currencyId) => {


      if (currencyId === 1) {
        return price;
      } else {
        return (price * currencyes[currencyId]).toFixed(2);
      }
    });

    orderStatuses = store.getters['order/getOrderStatuses'];


    const defaultUpSender = ref(null);
    const npSendersString = localStorage.getItem('npSenders');
    const upSendersString = JSON.parse(localStorage.getItem('ukrPostSenders'));

    //  console.log('upSendersString', upSendersString);

    npSenders.value = JSON.parse(npSendersString);
    //  console.log('npSenders.value', npSenders.value);
    defaultNpSender.value = npSenders.value.find(sender => sender.defaultContactPerson === 1 && sender.default_sender === 1);
    defaultUpSender.value = upSendersString.find(sender => sender.default_cabinet === 1);


    function fillDefaultNpSenderByContactPersonId(id) {
      const sender = npSenders.value.find(sender => sender.contact_person_id === id);

      selectSender(sender)

      return sender;

    }


    const fullInfoId = ref(null);
    const selectedSenderWarehouse = ref(findDefaultWarehouseId())

    function findDefaultWarehouseId() {
      if (senderAddressNp.value) {
        const defaultWarehouse = Object.values(senderAddressNp.value).find(warehouse => {
          return warehouse.is_default === true;
        });
        return defaultWarehouse ? defaultWarehouse.id : undefined;
      }
    }


    function selectSenderWarehouse(warehouse) {
      console.log(' selectSenderWarehouse', warehouse);
      selectedSenderWarehouse.value = warehouse.id;
    }


    let originalDate;

    function compareBuyerPhoneWithRecipientsPhone(buyerPhone, recipientPhone) {
      //  console.log('buyerPhone', buyerPhone);
      //  console.log('recipientPhone', recipientPhone);
      if (recipientPhone !== '0undefined' && buyerPhone !== recipientPhone) {
        otherReceiverChecked.value = true;
      } else {
        buyerName.value = DeliveryFio.value ? DeliveryFio.value : buyerName.value;
        otherReceiverChecked.value = false; // додав нещодавно, бо краще вимикати чекбокс, якщо вони однакові
        // console.log('delivery fio is buyer fio true');
        //  console.log('buyer name is changed to', buyerName.value);
        validateBuyerName();
      }
    }


    function fillOrderData(newItem) {

      console.log('newItem', newItem);


      resetModalState();
      props.modalTitle.modalType = 'update'
      // console.log('newItem', newItem);
      //console.log('firstFullOrderData', newItem);
      // newItem.sourceData?.source_cabinet_id

      const senderContactPersonIdForOrder = newItem?.deliveryData?.SenderId;
      fillDefaultNpSenderByContactPersonId(senderContactPersonIdForOrder)


      firstFullOrderData = newItem;
      isAutomaticUpdate.value = true;
      OrderId.value = newItem?.sourceData.OrderNumber ?? '';
      orderDate.value = formatDate(newItem?.date);
      originalDate = newItem?.date;
      SourceName.value.sourceType = newItem?.SourceType ?? '';
      SourceName.value.name = newItem?.sourceData.SourceName ?? '';
      SourceName.value.id = newItem?.sourceData.source_cabinet_id ?? '';
      marketplaceOrderStatus.value = newItem?.sourceData.MarketplaceOrderStatus;
      buyerName.value = newItem?.BuyerName;
      buyerNameIsEditable.value = false;
      selectedBuyerDropTypeId.value = newItem?.buyer_type ?? 1; // dropBuyerType
      dropPrice.value = newItem?.drop_price ?? 0; // drop price
      crmOroderId.value = newItem?.crm_order_id;
      selectedOrderStatus.value = orderStatuses[newItem?.CrmOrderStatusId];
      //  validateBuyerName();
      buyerPhone.value = '0' + newItem?.BuyerPhone;
      usersOrderNumber.value = newItem?.UsersOrderNumber;
      dontCall.value = newItem?.dontCall;
      buyerPhoneIsNotEditable.value = true;
      orderIsPaid.value = newItem?.is_paid === 1;
      buyerEmail.value = newItem?.BuyerEmail;
      fullInfoId.value = newItem?.full_info_id;
      buyerId.value = newItem?.BuyerId;
      createCheck.value = newItem?.create_check === 1;
      checkData.value = newItem?.check_data;
      totalAmount.value = newItem?.total_amount;
      selectedSenderWarehouse.value = newItem?.deliveryData.sender_warehouse_id;
      selectedPriceTypeId.value = newItem?.priceTypeId;
      prePaidAmount.value = newItem?.prePaidAmount;
      // console.log('newItem?.prePaidAmount', newItem?.prePaidAmount);
      // console.log('newItem?.total_amount', newItem?.total_amount);
      MarketplacePaymentType.value = newItem?.sourceData.MarketplacePaymentType ?? '';
      // console.log('paymentTYpe', paymentTYpe);
      selectedPaymentType.value = getPaymentType(newItem)
      selectedDeliveryType.value = deliveryInfoAssociatedTypes[newItem?.deliveryInfoAssociatedType] || newItem?.deliveryData?.PostName;
      // console.log('selectedPaymentType.value', selectedPaymentType.value);
      selectedSender.value = newItem?.deliveryData?.SenderName;
      addressChangedBySender.value = newItem?.deliveryData?.AddressChangedBySender ?? false;
      SenderContactPersonId.value = senderContactPersonIdForOrder;
      DeliveryFio.value = newItem?.deliveryData?.DeliveryFio;
      DeliveryFioIsEditable.value = false;
      BuyerContactPersonId.value = newItem?.deliveryData?.BuyerContactPersonId;
      DeliveryPhone.value = '0' + newItem?.deliveryData?.DeliveryPhone;
      selectedCity.name = newItem?.deliveryData?.DeliveryCity;
      selectedCity.id = newItem?.deliveryData?.DeliveryCityId;
      DeliveryWarehouse.name = newItem?.deliveryData?.DeliveryWarehouse ?? '';
      DeliveryWarehouse.id = newItem?.deliveryData?.DeliveryWarehouseId ?? '';
      selectedDeliveryTechnology.value = serviceTypes[newItem?.deliveryData?.ServiceTypeId] ?? serviceTypes[3];
      DeliveryPayer.value = DeliveryPayerTypes[newItem?.deliveryData?.deliveryPayerId] ?? DeliveryPayerTypes[4];
      BackwardDeliveryPayer.value = DeliveryPayerTypes[newItem?.deliveryData?.backwardDeliveryPayer] ?? DeliveryPayerTypes[4];
      orderComment.value = newItem?.orderComment;
      ttnNumber.value = newItem?.deliveryData?.TtnNumber === 'Не вказано' ? '' : newItem?.deliveryData?.TtnNumber;
      trackingStatus.value = newItem?.deliveryData?.DeliveryStatus;
      senderUphoneKey.value = newItem?.senderUphoneKey;
      otherReceiverChecked.value = DeliveryPhone.value !== buyerPhone.value;
      /*      якщо не пусто у newItem?.deliveryData?.upAddiditional , то заповнюємо*/
      if (!selectedDeliveryType.value) {
        selectDeliveryType(deliveryInfoAssociatedTypes[1]);
      }
      // console.log('selectedDeliveryType.value', selectedDeliveryType.value);


      if (newItem?.deliveryData?.ServiceTypeId === 4) {
        console.log('join in service type id = 4')
        streetValues.name = newItem?.deliveryData?.streetData.name
        streetValues.building = newItem?.deliveryData?.streetData.building
        streetValues.flat = newItem?.deliveryData?.streetData.flat
        streetValues.comment = newItem?.deliveryData?.streetData.comment
      }


      const upData = newItem?.deliveryData?.upAddiditional;

      // console.log('createCheck', createCheck.value);

      if (upData) {
        upAddiditional.value.is_standard = upData.is_standard === 1;
        upAddiditional.value.up_check_on_delivery = upData.up_check_on_delivery === 1;
        upAddiditional.value.fragile = upData.fragile === 1;
        upAddiditional.value.bulky = upData.bulky === 1;
        upAddiditional.value.fitting_allowed = upData.fitting_allowed === 1;
        upAddiditional.value.onFailReceiveType = upData.onFailReceiveType;
        upAddiditional.value.up_packed_by_sender = upData.packed_by_sender === 1;
      }

      // console.log('upAddiditional', upAddiditional.value);


      validateBuyerName();
      validateDeliveryFio();
      compareBuyerPhoneWithRecipientsPhone(buyerPhone.value, DeliveryPhone.value);
      PartialPayment.value = calcPartialPaymentState(newItem);


      // console.log('newItem?.products', newItem?.products);

      inputProductFields.value = newItem?.products.map(orderProduct => ({


        dropdownItemRefs: [],
        product: {
          product_id: orderProduct.product_id, // +
          name: orderProduct.product.name,
          article: orderProduct.product.article ?? '',
          quantity: orderProduct.quantity,
          unknown_product: orderProduct.product.unknown_product,
          price: orderProduct.price,
          prices: {
            [selectedPriceTypeId.value]: orderProduct.price,
          },
          totalPrice: orderProduct.quantity * orderProduct.price,
          image_url: orderProduct.product.thumbnail_url,
          stockQty: orderProduct.product.stock[0]?.stock_qty,
          stockReservedByThisOrder: orderProduct.stock_reserved ? orderProduct.quantity : 0,
          reservedQty: ' | ' + orderProduct.product.stock[0]?.reserved_qty
          /* + '(' + (orderProduct.stock_reserved ? orderProduct.quantity : 0) + ')'*/,
          onReturn: orderProduct.product.stock[0]?.on_return_qty !== 0 ? ' | '
              + orderProduct.product.stock.on_return_qty
              /* + '(' + (orderProduct.on_return ? orderProduct.quantity : 0) + ')'*/ : null,

          onReturnByThisOrder: orderProduct.on_return ? orderProduct.quantity : 0,
          weight: orderProduct.product.weight * orderProduct.quantity,
          height: orderProduct.product.length * orderProduct.length,
          length: orderProduct.product.length,
          width: orderProduct.product.width,
          needUpdateWeight: false,
          originalWeight: orderProduct.product.weight,
          originalHeight: orderProduct.product.height,
          originalLength: orderProduct.product.length,
          originalWidth: orderProduct.product.width,

        },
        inputField: {
          article: {
            element: null,
            editable: false,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          name: {
            element: null,
            editable: false,
            isFocused: false,
            isFocusWithinDropdown: false,
            isProductNameDropdownVisible: false,
          },
          qty: {
            element: null,
            editable: false,
            isFocused: false,
          },
          price: {
            element: null,
            editable: false,
            isFocused: false,
          },
          weight: {
            element: null,
            editable: false,
            isFocused: false,
          },
        }
      }));
      ProductNameIsEditable.value = false;
      seatsAmounts.value = newItem?.seatsAmounts.map(seatsAmount => ({
        id: seatsAmount.id,
        order_id: seatsAmount.order_id,
        seats_amount: seatsAmount.seats_amount,
        weight: seatsAmount.weight,
        height: seatsAmount.height,
        width: seatsAmount.width,
        length: seatsAmount.length,
        volume: seatsAmount.volume,
        np_pack_list_id: seatsAmount.np_pack_list_id,
        np_pack_list_name: seatsAmount.np_pack_list_id ? getPackListName(seatsAmount.np_pack_list_id) : null,
      }));
      if (seatsAmounts.value.length > 1) {
        showSeatsDetalsParams.value = true;
      }
      // console.log('updated blocks', needToUpdate);
      // console.log('inputProductFields', inputProductFields.value);

      //  console.log('inputProductFields', inputProductFields.value) ;

      setTimeout(setAutomaticUpdateToFalse, 500)
    }

    function calcPartialPaymentState(newItem) {
      //  console.log('payment type', newItem.PaymentTypeNameId); // 2
      //  console.log('newItem?.prePaidAmount', parseFloat(newItem?.prePaidAmount)); // 0.00
      //  console.log('typeof newItem?.prePaidAmount', typeof newItem?.prePaidAmount, parseFloat(newItem?.prePaidAmount));
      //  console.log('newItem?.prePaidAmount !== 0.00', parseFloat(newItem?.prePaidAmount) !== 0.00) // true
      //  console.log('newItem?.total_amount', newItem?.total_amount); // 385
      // console.log(typeof newItem?.total_amount, newItem?.total_amount);
      //  console.log('newItem?.prePaidAmount !== newItem?.total_amount', parseFloat(newItem?.prePaidAmount) !== newItem?.total_amount) // true

      if (newItem.PaymentTypeNameId === 3 || newItem.PaymentTypeNameId === 2) {
        let prepaidValue = parseFloat(newItem?.prePaidAmount);
        if (prepaidValue !== 0.00 && prepaidValue !== parseFloat(newItem?.total_amount)) {
          return true;
        }
      } else {
        return false;
      }
    }

    function setAutomaticUpdateToFalse() {
      isAutomaticUpdate.value = false;
      //  console.log('updated blocks', needToUpdate);
    }

    if (props.modalTitle.modalType === 'update') {
      fillOrderData(orderForUpdate.value);
    } else if (props.modalTitle.modalType === 'insert') {

      addSeatAmount();
      if (defaultNpSender.value) {
        selectSender(defaultNpSender.value); // Use the selectSender function to set the default values
      }
    }


    watch(orderForUpdate, (newValue) => {
      if (newValue) {
        /*    resetModalState().then(() => {
              // console.log('orderForUpdate watch', newValue);
              // console.log('props.modalTitle.modalType', props.modalTitle.modalType);
              if (props.modalTitle.modalType === 'update') {
                // console.log('update order in watch')
                fillOrderData(newValue);
              } else if (props.modalTitle.modalType === 'insert') {
                console.log('insert order in watch')
                addSeatAmount();
                if (defaultNpSender.value) {
                  selectSender(defaultNpSender.value); // Use the selectSender function to set the default values
                }
              }
            });*/

        if (props.modalTitle.modalType === 'update') {
          // console.log('update order in watch')
          fillOrderData(newValue);
        } else if (props.modalTitle.modalType === 'insert') {
          console.log('insert order in watch')
          addSeatAmount();
          if (defaultNpSender.value) {
            selectSender(defaultNpSender.value); // Use the selectSender function to set the default values
          }
        }

      }
      isReseted.value = false;
    });

    watch(orderIsPaid, (value) => {

      if (value) {

        paymentStatus.value = paymentStatuses.paid;
        prePaidAmount.value = totalAmount.value;

        //  console.log('prePaidAmount1', prePaidAmount.value);

      } else if (!value && !orderIsPaid.value) {
        paymentStatus.value = paymentStatuses.unpaid;
        prePaidAmount.value = 0.00;
        //  console.log('prePaidAmount2', prePaidAmount.value);
      }
    });


    watch(urikChecked, (newVal) => {
      /*      if (isReseted.value) {
              return
            }*/
      if (newVal === true) {
        DeliveryPayer.value = DeliveryPayerTypes[4];
        BackwardDeliveryPayer.value = DeliveryPayerTypes[4];
        selectedCashlesType.value = cashlesTypes[1]
      } else if (newVal === false && isNonCashSupported.value === false) {
        selectedCashlesType.value = cashlesTypes[2]
      }
      if (!isAutomaticUpdate.value) {
        // console.log('deliveryData changed 1');
        needToUpdate.deliveryData = true;
      }
    });

    watch(DeliveryPayer, (newVal) => {
      if (isAutomaticUpdate.value) {
        return
      }
      //  console.log('watch selectDeliveryPayer', newval);
      //  console.log('isNonCashSupported.value', isNonCashSupported.value);
      if (newVal === DeliveryPayerTypes[3] && isNonCashSupported.value === true) {
        selectedCashlesType.value = cashlesTypes[1] // безготівка
      } else if (newVal === DeliveryPayerTypes[4] && !urikChecked.value) {
        selectedCashlesType.value = cashlesTypes[2] // готівка
      }

      /*      if (!isAutomaticUpdate.value) {
              needToUpdate.paymentData = true;
            }*/
    })


    watch(totalAmount, (newVal) => {
      if (isAutomaticUpdate.value) {
        return
      }
      // console.log('selectedDeliveryType.value.id', selectedDeliveryType.value.id);
      if (selectedDeliveryType.value.id === 1) { // Нова пошта
        if (parseInt(newVal) > senderDeliveryPayerLimit) {
          //   console.log('newVal > senderDeliveryPayerLimit', parseInt(newVal), senderDeliveryPayerLimit);
          DeliveryPayer.value = DeliveryPayerTypes[3];
        } else {
          DeliveryPayer.value = DeliveryPayerTypes[4];
        }
      }
    })

    /* watch(() => inputProductFields.value.map(item =>
             //[item.product.quantity, item.product.price, item.product.name, item.product.article, item.product.weight]),
             [item.product.quantity,
               item.product.price,
               item.product.name,
               item.product.article,
               item.product.weight
               ]),
         (newValues, oldValues) => {
           newValues.forEach((value, index) => {
             const [newQty, newPrice, newName, newArticle, newWeight] = value;
             const [oldQty, oldPrice, oldName, oldArticle, oldWeight] = oldValues[index] || [];
             // Check for changes in qty or price

             //  console.log('newprice', newPrice);
             //   console.log('oldPrice', oldPrice);

             if (newQty !== oldQty || newPrice !== oldPrice) {
               inputProductFields.value[index].product.totalPrice = newQty * newPrice;

               if (inputProductFields.value[index].product.originalWeight !== '' || inputProductFields.value[index].product.originalWeight !== 0) {
                 inputProductFields.value[index].product.weight = inputProductFields.value[index].product.originalWeight * newQty;
               }

               if (inputProductFields.value[index].product.originalLength !== '' || inputProductFields.value[index].product.originalLength !== 0) {
                 inputProductFields.value[index].product.length = inputProductFields.value[index].product.originalLength * newQty;
               }

               // TODO зберіг що б доробити пізніше (автоматично змінювати статус оплати в залежності від того чи дорівнює
               // сума замовлення сумі передоплати)

             }

             // calculate seat amount weight off all products if weight was changed

             if (newWeight !== oldWeight) {
               if (seatsAmounts.value.length === 1) {
                 if (isAutomaticUpdate.value) {
                   return
                 }
                 //  console.log('newWeight', newWeight);
                 //  console.log('oldweightn', oldWeight);
                 seatsAmounts.value[0].weight = inputProductFields.value
                 .reduce((weight, item) => {
                   let totalWeight = Number(item.product.weight) || 0;
                   return weight + totalWeight;
                 }, 0).toFixed(2);
                 // console.log('seatsAmounts.value[0].weight', seatsAmounts.value[0].weight);
               }
               if (inputProductFields.value[index].product.originalWeight !== inputProductFields.value[index].product.weight / newQty) {
                 inputProductFields.value[index].product.needUpdateWeight = true;
                 inputProductFields.value[index].product.originalWeight = inputProductFields.value[index].product.weight / newQty
                 // console.log('new originalWeight', inputProductFields.value[index].product.originalWeight)
               }
             }


             if (newName !== oldName && newName.length >= 3 && inputProductFields.value[index].inputField.name.isFocused) {
               inputProductFields.value[index].product.name = newName;
               search('productName', newName, 300, index);
             }
             if (newArticle !== oldArticle && newArticle.length >= 2
                 && inputProductFields.value[index].inputField.article.isFocused
             ) {
               inputProductFields.value[index].product.article = newArticle;
               search('article', newArticle, 300, index);
             }
             // }
           });

           totalAmount.value = inputProductFields.value.reduce((sum, item) => {
             let totalPrice = Number(item.product.totalPrice) || 0;
             return sum + totalPrice;
           }, 0).toFixed(2);
         },
         {deep: true}
     );*/


    const formatToLocalizedNumber = (value, locale = 'uk-UA') => {
      // Remove all non-digit characters except decimal point
      const cleanValue = String(value).replace(/[^\d.,]/g, '')

      // Convert to number, handling both comma and dot as decimal separators
      const number = parseFloat(cleanValue.replace(',', '.'))

      if (isNaN(number)) return 0

      // Format number according to locale
      return number.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    }

    const parseLocalizedNumber = (value, locale = 'uk-UA') => {
      if (!value) return 0

      // Remove all non-digit characters except decimal point or comma
      const cleanValue = String(value).replace(/[^\d.,]/g, '')

      // Convert to number, handling both comma and dot as decimal separators
      const number = parseFloat(cleanValue.replace(',', '.'))

      return isNaN(number) ? 0 : number
    }


    function validateWeightNumber(index) {
      // Конвертуємо вхідне значення в рядок і замінюємо всі коми на крапки
      const cleanValue = String(inputProductFields.value[index].product.weight)
          .replace(/[^\d.,]/g, '')
          .replace(',', '.');

      // Перевіряємо формат числа і конвертуємо його
      if (/^\d*\.?\d*$/.test(cleanValue)) {
        const parsedNumber = parseFloat(cleanValue).toFixed(2);
        // console.log('parsed weight', parsedNumber);
        inputProductFields.value[index].product.weight = parsedNumber === 'NaN' ? '0.00' : parsedNumber;
      }
    }

    function validatePriceValue(index) {
      // Конвертуємо вхідне значення в рядок і замінюємо всі коми на крапки
      const cleanValue = String(inputProductFields.value[index].product.price)
          .replace(/[^\d.,]/g, '')
          .replace(',', '.');

      // Перевіряємо формат числа і конвертуємо його
      if (/^\d*\.?\d*$/.test(cleanValue)) {
        const parsedNumber = parseFloat(cleanValue).toFixed(2);
        // console.log('parsed price', parsedNumber);
        inputProductFields.value[index].product.price = parsedNumber === 'NaN' ? '0.00' : parsedNumber;
      }
    }

    /*    function validatePriceValue(inputValue, index) {
          // Перевіряємо, чи є введене значення рядком



          console.log('index', index);
          console.log('inputProductFields.value', inputProductFields.value[index]);

          if (typeof inputValue !== 'string') {
            console.error("Некоректне введене значення:", inputValue);




            inputProductFields.value[index].product.price = '0.00';
            return;
          }

          // Очищуємо значення від некоректних символів
          const cleanValue = String(inputValue)
              .replace(/[^\d.,]/g, '') // Видаляємо все, що не є цифрами, комами чи крапками
              .replace(',', '.'); // Замінюємо кому на крапку

          // Перевіряємо формат числа і конвертуємо його
          if (/^\d*\.?\d*$/.test(cleanValue)) {
            const parsedNumber = parseFloat(cleanValue).toFixed(2); // Форматуємо до двох знаків після коми
            console.log('parsed price', parsedNumber); // parsed price 0.00
            inputProductFields.value[index].product.price = parsedNumber === 'NaN' ? '0.00' : parsedNumber;
          } else {
            inputProductFields.value[index].product.price = '0.00'; // Якщо значення некоректне, очищуємо
          }
        }*/


    watch(
        () =>
            inputProductFields.value.map((item) => [
              item.product.quantity,
              item.product.price,
              item.product.name,
              item.product.article,
              // item.product.weight,
              item.product.weight
            ]),
        (newValues, oldValues) => {
          newValues.forEach((value, index) => {
            const [newQty, newPrice, newName, newArticle, newWeight] = value;
            const [oldQty, oldPrice, oldName, oldArticle, oldWeight] = oldValues[index] || [];

            // Перевіряємо, чи є значення NaN, і виправляємо
            /*  if (isNaN(newQty)) inputProductFields.value[index].product.quantity = oldQty;
              if (isNaN(newPrice)) inputProductFields.value[index].product.price = oldPrice;
  */
            //  console.log('newWeight', newWeight);


            /* const numberWeight = Number(newWeight.replace(',', '.'));

             if (Number.isNaN(numberWeight)) {
               // Якщо не є числом - використовуємо старе значення
               inputProductFields.value[index].product.weight = oldWeight;
             } else {
               // Форматуємо число до 3 знаків після крапки
               inputProductFields.value[index].product.weight = Number(numberWeight.toFixed(3));
             }*/

            // Логіка для кількості та ціни
            if (newQty !== oldQty || newPrice !== oldPrice) {
              validatePriceValue(index)
              inputProductFields.value[index].product.totalPrice = (newQty * newPrice).toFixed(2)

              if (inputProductFields.value[index].product.originalWeight !== '') {
                validateWeightNumber(index)
                inputProductFields.value[index].product.weight = inputProductFields.value[index].product.originalWeight * newQty;
              } else if (
                  inputProductFields.value[index].product.originalWeight === '' &&
                  inputProductFields.value[index].product.weight !== 0) {
                validateWeightNumber(index)
                inputProductFields.value[index].product.weight = inputProductFields.value[index].product.weight * newQty;
              }


              if (inputProductFields.value[index].product.originalLength) {
                inputProductFields.value[index].product.length = inputProductFields.value[index].product.originalLength * newQty;
              }
            }

            // Логіка для ваги
            if (newWeight !== oldWeight) {
              if (seatsAmounts.value.length === 1 && !isAutomaticUpdate.value) {
                seatsAmounts.value[0].weight = inputProductFields.value
                    .reduce((weight, item) => weight + (Number(item.product.weight) || 0), 0)
                    .toFixed(2);
              }

              if (inputProductFields.value[index].product.originalWeight !== inputProductFields.value[index].product.weight / newQty) {
                inputProductFields.value[index].product.needUpdateWeight = true;
                inputProductFields.value[index].product.originalWeight = inputProductFields.value[index].product.weight / newQty;
              }
            }

            // Логіка для назви
            if (newName !== oldName && newName.length >= 3 && inputProductFields.value[index].inputField.name.isFocused) {
              inputProductFields.value[index].product.name = newName;
              search('productName', newName, 300, index);
            }

            // Логіка для артикулу
            if (newArticle !== oldArticle && newArticle.length >= 2 && inputProductFields.value[index].inputField.article.isFocused) {
              inputProductFields.value[index].product.article = newArticle;
              search('article', newArticle, 300, index);
            }
          });

          // Обчислення загальної суми
          totalAmount.value = inputProductFields.value
              .reduce((sum, item) => sum + (Number(item.product.totalPrice) || 0), 0)
              .toFixed(2);
        },
        {deep: true}
    );


    watch(DeliveryPhone, (newVal) => {
      if (newVal !== '0undefined') {
        nextTick(() => {

          if (newVal !== '') {
            compareBuyerPhoneWithRecipientsPhone(newVal, buyerPhone.value);
          }
        });
      } else {
        DeliveryPhone.value = '';
      }

      validateBuyerName();
      // console.log('isAutomaticUpdate.value', isAutomaticUpdate.value);
      if (!isAutomaticUpdate.value) {

        needToUpdate.deliveryData = true;
        //   console.log('deliveryData changed 3');
      }
    });

    // watch(() => seatsAmounts.value.map(seatsAmount => seatsAmount.name),
    watch(() => seatsAmounts.value.map(seatsAmount => seatsAmount.np_pack_list_name),
        (newValues, oldValues) => {

          // console.log('watch newValues', newValues);

          newValues.forEach((value, index) => {
            const newName = value;
            const oldName = oldValues[index] || [];

            // Check for changes in name and if length is >= 3
            if (newName !== oldName && inputFocusStates.packingName) {
              //    search('packingName', newName, 'packingName', index);
              fillSearchPackingResults('packingName', newName, null, index)
              if (!isAutomaticUpdate.value) {
                needToUpdate.seatsAmounts = true;
              }
            }
          });
        },
        {deep: true}
    );


    // TODO optimize it
    watch(() => [PartialPayment.value, prePaidAmount.value, otherReceiverChecked.value,],
        (partialPaymentIsTrue, prepaidAmountValue, otherReceiverChanged) => {
          if ((partialPaymentIsTrue || prepaidAmountValue)
              && needToUpdate.paymentData === false && !isAutomaticUpdate.value) {
            needToUpdate.paymentData = true;
          }
          if (otherReceiverChanged && needToUpdate.deliveryData === false && !isAutomaticUpdate.value) {
            needToUpdate.deliveryData = true;
            //  console.log('deliveryData changed 2');
          }
        })


    // add watcher to PartialPayment
    watch(PartialPayment, (newValue, oldValue) => {
      // console.log('PartialPayment watcher', newValue, oldValue)

      if (newValue === false) {
        prePaidAmount.value = 0.00;
      }
    })


    const afterPaymentValue = computed(() => {
      return totalAmount.value - prePaidAmount.value;
    });


    /*    function setPaiyedValue() {

          console.log('orderIsPaid.value ', orderIsPaid.value);

          if (orderIsPaid.value === true) {
            console.log('order is paid called');
            prePaidAmount.value = totalAmount.value;
            console.log('prePaidAmount.value', prePaidAmount.value);
          } else {
            // prePaidAmount.value = 0;
          }
        }*/

    // запуск пошуку по телефону, фіо, місту, відділенню
    watch([buyerPhone, buyerName, () => selectedCity.name, () => DeliveryWarehouse.name],
        ([editedPhone, editedName, editedCity, editedWarehouse]) => {

          //   console.log('watcher called', DeliveryWarehouse.name);

          if (isAutomaticUpdate.value) return; // якщо відкриття замовлення, то не виконувати прослуховування

          if (editedCity && containsLatinCharacters(editedCity) && inputFocusStates.city) {
            return;
          }
          if (editedWarehouse && containsLatinCharacters(editedWarehouse) && inputFocusStates.npWarehouse) {
            return; // Exit the watcher early
          }
          //search('phone', editedPhone.substring(1));
          // if (editedPhone && editedPhone.length >= 5 && !buyerName.value && inputFocusStates.phone) {
          if (editedPhone && inputFocusStates.phone) {
            if (editedPhone.length >= 5 && editedPhone.length < 10 && !buyerName.value) {
              //console.log('first phone search')
              search('phone', editedPhone.substring(1), 300);

              // search('phone', editedPhone.substring(1));

              /*const results =  PhoneSearch('phone', editedPhone.substring(1), 0);
                 console.log('Results:', results);
                 if (filteredbuyerPhoneResults.value.length === 0 && editedPhone.length === 10) {
                   console.log(' tab navigation')
                   handleTabNavigation(null, 'buyerPhoneRef');
                 }*/


            } else if (editedPhone.length === 10 && !buyerId.value) {
              lastSearchResultsCount.value.phone = null;
              lastSearchValues.value.phone = '';
              lastSearchValueLengths.value.phone = 0;
              //  console.log('second phone search')
              let url = 'search-buyers?phone=' + editedPhone.substring(1);
              searchByPhone(url, editedPhone.length);
            }

          } else if (editedName && editedName.length >= 3 && inputFocusStates.buyerName && !buyerPhone.value) {
            search('name', editedName);
          } else if (editedCity && editedCity.length >= 2 && inputFocusStates.city) {
            search('city', editedCity);
          } else if (editedWarehouse && (editedWarehouse.length >= 3
              || Number.isInteger(parseInt(editedWarehouse))) && inputFocusStates.npWarehouse) {
            console.log('editedWarehouse', editedWarehouse)
            search('npWarehouse', editedWarehouse);
          }
        });


    /*
        const fillSearchPackingResults = (type, searchValue, debounceTime, index) => {

          console.log('searchValue', searchValue);

          if(searchValue === undefined){
            return;
          }

          let packingTypes;
          if (localStorage.getItem('packingTypes')) {
            packingTypes = JSON.parse(localStorage.getItem('packingTypes'));
            StaticPackingTypes = packingTypes;

            console.log('join in if');

          } else {
            console.log('join in else');
            console.log('searchValue', searchValue);

            packingTypes = search(type, searchValue, debounceTime, index);
          }


          console.log('packingTypes', packingTypes)

          if (searchValue) {
            searchPackingResults.value = packingTypes.filter(packingType =>
                packingType.description.toLowerCase().includes(searchValue.toLowerCase())
            );
          } else {
            searchPackingResults.value = packingTypes;
          }
          isPackingDropdownVisible.value = searchPackingResults.value?.length > 0;
        }
    */

    const fillSearchPackingResults = async (type, searchValue, debounceTime, index) => {
      // console.log('searchValue', searchValue);

      if (searchValue === undefined) {
        return;
      }

      let packingTypes;

      if (localStorage.getItem('packingTypes')) {
        packingTypes = JSON.parse(localStorage.getItem('packingTypes'));
        StaticPackingTypes = packingTypes;

        //   console.log('join in if');

        processPackingTypes(packingTypes, searchValue);
      } else {
        //    console.log('join in else');
        //   console.log('searchValue', searchValue);

        try {
          packingTypes = await search(type, searchValue, debounceTime, index);
          //    console.log('packingTypes', packingTypes);

          processPackingTypes(packingTypes, searchValue);
        } catch (error) {
          console.error('Помилка пошуку:', error);
        }
      }
    };

    const processPackingTypes = (packingTypes, searchValue) => {
      if (Array.isArray(packingTypes)) {
        searchPackingResults.value = searchValue
            ? packingTypes.filter(packingType =>
                packingType.description.toLowerCase().includes(searchValue.toLowerCase())
            )
            : packingTypes;
      } else {
        searchPackingResults.value = [];
      }
      isPackingDropdownVisible.value = searchPackingResults.value?.length > 0;
    };


    async function searchByPhone(url, phoneLength) {
      try {
        isPhoneDropdownVisible.value = true
        // DropdownStatus = isPhoneDropdownVisible.value
        searchResults.value = await getSearchResults(url);
        //   console.log('Results:', searchResults.value);
        isPhoneDropdownVisible.value = searchResults.value.length > 0;
        //  console.log('isPhoneDropdownVisible.value', isPhoneDropdownVisible.value)

        //  console.log('filteredbuyerPhoneResults.value', filteredbuyerPhoneResults.value)
        //  console.log('phoneLength', phoneLength)

        if (filteredbuyerPhoneResults.value.length === 0 && phoneLength === 10) {
          //   console.log(' tab navigation')
          buyerPhoneIsNotEditable.value = true;

          handleTabNavigation(null, 'buyerPhoneRef');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }


    /* function productPopoverVisible ()  {

       const elem = inputProductFields.value[currentProductIndex].inputField.name.isProductNameDropdownVisible
       console.log('elem', elem)
       return false;
     }*/

    const isProductNameDropdownVisible = computed(() => {
      // if empty inputProductFields is empty, return

      if (inputProductFields.value.length === 0) {
        return false;
      }

      return (
          /*    (inputProductFields.value[currentProductIndex.value].inputField.name.isProductNameDropdownVisible &&
                  inputProductFields.value[currentProductIndex.value].inputField.name.isFocused) ||
              (inputProductFields.value[currentProductIndex.value].inputField.article.isProductNameDropdownVisible &&
                  inputProductFields.value[currentProductIndex.value].inputField.article.isFocused) &&
              filteredProductsResults.value.length !== 0*/




          // Cannot read properties of undefined (reading 'inputField')
          (inputProductFields.value[currentProductIndex.value].inputField.name.isProductNameDropdownVisible
              || inputProductFields.value[currentProductIndex.value].inputField.article.isProductNameDropdownVisible)
          && filteredProductsResults.value.length !== 0

      );
    });

    /*    const buttonAttributes = computed(() => {
          if (userData.value.ip_telephony) {
            return {
              href: `tel:380${buyerPhone}`
            };
          } else {
            return {
              onClick: () => callToClient(buyerPhone)
            };
          }
        });*/

    // Method to handle calling the client

    const callToClient = async () => {

      useCallToClient(userData.value.sip_number, buyerPhone.value, buyerName.value)

      /*  console.log('callToClient', buyerPhone.value)
        await makeCall({
          'channel': userData.value.sip_number,
          'destination': buyerPhone.value,
          'callerName': userData.value.name
        }).then((response) => {


          store.dispatch('alert/triggerAlert', {
            show: 3000,
            variant: response.status,
            message: response.message
          }, {root: true});

        }).catch((error) => {
          console.error('Error:', error);
        });*/
    }


    const uponFailReceiveTypes = { // 'RETURN','RETURN_AFTER_7_DAYS','PROCESS_AS_REFUSAL'
      'RETURN': {value: 1, text: 'Повернути через 14 днів'},
      'RETURN_AFTER_7_DAYS': {value: 2, text: 'Повернути через 7 днів'},
      'PROCESS_AS_REFUSAL': {value: 3, text: 'Знищити'},
    }

    function selectOrderSource(selectedSource) {
      /*
      {
        "id": 5,
        "name": "asdasd",
        "alpha_name_id": 92,
        "user_id": 57,
        "price_type_id": 29,
        "stock_id": null,
        "sourceType": "inst"
    }
      */


      SourceName.value = selectedSource;


      if (selectedSource?.price_type_id) {
        console.log('selectedSource?.price_type_id', selectedSource.price_type_id);
        selectedPriceTypeId.value = selectedSource.price_type_id;
      }


      //  console.log('SourceName', SourceName.value);
      //  console.log('selectedPriceTypeId', selectedPriceTypeId.value);

      /*    if(selectedSource.stock_id !== null){
            selectedSto.value = selectedSource.stock_id;
          }*/


    }


    function selectPriceType(item) {
      // console.log('price types', priceTypes.value)
      //  console.log('selectPriceType', item)

      // console.log('inputProductFields.value', inputProductFields.value)

      selectedPriceTypeId.value = item;

      fillPriceByPriceType(item);

      // console.log('inputProductFields.value', inputProductFields.value)
      //ensurePricesExist();
    }

    function fillPriceByPriceType(item) {


      if (props.modalTitle.modalType !== 'insert') {
        //  console.log('111inputProductFields.value', inputProductFields.value)
        const productIds = inputProductFields.value.map(item => item.product.product_id);
        //console.log('productIds', productIds)

        const queryData = {
          productIds: productIds,
          priceType: item
        }

        getPricesByProductIdsAndPriceType(queryData).then(response => {
          //  console.log('response', response)
          if (response.data) {
            inputProductFields.value.forEach(field => {
              const product = field.product;
              const price = response.data.find(item => item.product_id === product.product_id);
              if (price) {
                product.price = price.price;
              }
            });
          }
        })
      } else {


        inputProductFields.value.forEach(field => {
          const product = field.product;
          const selectedPriceType = selectedPriceTypeId.value;
          // Перевіряємо, чи є ціна для вибраного типу в product.prices
          if (product.prices && product.prices[selectedPriceType] !== undefined) {
            product.price = product.prices[selectedPriceType]; // Встановлюємо ціну
          } else {
            product.price = 0; // Якщо немає ціни, встановлюємо порожнє значення або 0
          }

          console.log(`Оновлена ціна для продукту ${product.product_id}:`, product.price);
        });

      }

    }


    function openCheckLink(type, action) {
      console.log('openCheckLink', type)
      // prepay , watch


      let link = checkData.value[type];


      if (type === 'return_link') {
        link = 'https://check.checkbox.ua/' + checkData.value['return_uuid']
      }

      console.log('link', link)


      if (action === 'watch') {
        window.open(link, '_blank');
      } else if (action === 'print') {
        // https://api.checkbox.in.ua/api/v1/receipts/{receipt_id}/png
        window.open(`https://api.checkbox.in.ua/api/v1/receipts/${checkData.value[type]}/png`, '_blank');
      }
    }


    /*    const relatedPhoneNumbersToOrders = computed(() => {
          return store.getters['order/relatedPhoneNumbersToOrders'];
        });*/

    const relatedPhoneNumbersToOrders = ref([]);

    function setProductAreaType(type) {
      //  console.log('setProductAreaType', type)

      console.log('buyerPhone', buyerPhone.value);

      store.dispatch('order/findExitedOrdersByPhoneNumbers', [buyerPhone.value])
          .then((callBack) => {
            console.log('callBack', callBack)
            relatedPhoneNumbersToOrders.value = callBack;
          });

      productAreaType.value = type;
    }


    //console.log('orderModalWindowPosition.value.width', orderModalWindowPosition.value.width);


    watch(
        () => orderModalWindowPosition.value.width,
        (newWidth, oldWidth) => {
          console.log(`Width changed from ${oldWidth} to ${newWidth}`);
        }
    )

    return {
      // modalWidth,

      // isVisible,
      previewImgUrl: 'img/icons/img-prev.jpg',
      formattedOrderDate,
      currentOrderStatus,
      selectDeliveryType,
      selectedDeliveryType,
      selectDeliveryTechnology,
      deliveryTechnologyPlaceholder,
      selectedDeliveryTechnology,
      placeholderCity,
      CounterpartyType,
      setCounterPartyType,
      closeModal,
      isMinimized,
      minimize,
      unMinimize,
      active,
//      order,
      print,
      //  show1,
      DeliveryPhone,
      handleCombinedInputKeydown,
      setPlaceholder,
      saveOrder,
      setMultipleItemRef,
      OrderId,
      SenderContactPersonId,
      selectedOrderStatus,
      selectOrderStatus,
      BuyerDropPlaceholder,
      selectBuyerDropType,
      marketplaceOrderStatus,
      // SourceType,
      selectedBuyerDropTypeId,
      nalozhkaTrue,
      selectedPaymentType,
      filteredProductsResults,
      selectPaymentType,
      comment,
      dropdownVariant,
      // selectedAddiditionalPaymentType,
      //selectAddiditionalPaymentType,
      handleButtonClick,
      showSMSDropdown,
      DeliveryPayer,
      selectDeliveryPayer,
      selectedCashlesType,
      selectCashlesType,
      selectBackwardDeliveryPayer,
      BackwardDeliveryPayer,
      addProduct,
      orderDate,
      dontCall,
      formatDate,
      SourceName,
      totalAmount,
      prePaidAmount,
      paymentType,
      paymentTypeNames,
      paymentStatus,
      showBadge,
      DeliveryFio,
      selectSmsTemplate,
      smsTextArea,
      selectedCity,
      inn,
      DeliveryWarehouse,
      serviceTypes,
      DeliveryPayerTypes,
      buyerId,
      buyerName,
      buyerPhone,
      orderComment,
      buyerEmail,
      urikChecked,
      orderStatuses,
      intermChecked,
      otherReceiverChecked,
      resetModalState,
      ttnNumber,
      npDefaultWeight,
      clearBuyerName,
      clearBuyerPhone,
      deliveryInfoAssociatedTypes,
      isAddiditionalOrderSettingsVisible,
      clearDeliveryCity,
      clearDeliveryWarehouse,
      // searchByPhone,
      search,
      searchResults,
      filteredBuyerNameResults,
      filteredbuyerPhoneResults,
      filteredNpCityResults,
      filteredNpWarehouseResults,
      filteredDeliveryFioResults,
      isPhoneDropdownVisible,
      selectBuyerPhone,
      searchNameResults,
      searchDeliveryFioResults,
      npSenders,
      selectedSender,
      deliverySenders,
      selectSender,
      removeCurrentSeatAmount,
      handleFocus,
      handleBlur,
      handleProductInputKeydown,
      handleProductKeydown,
      selectCity,
      // modalHeader,
      isNameDropdownVisible,
      inputFocusStates,
      selectBuyerName,
      searchCityResults,
      isCityDropdownVisible,
      removeProduct,
      searchWarehouseResults,
      isWarehouseDropdownVisible,
      selectWarehouse,
      itemRefs,
      isFocusWithinDropdown,
      handleInputKeydown,
      handleKeydown,
      setItemRef,
      searchProductsResults,
      selectProduct,
      handleTabNavigation,
      dropdownTop,
      CityInputRef,
      WarehouseInputRef,
      buyerNameRef,
      buyerPhoneRef,
      inputProductFields,
      validateWeightNumber,
      validatePriceValue,
      productQtyInputRef,
      productPriceInputRef,
      weightInputRef,
      submitButtonRef,
      MarketplacePaymentType,
      dropdownLeft,
      handleProductFocus,
      isNonCashSupported,
      paymentControl,
      seatsAmounts,
      cashlesTypes,
      showSeatsDetalsParams,
      addSeatAmount,
      handsPrepare,
      selectPacking,
      currentPackingIndex,
      currentProductIndex,
      isPackingDropdownVisible,
      usersOrderNumber,
      searchPackingResults,
      SeatsInputFields,
      deliveryFioRef,
      deliveryPhoneRef,
      handlePackingFocus,
      sendMessage,
      smsTemplates,
      fillSearchPackingResults,
      inputNameStatus,
      trackingStatus,
      validationMessage,
      searchReceiverResults,
      clearPackingNameAndId,
      validationMessageDelivery,
      inputDeliveryFioStatus,
      validateBuyerName,
      handlePaste,
      validateDeliveryFio,
      isDeliveryFioDropdownVisible,
      setCombinedItemRef,
      root,
      handleCombinedKeydown,
      selectDeliveryFioName,
      orderModalWindowPosition,
      buyerPhoneIsNotEditable,
      DeliveryPhoneIsEditable,
      toggleInputEditability,
      buyerNameIsEditable,
      BuyerContactPersonId,
      DeliveryFioIsEditable,
      PartialPayment,
      ProductNameIsEditable,
      orderIsPaid,
      calcPriceWithCurrency,
      senderAddressNp,
      selectedSenderWarehouse,
      selectSenderWarehouse,
      spinnerStatuses,
      getWarehouseStateStatus,
      buyerDropTypes,
      dropPrice,
      isProductNameDropdownVisible,
      userData,
      upAddiditional,
      customSources,
      // buttonAttributes,
      callToClient,
      uponFailReceiveTypes,
      selectOrderSource,
      setBuyerNameItemRef,
      handleBuyerNameKeydown,
      handleBuyerNameInputKeydown,
      priceTypes,
      selectedPriceTypeId,
      selectPriceType,
      createCheck,
      //   setProductAreaType,
      productAreaType,
      checkData,
      openCheckLink,
      //   setPaiyedValue,
      afterPaymentValue,
      saveButtonColor,
      textAreaRowsCount,
      setProductAreaType,
      activeProductTab,
      relatedPhoneNumbersToOrders,
      crmOroderId,
      streetValues

      /*  myNumber,
        validateNumber*/
      // handleBuyerNameKeydown

      // getOrderPosition,
      //  searchByName,
      /*      ServiceTypeId,*/
    };

  },

  components: {
    BInputGroup,
    BDropdown,
    BContainer,
    BButton,
    BDropdownItem,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BFormInput,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
    BTd,
    BFormCheckbox,
    BPopover,
    BOffcanvas,
    BInputGroupText,
    BFormInvalidFeedback

  }

}
;
</script>

<style scoped>
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-arrows[type=number] {
  -moz-appearance: textfield;
}

.phone-width-10ch {
  width: 15ch; /* Встановлює ширину на 10 символів */
  min-width: 15ch; /* Забирає мінімальну ширину */
  #max-width: 15ch; /* Задає максимальну ширину на 10 символів */
}

.tabs-width {
  min-width: 500px;
}

.mt-2xl {
  margin-top: 10px;
}

/*.tooltip {
  position: absolute;
  top: 50px;
  left: 50px; !* Розташування підказки *!
}*/

.exclamation-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}


</style>