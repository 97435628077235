<template>

  <BDropdown
      text="Імпортувати товари"
      variant="outline-primary"
      boundary="viewport"
      container="body"
  >
    <BDropdownItem @click="importProduct('prom')">пром</BDropdownItem>
    <BDropdownItem @click="importProduct('rozetka')">розетка</BDropdownItem>
    <BDropdownItem @click="importProduct('kasta')">каста</BDropdownItem>
    <BDropdownItem @click="importProduct('horoshop')">хорошоп</BDropdownItem>
    <BDropdownItem @click="importProduct('opencart')">opencart</BDropdownItem>
    <BDropdownItem @click="importProduct('hubber')">hubber</BDropdownItem>
  </BDropdown>


</template>

<script>
import {
  getPromProducts,
  getRozetkaProducts,
  getKastaProducts,
  getOpencartProducts,
  getHoroshopProducts,
  getHubberProducts
} from "@/axios/settignsRequests";



import {
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue-next';

export default {

  name: "importProductsButtons",
  setup() {
    function importProduct(resource) {
      if (resource === 'rozetka') {
        getRozetkaProducts();
      } else if (resource === 'prom') {
        getPromProducts();
      } else if (resource === 'kasta') {
        getKastaProducts();
      } else if (resource === 'opencart') {
        getOpencartProducts();
      } else if (resource === 'horoshop') {
        getHoroshopProducts();
      } else if (resource === 'hubber') {
        getHubberProducts();
      }
    }

    return {
      importProduct
    }
  },

  components: {
    BDropdown,
    BDropdownItem
  }


}
</script>

<style scoped>

</style>