import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDwBoc01NvycxsKl95TWOhwEzsg9gesawc",
    authDomain: "ttn-gen.firebaseapp.com",
    projectId: "ttn-gen",
    storageBucket: "ttn-gen.firebasestorage.app",
    messagingSenderId: "700097199415",
    appId: "1:700097199415:web:6e0d2af45ff7ec134c6eb2"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestNotificationPermission = async () => {

    if (Notification.permission === 'granted') {

        try {
            const token = await getToken(messaging, {vapidKey: "BMwvUnPiUIu_ERpiq5_Tbiq2PPslTsih4dkCgAKSvLSLs0N0-yspskuOXJcNhRXSFIMzt1hBbZka75rLoZfWnLg"});
            console.log('Push Token:', token);
            return token;
        } catch (err) {
            console.error('Push notification error:', err);
            return null;
        }
    }

    if (Notification.permission === 'denied') {
        console.error('Notification permission is denied. Ask user to unblock it.');
        // alert('Сповіщення заблоковані. Розблокуйте їх у налаштуваннях браузера.');
        return null;
    }


};


export const onMessageListener = (callback) => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        callback(payload);
    });
};




